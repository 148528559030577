import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ProgressBarService } from 'src/app/shared/services/progress-bar.service';

@Component({
  selector: 'app-userchange-password',
  templateUrl: './userchange-password.component.html',
  styleUrls: ['./userchange-password.component.css']
})
export class UserchangePasswordComponent implements OnInit {

  constructor(private authService: AuthService, public progressBar: ProgressBarService) { }
  new1: string = "";
  new: string = "";
  ungleich: boolean | undefined; 
  ngOnInit(): void {
  }
  onSubmit(f: NgForm) {
    this.progressBar.startLoading();
    const changepwObserver = {
      next: () => {
       //console.log('Service Ok');
        this.progressBar.setSuccess();
        this.progressBar.completeLoading();
    },
      error: (err: any) => {
       //console.log(err);
        this.progressBar.completeLoading();
      }
    };
    this.authService.userchangepw(f.value).subscribe(changepwObserver);
  }

  onChangeEvent(event: any){
   //console.log(this.new + this.new1)
    if (this.new !== this.new1){
      this.new1 = "";
      this.ungleich = true;
    }else{
      this.ungleich = false;
    }
  }
}
