import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ProgressBarService } from 'src/app/shared/services/progress-bar.service';
import Swal from 'sweetalert2';
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';





@Component({
  selector: 'app-reservierung-w',
  templateUrl: './reservierung-w.component.html',
  styleUrls: ['./reservierung-w.component.css']
})
export class ReservierungWComponent implements OnInit {
  
  constructor(public authService: AuthService, public progressBar: ProgressBarService) { }
  status: string[] | any;
  show: boolean = false;
  eventsinfo: EventDataE[] = [];
  OutputHtml: OutputRes[] = [];
  showSuccess: boolean | undefined;
  public payPalConfig ? : IPayPalConfig;
  ngOnInit(): void {
    
  
    let valueObserver = this.authService.getAllReser();
    this.status = ['Warte', 'Aktiv', 'Abgelehnt', 'Storniert'];
    this.initConfig();

    if (valueObserver) {
      this.progressBar.setSuccess();
      this.progressBar.completeLoading();
      this.showSuccess = true;
      
    } else {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Leider ist ein Fehler aufgetreten',
        showConfirmButton: false,
        timer: 1500
      })
    }
  }


  public onChangeEventBetrag(){
    //console.log(this.authService.betrag);
     //this.initConfig();
   }
  bearbeiten(value: number, resid: number){
    const statusObserver = {
      next: () => {
        this.progressBar.setSuccess();
        this.progressBar.completeLoading();
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Speichern war erfolgreich!',
          showConfirmButton: false,
          timer: 1500
        })
        this.ngOnInit();
      },
      error: (err: any) => {
       //console.log(err);
        this.progressBar.completeLoading();
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Leider ist ein Fehler aufgetreten',
          showConfirmButton: false,
          timer: 1500
        })
      }
    };
    this.authService.resbearbeit(value, resid).subscribe(statusObserver);;;
  }
  selectDay(event : any) {
   //console.log(event.events);
    this.OutputHtml = [];
    this.eventsinfo = event.events;
    for (let i = 0; i < this.eventsinfo.length; i ++){
     //console.log(this.eventsinfo[i].id);
      let index = this.authService.valuesallres.message.findIndex((x: { resid: string; }) => x.resid == this.eventsinfo[i].id);
     //console.log(index);
     //console.log(this.authService.valuesallres.message[index]);
      this.OutputHtml.push(this.authService.valuesallres.message[index]);
     //console.log(this.OutputHtml);
    }
    this.show = true;
   //console.log(this.show);
  }

  private initConfig(): void {
    this.payPalConfig = {
    currency: 'EUR',
    //clientId: 'AYRXQ8P4h8XR-SE9kUp0IP-tNx7l8WDnNI8eIJKE2bY_ISoyIZj1Fq05RhsaIoTMcxE8DkveL3N7Ud4-',
    clientId: 'ATnod2ZADRwTxvG89MczbD2XhP_XB_UubOJVDsENXcqsIRL-6VT36YhwHlNEzcSCR9GDEOnE5L528hH_',
    createOrderOnClient: (data) => <ICreateOrderRequest>{
      intent: 'CAPTURE',
      purchase_units: [
        {
          amount: {
            currency_code: 'EUR',
            value: String(this.authService.betrag),
            breakdown: {
              item_total: {
                  currency_code: 'EUR',
                  value: String(this.authService.betrag)
              }
            }
          },
          items: [{
            name: 'Spende',
            quantity: '1',
            category: 'DIGITAL_GOODS',
            unit_amount: {
                currency_code: 'EUR',
                value: String(this.authService.betrag),
            },
        }]
        }
      ]
    },
    advanced: {
      commit: 'true'
    },
    style: {
      label: 'paypal',
      layout: 'vertical'
      
    },
    onApprove: (data, actions) => {
     //console.log('onApprove - transaction was approved, but not authorized', data, actions);
      actions.order.get().then((details: any) => {
       //console.log('onApprove - you can get full order details inside onApprove: ', details);
      });
    },
    onClientAuthorization: (data) => {
     //console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
      const donationObserver = {
        next: () => {
          this.progressBar.setSuccess();
          this.progressBar.completeLoading();
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Spende war erfolgreich!',
            showConfirmButton: false,
            timer: 1500
          })
          //this.ngOnInit();
          window.location.reload();
        },
        error: (err: any) => {
         //console.log(err);
          this.progressBar.completeLoading();
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Leider ist ein Fehler aufgetreten',
            showConfirmButton: false,
            timer: 1500
          })
        }
      };
      this.authService.saveDonation({'betrag': this.authService.betrag}).subscribe(donationObserver);;
      this.showSuccess = true;
    },
    onCancel: (data, actions) => {
     //console.log('OnCancel', data, actions);
    },
    onError: err => {
     //console.log('OnError', err);
    },
    onClick: (data, actions) => {
     //console.log('onClick', data, actions);
    },
  };
  }

}

export interface EventDataE {
  id: string;
  title: string;
  desc?: string;
  startDate: Date;
  endDate: Date;
  createdBy?: string;
  createdAt?: Date;
  type?: number;
  color?: string;
}

export interface OutputRes {
    date: any,
    zeit: any,
    resid: any,
    datum: any,
    name: any,
    id: any,
    kinder: any,
    beeintraechtigung: any,
    personen: any,
    bemerkung: any,
    telefon: any,
    status: any,
    email: any
}
