<div class="row tours">
    <div class="container" style="margin-top: 7rem; margin-bottom: 5rem;">
        <section class="login-clean" style="background: rgb(236,244,225);">
            <form #f="ngForm" (ngSubmit)="onSubmit(f)">
                <h2 class="sr-only">Login</h2>
                <div class="illustration"><i class="fas fa-location-arrow fa-sm" style="color: rgb(148,195,87);"></i>
                    <h3 style="border-color: rgb(0,0,0);color: rgb(0,0,0);">Login für Restaurants</h3>
                </div>
                <div class="form-group"><input class="form-control" type="email" name="email" ngModel required #username="ngModel" class="form-control" id="loginUsername" placeholder="Email"></div>
                <div class="form-group"><input class="form-control" type="password" name="password" ngModel required #password="ngModel" class="form-control" id="loginPassword" placeholder="Password"></div>
                <div class="form-group"><button class="btn btn-primary btn-block" type="submit" [disabled]="f.invalid" style="background: rgb(246,175,140) !important;border-width: 0px;border-radius: 0px;">Anmelden</button></div>
                <a class="forgot" [routerLink]="['/reset-password']">Passwort vergessen?</a>
            </form>
        </section>
    </div>
</div>