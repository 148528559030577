<div style="margin-top: 9rem;" class="container">
    <div class="row">
        <p> {{this.address}}</p>
        <!-- Default inline 1-->
    </div>
    <div class="row">
        <div class="" *ngFor="let kategorie of kategories">
            <!-- <mdb-checkbox [inline]="true" data-md-icheck [value]="kategorie.value" (change)="onCheckboxChange($event)" [default]="false">{{ kategorie.viewValue}}</mdb-checkbox> -->

            <!-- <input type="checkbox" name="check" checked> <span class="label-text">{{ kategorie.viewValue}}</span> -->
            <label class="check ">{{ kategorie.viewValue}}
                <input type="checkbox" [value]="kategorie.value" (change)="onCheckboxChange($event)" >
              <span class="checkmark"></span>
            </label>
        </div>
    </div>

</div>
<p #AddresseHTML></p>
<br>

<div class="container" *ngIf="this.authService.valuesuser">

    <!--     <div class="col">
        <div class="row tours">
            <div class="tourcard">
                <figure>
                    <div class="col">
                        <div class="tourpic">
                            <img src="assets/images/milk-tea-PeJWBQ5O6g8-unsplash.jpg">
                            <span class="tourcat"><i class="fas fa-hamburger"></i></span>
                            <span class="tourday hot">€€€</span>
                        </div>
                    </div>
                    <figcaption>
                        <h4 class="entry-title">
                            <span class="restaurant">Restaurant Name</span></h4>
                        <span class="standort">Musterstraße 3</span>
                        <br>
                        <span class="standort">1111 Musterdorf</span>
                    </figcaption>
                    <div class="maps">
                        <i class="fas fa-map-marker-alt"></i><a href="#">In Google Maps zeigen</a>
                    </div>
                    <div class="tourbtn">
                        <a href=# class="btn btn-sm">
                            <span>TISCH RESERVIEREN</span>
                        </a>
                    </div>
                </figure>
            </div>
        </div>
    </div> -->

    <div class="row tours" *ngFor="let item of this.authService.valuesuser.message; let i = index">
        <div class="tourcard" style="margin-top: 3rem;">
            <figure>
                <div class="col">
                    <div class="tourpic">
                        <img *ngIf="item.vorschaubild" src="{{this.authService.authURL + 'uploads/' + item.id + '/' +  item.vorschaubild}}">
                        <img *ngIf="!item.vorschaubild" src="assets/images/Locatetable_LOGO_RGB.png">
                        <span class="tourcat">                                        
                            <img *ngIf="item.kategorie == 1" src="assets/images/categorie/Locatetable__Österreichisch.png" class="icon" placement="top" ngbTooltip="Österreichische Küche"  alt=""> 
                            <img *ngIf="item.kategorie == 2" src="assets/images/categorie/Locatetable__Pizza.png" class="icon" placement="top" ngbTooltip="Italienisch" alt=""> 
                            <img *ngIf="item.kategorie == 3" src="assets/images/categorie/Locatetable__Bar.png" class="icon" placement="top" ngbTooltip="Bar" alt=""> 
                            <img *ngIf="item.kategorie == 4" src="assets/images/categorie/Locatetable__Pizza.png" class="icon" placement="top" ngbTooltip="Pizza" alt=""> 
                            <img *ngIf="item.kategorie == 5" src="assets/images/categorie/Locatetable__Fastfood.png" class="icon" placement="top" ngbTooltip="FastFood" alt=""> 
                            <img *ngIf="item.kategorie == 6" src="assets/images/categorie/Locatetable__Pasta.png" class="icon" placement="top" ngbTooltip="Pasta" alt=""> 
                            <img *ngIf="item.kategorie == 7" src="assets/images/categorie/Locatetable__Asiatisch.png" class="icon" placement="top" ngbTooltip="Asiatisch" alt=""> 
                            <img *ngIf="item.kategorie == 8" src="assets/images/categorie/Locatetable__Cafe.png" class="icon" placement="top" ngbTooltip="Cafe" alt=""> 
                            <img *ngIf="item.kategorie == 9" src="assets/images/categorie/Locatetable__Burger.png" class="icon" placement="top" ngbTooltip="Burger" alt="">
                            <img *ngIf="item.kategorie == 10" src="assets/images/categorie/Locatetable__Mexikanisch.png" class="icon" placement="top" ngbTooltip="Mexikanisch" alt="">
                            <!-- Kategorie 2 -->
                            <img *ngIf="item.kategorie1 == 1" src="assets/images/categorie/Locatetable__Österreichisch.png" class="icon" placement="top" ngbTooltip="Österreichische Küche"  alt=""> 
                            <img *ngIf="item.kategorie1 == 2" src="assets/images/categorie/Locatetable__Pizza.png" class="icon" placement="top" ngbTooltip="Italienisch" alt=""> 
                            <img *ngIf="item.kategorie1 == 3" src="assets/images/categorie/Locatetable__Bar.png" class="icon" placement="top" ngbTooltip="Bar" alt=""> 
                            <img *ngIf="item.kategorie1 == 4" src="assets/images/categorie/Locatetable__Pizza.png" class="icon" placement="top" ngbTooltip="Pizza" alt=""> 
                            <img *ngIf="item.kategorie1 == 5" src="assets/images/categorie/Locatetable__Fastfood.png" class="icon" placement="top" ngbTooltip="FastFood" alt=""> 
                            <img *ngIf="item.kategorie1 == 6" src="assets/images/categorie/Locatetable__Pasta.png" class="icon" placement="top" ngbTooltip="Pasta" alt=""> 
                            <img *ngIf="item.kategorie1 == 7" src="assets/images/categorie/Locatetable__Asiatisch.png" class="icon" placement="top" ngbTooltip="Asiatisch" alt=""> 
                            <img *ngIf="item.kategorie1 == 8" src="assets/images/categorie/Locatetable__Cafe.png" class="icon" placement="top" ngbTooltip="Cafe" alt=""> 
                            <img *ngIf="item.kategorie1 == 9" src="assets/images/categorie/Locatetable__Burger.png" class="icon" placement="top" ngbTooltip="Burger" alt="">
                            <img *ngIf="item.kategorie1 == 10" src="assets/images/categorie/Locatetable__Mexikanisch.png" class="icon" placement="top" ngbTooltip="Mexikanisch" alt="">
                            
                        </span>
                        <span class="tourday hot">{{item.preis}}</span>
                    </div>
                </div>
                <figcaption>
                    <div class="col">
                        <h4 class="entry-title">

                            <span class="restaurant">{{item.name}}</span></h4>
                    </div>
                    <div class="col"><span class="standort"><strong>{{item.distance}}</strong> km Entfernt</span></div>


                    <div class="col">
                        <span class="standort">{{item.standort}}</span>
                    </div>


                </figcaption>
                <div class="maps col">
                    <i class="fas fa-map-marker-alt"></i><a target="_blank" href="{{'https://www.google.com/maps/search/?api=1&query=' + item.standort}}">In Google Maps zeigen</a>
                </div>
                <div class="tourbtn">
                    <div class="row d-flex">
                        <a [routerLink]="['/reserkunde', item.id]" class="btn btn-sm p-2">
                            <span>TISCH RESERVIEREN</span>
                        </a>
                        <span class="d-flex align-items-center ml-auto p-2" (click)="collapse.toggle()" [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">
                        <i class="fas fa-chevron-circle-down fa-2x" style="color: white;"></i>
                        </span>
                    </div>
                </div>

                <div class="row">
                    <div class="col">

                    </div>
                </div>
                <div class="row" style="margin-left: 5px; color: white;" #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
                    <div class="col">
                        <p>{{item.beschreib}}</p>


                        <div class="col" style="margin-top: 2rem;">
                            <a [routerLink]="['/speisekarte', item.id]">Speisekarte</a> - Zahlung: {{zahlungs[item.zahlung].viewValue}}
                        </div>
                        <div class="col">
                            Öffnungzeiten:
                        </div>
                        <div class="col">
                            <div *ngIf="item.zeitausgabe">
                                <p *ngIf="item.zeitausgabe[0].wtag && item.zeitausgabe[0].time"> {{item.zeitausgabe[0].wtag}}:{{item.zeitausgabe[0].time}}</p>
                                <p *ngIf="item.zeitausgabe[1].wtag"> {{item.zeitausgabe[1].wtag}}:{{item.zeitausgabe[1].time}}</p>
                                <p *ngIf="item.zeitausgabe[2].wtag"> {{item.zeitausgabe[2].wtag}}:{{item.zeitausgabe[2].time}}</p>
                                <p *ngIf="item.zeitausgabe[3].wtag"> {{item.zeitausgabe[3].wtag}}:{{item.zeitausgabe[3].time}}</p>
                                <p *ngIf="item.zeitausgabe[4].wtag"> {{item.zeitausgabe[4].wtag}}:{{item.zeitausgabe[4].time}}</p>
                                <p *ngIf="item.zeitausgabe[5].wtag"> {{item.zeitausgabe[5].wtag}}:{{item.zeitausgabe[5].time}}</p>
                                <p *ngIf="item.zeitausgabe[6].wtag"> {{item.zeitausgabe[6].wtag}}:{{item.zeitausgabe[6].time}}</p>
                            </div>
                        </div>

                    </div>
                </div>

            </figure>
        </div>
    </div>
</div>
<!-- <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
</ngx-spinner> -->