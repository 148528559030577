import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';

 
@Component({
  selector: 'app-confirm-email',
  templateUrl: './confirm-email.component.html',
  styleUrls: ['./confirm-email.component.css']
})
export class ConfirmEmailComponent implements OnInit {
  emailConfirmed: boolean = false;
  urlsparam: any = {};
  constructor(private route: ActivatedRoute, private authService : AuthService) { }

  ngOnInit(): void {
    this.urlsparam.id = this.route.snapshot.queryParamMap.get('id');
    this.urlsparam.token = this.route.snapshot.queryParamMap.get('token');
    //console.log(this.urlsparam);
    this.authService.confirmEmail(this.urlsparam).subscribe(() =>{
      //console.log("Erfolgreich Aktiviert");
    }, error => {
      //console.log(error);
    })
  }

}
