import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgProgress } from '@ngx-progressbar/core';
import { AuthService } from '../../services/auth.service';
import { ProgressBarService } from '../../services/progress-bar.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  OnTop: boolean = true;
  constructor(private progress: NgProgress, public progressBar: ProgressBarService,
    public authService: AuthService, public router: Router) { }

  ngOnInit() {
    this.progressBar.progressRef = this.progress.ref('progressBar');
  }


  logout(){
    localStorage.removeItem('token');
    this.router.navigate(['']);
    this.authService.values = undefined;
  }
  @HostListener('window:scroll', ['$event']) onScrollEvent($event: any){

    const scrollPos = document.documentElement.scrollTop;
    if (scrollPos > 120) {

      this.OnTop = false;
      
    }
    else {

      this.OnTop = true;
      
    }
  } 
}
