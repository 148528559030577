import { Component, OnInit, NgModule } from '@angular/core';
import { NgForm  } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ProgressBarService } from 'src/app/shared/services/progress-bar.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-reservierungbearbeiten',
  templateUrl: './reservierungbearbeiten.component.html',
  styleUrls: ['./reservierungbearbeiten.component.css']
})
export class ReservierungbearbeitenComponent implements OnInit {
  options: string[] | any;

  status: string[] | any;
  resid: any;
  telefon: any;

  selectedOption: string | any;
  constructor(public authService: AuthService,  public progressBar: ProgressBarService, public router: Router) { }
 
  ngOnInit(): void {
    if (this.authService.loggedIn()){
      this.router.navigate(['/reserlogin']);
    };
    window.scrollTo(0, 0);
    this.options = [
      '+43',
      '+49',
   ];
   this.selectedOption = this.options[0];
   this.status = ['Warte', 'Aktiv', 'Abgelehnt', 'Storniert'];
  }
  changeSelectedOption(option: string) {
    this.selectedOption = option;
 }

  onSubmit(f: NgForm) {
   //console.log(f.value);
    this.authService.getreseriddata(f.value['resid'],this.selectedOption + f.value['telefon']);
    this.resid = f.value['resid'];
    this.telefon = this.selectedOption + f.value['telefon'];

    if (this.authService.values?.success){
      this.progressBar.setSuccess();
      this.progressBar.completeLoading();
     
    }
    else {
      this.progressBar.completeLoading();

    }
 
  }
  stornieren(){
   //console.log(this.resid +this.telefon);
    Swal.fire({
      title: 'Möchtest du die Reservierung wirklich stornieren?',
      showCancelButton: true,
      confirmButtonText: `Stornieren`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        this.authService.buttonstorno = undefined;
        this.authService.stornores(this.authService.valuesres.message.id,this.telefon,this.authService.valuesres.message.datum + this.authService.valuesres.message.zeit,this.resid,);
      } 
    })
  }



}
