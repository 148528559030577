<!-- <div class="container" style="margin-top: 15rem;">
    <div class="row"><a (click)="backClicked()">Zurück</a></div>
    <div class="row" style=" margin-bottom: 5rem;">
        <div class="col">
            <img class="img-thumbnail" style="max-height: 300px;" src='{{this.authService.values ? this.authService.values.message.vorschaubild : " " }}' alt="">
        </div>
        <div class="col">
            <p>{{this.authService.values ? this.authService.values.message.name : ""}} {{this.authService.values ? this.authService.values.message.preis : ""}}</p>
            <p>{{this.authService.values ? this.authService.values.message.standort : ""}}</p><a [routerLink]="['/speisekarte', this.activerouter.snapshot.params.id]">Speisekarte</a> Öffnungzeiten:
            <div *ngIf="this.authService.zeitenausgabe[0]">
                <p *ngIf="this.authService.zeitenausgabe[0].wtag"> {{this.authService.zeitenausgabe[0].wtag}}:{{this.authService.zeitenausgabe[0].time}}</p>
                <p *ngIf="this.authService.zeitenausgabe[1].wtag"> {{this.authService.zeitenausgabe[1].wtag}}:{{this.authService.zeitenausgabe[1].time}}</p>
                <p *ngIf="this.authService.zeitenausgabe[2].wtag"> {{this.authService.zeitenausgabe[2].wtag}}:{{this.authService.zeitenausgabe[2].time}}</p>
                <p *ngIf="this.authService.zeitenausgabe[3].wtag"> {{this.authService.zeitenausgabe[3].wtag}}:{{this.authService.zeitenausgabe[3].time}}</p>
                <p *ngIf="this.authService.zeitenausgabe[4].wtag"> {{this.authService.zeitenausgabe[4].wtag}}:{{this.authService.zeitenausgabe[4].time}}</p>
                <p *ngIf="this.authService.zeitenausgabe[5].wtag"> {{this.authService.zeitenausgabe[5].wtag}}:{{this.authService.zeitenausgabe[5].time}}</p>
                <p *ngIf="this.authService.zeitenausgabe[6].wtag"> {{this.authService.zeitenausgabe[6].wtag}}:{{this.authService.zeitenausgabe[6].time}}</p>
            </div>
        </div>
    </div>
    <div ngif="showform">
        <form #f="ngForm" (ngSubmit)="onSubmit(f)">
            <div class="form-row">
                <div class="col-lg-4 col-md-6 form-group">
                    <input type="text" name="name" class="form-control" required ngModel id="name" placeholder="Name">
                </div>
                <div class="col-lg-4 col-md-6 form-group">
                    <input type="email" class="form-control" required ngModel name="email" id="email" placeholder="E-Mail">
                </div>
                <div class="col-lg-1 col-md-2 form-group">
                    <div class="btn-group" dropdown>
                        <button id="button-basic" dropdownToggle type="button" class="btn btn-primary dropdown-toggle" aria-controls="dropdown-basic">
                                  {{ selectedOption }} <span class="caret"></span>
                        </button>

                        <ul id="dropdown-basic" class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                            <li role="menuitem" *ngFor="let option of options">
                                <span class="dropdown-item" (click)="changeSelectedOption(option)"> 
                                {{ option }} 
                            </span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-3 col-md-5 form-group">
                    <input type="number" class="form-control" required ngModel name="telefon" id="telefon" placeholder="Telefonnummer">
                </div>
                <div class="col-lg-4 col-md-6 form-group">
                    <input type="date" name="datum" class="form-control" min="{{this.minDate}}" (change)="onChangeEventDate($event)" required [(ngModel)]="datum" ngModel id="datum" placeholder="datum">
                </div>
                <div class="col-lg-4 col-md-6 form-group">
                    <input type="time" class="form-control" required ngModel (change)="onChangeEvent($event)" [readonly]="this.isEditable" [(ngModel)]="zeit" name="zeit" id="zeit" placeholder="Zeit">
                </div>
                <div *ngIf="this.isEditable">
                    <p> An diesem Tag hat das Restaurant leider geschlossen.</p>
                </div>
                <div class="col-lg-4 col-md-6 form-group">
                    <input type="number" class="form-control" required ngModel name="personen" id="personen" placeholder="Anzahl der Personen">
                </div>
            </div>
            <div class="form-group">
                <textarea class="form-control" ngModel id="bemerkung" name="bemerkung" name="bemerkung" placeholder="Bemerkung"></textarea>
            </div>
                     <div class="form-row">


                <div class="col col-lg-2 form-group">
                    Beeinträchtigung
                </div>
                <div class="col col-lg-2 form-group">
                    <input type="checkbox" name="beintraechtigung" ngModel #besonder="ngModel" class="form-control" id="reservierungBeeinträchtigung">
                </div>
                <div class="col col-lg-2 form-group">
                    Kinder
                </div>
                <div class="col col-lg-2 form-group">
                    <input type="checkbox" name="kinder" ngModel #besonder="ngModel" class="form-control" id="reservierungKinder">
                </div>
                
                <div class="col col-lg-2 form-group">
                    Tischauswahl
                </div>
                <div class="col col-lg-2 form-group" *ngIf="zeit != undefined">
                    <input type="checkbox" name="tischauswahl" ngModel #tisch="ngModel" class="form-control" id="reservierungTisch" (click)="toggleShow()">
                </div>

            </div>



            <div class="form-row" style="margin-bottom: 5rem;">
                <div class="col-8 form-group">
                    <div class="row">
                        <div class="col">
                            Ich stimme zu diese Daten an das jeweiligen Unternehmen weiter zuleiten und per SMS oder E-Mail benachrichtigt zu werden.
                        </div>
                        <div class="col-1">
                            <input type="checkbox" name="AGB" required ngModel #agb="ngModel" class="form-control" id="reservierungAGB">
                        </div>
                    </div>
                </div>
                <div class="col form-group">
                    <button type="submit" [disabled]="f.invalid" class="btn btn-primary float-right mt-3">Buche deinen Tisch</button>
                </div>

            </div>

        </form>
        <div class="border" *ngIf="isShown">
            <div class="row" *ngFor="let rowData of rows" style="margin-bottom: 1rem;">
                <div class="col-lg-2 bg-lightblue rounded" *ngFor="let row of rowData">

                    <button type="button" (click)="tischwahl(row.id)" class="btn btn-danger bmd-btn-fab btn-circle">
                        {{row.name}}
                      </button>
                </div>
            </div>

        </div>
    </div>

    <div class="row">
        <p style="margin-bottom: 10rem;"> </p>
    </div>
</div> -->


<div class="container col-md-6" style="margin-top: 8rem;" id="bookingform">
    <div class="row text-start">
        <a (click)="backClicked()"><i class="fas fa-arrow-circle-left fa-3x"></i></a>
    </div>
    <div class="d-flex d-md-flex flex-column justify-content-center align-items-center align-content-center align-items-md-center headbook">

        <h3>Tisch RESERVIEREN</h3>
        <h4 id="restaurant">{{this.authService.values ? this.authService.values.message.name : ""}}</h4>
        <div class="row">
            <div class="col">
                <p>{{this.authService.values ? this.authService.values.message.standort : ""}}</p><a [routerLink]="['/speisekarte', this.activerouter.snapshot.params.id]">Speisekarte</a>
            </div>
            <div class="col" *ngIf="this.authService.zeitenausgabe[0]">
                Öffnungzeiten:
                <p *ngIf="this.authService.zeitenausgabe[0].wtag && this.authService.zeitenausgabe[0].time"> {{this.authService.zeitenausgabe[0].wtag}}:{{this.authService.zeitenausgabe[0].time}}</p>
                <p *ngIf="this.authService.zeitenausgabe[1].wtag"> {{this.authService.zeitenausgabe[1].wtag}}:{{this.authService.zeitenausgabe[1].time}}</p>
                <p *ngIf="this.authService.zeitenausgabe[2].wtag"> {{this.authService.zeitenausgabe[2].wtag}}:{{this.authService.zeitenausgabe[2].time}}</p>
                <p *ngIf="this.authService.zeitenausgabe[3].wtag"> {{this.authService.zeitenausgabe[3].wtag}}:{{this.authService.zeitenausgabe[3].time}}</p>
                <p *ngIf="this.authService.zeitenausgabe[4].wtag"> {{this.authService.zeitenausgabe[4].wtag}}:{{this.authService.zeitenausgabe[4].time}}</p>
                <p *ngIf="this.authService.zeitenausgabe[5].wtag"> {{this.authService.zeitenausgabe[5].wtag}}:{{this.authService.zeitenausgabe[5].time}}</p>
                <p *ngIf="this.authService.zeitenausgabe[6].wtag"> {{this.authService.zeitenausgabe[6].wtag}}:{{this.authService.zeitenausgabe[6].time}}</p>
            </div>
        </div>
    </div>
    <div ngif="showform">
        <form class="row" #f="ngForm" (ngSubmit)="onSubmit(f)">
            <div class="form-group col-md-6 book"><input class="form-control book " type="number" required ngModel name="personen" id="personen" placeholder="Personen Anzahl"></div>
            <div class="form-group col-md-6 book">
                <p class="d-lg-none">Datum</p><input class="form-control book " type="date" name="datum" min="{{this.minDate}}" (change)="onChangeEventDate($event)" required [(ngModel)]="datum" ngModel id="datum"></div>
            <div class="form-group col-md-8 book">
                <p style="color: red;" *ngIf="this.isEditable"> An diesem Tag hat das Restaurant leider geschlossen.</p>
            </div>
            <div class="form-group col-md-4 book">
                <p class="d-lg-none">Zeit</p><input class="form-control book " type="time" required ngModel (change)="onChangeEvent($event)" [readonly]="this.isEditable" [(ngModel)]="zeit" name="zeit" id="zeit"></div>

            <div class="form-group col-md-6 book"><input class="form-control book " type="text" name="name" required ngModel id="name" placeholder="Vorname, Nachname"></div>
            <div class="form-group col-md-6 book">
                <div class="row">
                    <div class="col-4 col-offset-0">
                        <div class="btn-group" dropdown>
                            <button id="button-basic" dropdownToggle type="button" class="btn btn-primary dropdown-toggle" aria-controls="dropdown-basic">
                      {{ selectedOption }} <span class="caret"></span>
                    </button>

                            <ul id="dropdown-basic" class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                                <li role="menuitem" *ngFor="let option of options">
                                    <span class="dropdown-item" (click)="changeSelectedOption(option)"> 
                    {{ option }} 
                </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-8 col-offset-0 align-self-left">
                        <input class="form-control book" type="text" mask="000 00 00 000 || 000 00 00 0000" [showMaskTyped]="false" required ngModel name="telefon" id="telefon" [(ngModel)]="phonenum" (change)="phoneonchange($event)" placeholder="Telefonnummer">
                        <div class="form-group col-md-8 book">
                            <p style="color: red;" *ngIf="this.isPhonevalide">Ungültige Telefonnummer (Handy Nummer)</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group col-md-12 book"><input class="form-control book " type="email" required ngModel email name="email" id="email" placeholder="E-Mail Adresse"></div>
            <div class="form-group col-md-12 book"><textarea class="form-control book" ngModel id="bemerkung" name="bemerkung" name="bemerkung" placeholder="Bemerkungen"></textarea></div>

            <p>Es wird bestätigt, dass diese Daten an den Wirten weitergeben werden. Zudem wird aktzeptiert per SMS, über die angegebene Telefonnummer, benachrichtigt zu werden.</p><button class="btn btn-primary reservation col-md-12" type="submit" [disabled]="f.invalid">Reservierung abschicken</button>
        </form>
    </div>
</div>