<div class="container" *ngIf="!isHomeRoute()">
    <section id="Impressum">


        <div class="tourbtn">

           <!--  <div class="row d-flex justify-content-center" style="margin-right: 0px; margin-left: 0px;">
                <h3>Teile uns gerne mit deinen Freunden</h3>
                <share-buttons [theme]="'default'" [include]="['facebook','telegram','messenger','whatsapp','sms','email','print','copy']" [show]="6" [size]="1" [url]="this.url + this.router.url" [autoSetMeta]="false"></share-buttons>

            </div> -->
            <div class="row d-flex justify-content-center">
                <h3>
                    <!-- <a [routerLink]="['register']">Registrieren</a> - -->
                    <a [routerLink]="['anleitung']" style="margin-bottom: 5rem;">Anleitung für Restaurants</a>
                </h3>
            </div>
            <div class="row d-flex justify-content-center">

                <a [routerLink]="['impressum']">Impressum</a> -
                <a [routerLink]="['datenschutz']" style="margin-bottom: 5rem;">Datenschutz</a>
            </div>
        </div>
    </section>
</div>