import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-anleitung',
  templateUrl: './anleitung.component.html',
  styleUrls: ['./anleitung.component.css']
})
export class AnleitungComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
