import { Component, OnInit, NgModule } from '@angular/core';
import { NgForm, ReactiveFormsModule, FormGroup,  } from '@angular/forms';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ProgressBarService } from 'src/app/shared/services/progress-bar.service';
import { GooglePlaceModule } from "ngx-google-places-autocomplete"; 
import { Options } from 'ngx-google-places-autocomplete/objects/options/options';






@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})


export class RegisterComponent implements OnInit {
  

  options : Options={
    types: [],
    componentRestrictions: { country: 'AT' },
    bounds: null as any,
    fields: null as any,
    strictBounds: false,
    origin: null as any
    };







  constructor(private authService : AuthService, public progressBar: ProgressBarService) { }

  //Local Variable defined 
  formattedaddress=" "; 
  preisvalue = "";
  kategorievalue = "";
  addresslat = "";
  addresslng = "";



  dataregist!: SendData;

  kategories: Kategorie[] = [
    { value: '0', viewValue: '' },
    { value: '1', viewValue: 'Gasthaus' },
    { value: '2', viewValue: 'Fastfood' },
    { value: '3', viewValue: 'Italienisch' },
    { value: '4', viewValue: 'Chinesisch' },
    { value: '5', viewValue: 'Hausmanskost' },
    { value: '6', viewValue: 'Bar' },
  ];
  

  public AddressChange(address: any) { 
  //setting address from API to local variable 
   this.formattedaddress=address.formatted_address 
   this.addresslat = address.geometry.location.lat();
   this.addresslng = address.geometry.location.lng();
} 

public preis(valuepreis: any){
  this.preisvalue = valuepreis;
}



  ngOnInit(): void {
 
  }
  onSubmit(f: NgForm) {
    //console.log("test");
    this.progressBar.startLoading();
    const registerObserver = {
      next: () => {
        //console.log('Service Ok');
        this.progressBar.setSuccess();
        this.progressBar.completeLoading();
    },
      error: (err: any) => {
        //console.log(err);
        this.progressBar.completeLoading();
      }
    };


    this.dataregist = f.value;
    this.dataregist.standort = this.formattedaddress;
    this.dataregist.lat = this.addresslat;
    this.dataregist.lng = this.addresslng;
    this.dataregist.preis = this.preisvalue;


//console.log(f.value);
    this.authService.register(this.dataregist).subscribe(registerObserver);
    //console.log(f.value['email']);
    //console.log(this.dataregist);
  }
}

interface Kategorie {
  value: string;
  viewValue: string;
}


export interface SendData {
  email: string;
  name: string;
  password: string;
  telefon: string;
  standort: string;
  lat:string;
  lng:string;
  preis: string;
  kategorie: string;
}
