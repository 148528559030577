<body>

    <section id="hero">
        <div class="d-flex flex-column justify-content-between herotext">
            <div class="container hero">
                <h1 class="text-uppercase">Dein Platzerl <br>wartet auf dich!</h1>
                <h2 class="d-none d-lg-block" style="margin-bottom: 2rem;">Entdecke Restaurants <br>in deiner Nähe.</h2>
            </div>
            <form #f="ngForm" (ngSubmit)="onSubmit(f)">
                <div class="container">
                    <div class="row">


                        <p *ngIf="this.showalert" style="color: red;"> <strong> *Keine Gültige Adresse </strong></p>
                    </div>
                    <div class="row-fluid d-flex">
                        <!--                     <div class="col-1 d-flex justify-content-center align-self-center">
                                <a (click)="getLocationService()">
                                    <mat-icon>location_searching</mat-icon>
                                </a>
                            </div> -->
                        <div class="col-11 p-0">
                            <input style="max-width: 100%;min-width: 200px;width: 100%;" type="text" name="standort" ngModel #standort="ngModel" class="d-lg-flex" id="registerStandort" [options]='options' placeholder="Aktuelle Position oder Standort " ngx-google-places-autocomplete
                                (onAddressChange)="AddressChange($event)" (change)="changeFn($event);">

                        </div>
                        <div class="col-auto align-self-center p-0">
                            <button class="btn" type="submit"><i class="fa fa-search"></i></button>

                        </div>


                    </div>
                </div>
            </form>
            <div class="container register">
                <!-- <a class="register" [routerLink]="['register']"> <strong> Restaurant registrieren</strong></a> -->

            </div>
        </div>
    </section>

    <section id="anleitung">
        <div class="container">
            <div class="col-md-12">
                <h3>Und so geht’s</h3>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-md-4"><img src="assets/images/user/Locatetable_Erklärung__Standort.png" class="img-thumbnail">
                    <h5>Gib deinen Standort an</h5>
                    <p>Gib deine Adresse ein oder lass uns deinen aktuellen Standort ermitteln.&nbsp;</p>
                </div>
                <div class="col-md-4"><img src="assets/images/user/Locatetable_Erklärung__RestaurantWählen.png" class="img-thumbnail">
                    <h5>Restaurant wählen</h5>
                    <p>Dein Suchergebnis zeigt dir Restaurants in deiner Nähe. Klicke einfach auf "Tisch reservieren" um deine Daten einzugeben.</p>
                </div>
                <div class="col-md-4"><img src="assets/images/user/Locatetable_Erklärung__Daten.png" class="img-thumbnail">
                    <h5>Daten eingeben und abschicken</h5>
                    <p>Gib alle wichtigen Informationen für deine Reservierung ein und klicke auf "Reservieren".&nbsp;&nbsp;</p>
                </div>
            </div>
        </div>
    </section>
    <section id="App" style="margin-top: 3rem;">
        <div class="container">
            <div class="col-md-12">
                <h3>LocateTable App</h3>
            </div>
        </div>
        <div class="container" style="margin-top: 1rem;">
            <div class="row align-items-center">
                <div class="col-2 offset-md-3">
                    <img src="assets/icons/Locatetable_appIcon.png" style="max-height: 70px; max-width: 70px;" class="rounded" alt="App Icon LocateTable">
                </div>
                <div class="col offset-1">
                    <p>Um noch einfacher Restaurants finden zu können, kannst du Locatetable auf deinen Homescreen speichern. Bei den meisten Browsen geht das über das Menü rechts oben.</p>
                </div>
            </div>

        </div>
    </section>
    <section id="topdrei" *ngIf="this.authService.valuesuser">
        <div class="container">
            <div class="col-md-12">
                <h3>Reservierungscounter</h3>
            </div>
        </div>
        <p style="margin-top: 2rem;">Es sind bisher <strong>{{this.authService.valuesuser.count}}</strong> Reservierungen über Locatetable getätigt worden. </p>
        <!-- <div class="container">
            <div class="row">
                <div class="col-md-4 px-md-4">
                    <div class="row tours">
                        <div class="tourcard">
                            <figure>
                                <div class="tourpic">
                                    <img src="assets/images/milk-tea-PeJWBQ5O6g8-unsplash.jpg">
                                    <span class="tourcat"><i class="fas fa-hamburger"></i></span>
                                    <span class="tourday hot">€€€</span>
                                </div>
                                <figcaption>
                                    <h4 class="entry-title">
                                        <span class="restaurant">Restaurant Name</span></h4>
                                    <span class="standort">Musterstraße 3</span>
                                    <br>
                                    <span class="standort">1111 Musterdorf</span>
                                </figcaption>
                                <div class="maps">
                                    <i class="fas fa-map-marker-alt"></i><a href="#">In Google Maps zeigen</a>
                                </div>
                                <div class="tourbtn">
                                    <a href=# class="btn btn-sm">
                                        <span>TISCH RESERVIEREN</span>
                                    </a>
                                </div>
                            </figure>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 px-md-4">
                    <div class="row tours">
                        <div class="tourcard">
                            <figure>
                                <div class="tourpic">
                                    <img width="100%" height="80%" src="assets/images/milk-tea-PeJWBQ5O6g8-unsplash.jpg">
                                    <span class="tourcat"><i class="fas fa-hamburger"></i></span>
                                    <span class="tourday hot">€€€</span>
                                </div>
                                <figcaption>
                                    <h4 class="entry-title">
                                        <span class="restaurant">Restaurant Name</span></h4>
                                    <span class="standort">Musterstraße 3</span>
                                    <br>
                                    <span class="standort">1111 Musterdorf</span>
                                </figcaption>
                                <div class="maps">
                                    <i class="fas fa-map-marker-alt"></i><a href="#">In Google Maps zeigen</a>
                                </div>
                                <div class="tourbtn">
                                    <a href=# class="btn btn-sm">
                                        <span>TISCH RESERVIEREN</span>
                                    </a>
                                </div>
                            </figure>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 px-md-4">
                    <div class="row tours">
                        <div class="tourcard">
                            <figure>
                                <div class="tourpic">
                                    <img src="assets/images/hero_mobile.jpg">
                                    <span class="tourcat"><i class="fas fa-hamburger"></i></span>
                                    <span class="tourday hot">€€€</span>
                                </div>
                                <figcaption>
                                    <h4 class="entry-title">
                                        <span class="restaurant">Restaurant Name</span></h4>
                                    <span class="standort">Musterstraße 3</span>
                                    <br>
                                    <span class="standort">1111 Musterdorf</span>
                                </figcaption>
                                <div class="maps">
                                    <i class="fas fa-map-marker-alt"></i><a href="#">In Google Maps zeigen</a>
                                </div>
                                <div class="tourbtn">
                                    <a href=# class="btn btn-sm">
                                        <span>TISCH RESERVIEREN</span>
                                    </a>
                                </div>
                            </figure>
                        </div>
                    </div>
                </div>
            </div> 
        </div> -->
    </section>
    <section id="newdrei">
        <div class="container">
            <div class="col-md-12">
                <h3>NEU MIT DABEI</h3>
            </div>
        </div>
        <p>Das sind unser Neuzugänge: Sei der erste Gast, der einen Tisch über Locatetable bei ihnen bucht!</p>
        <div class="container">
            <div class="row" *ngIf="this.authService.valuesuser">
                <div class="col-md-4 px-md-4" *ngFor="let item of this.authService.valuesuser.message; let i = index">
                    <div class="row tours">
                        <div class="tourcard">
                            <figure>
                                <div class="tourpic">

                                    <img *ngIf="item.vorschaubild" src="{{this.authService.authURL + 'uploads/' + item.id + '/' +  item.vorschaubild}}">
                                    <img *ngIf="!item.vorschaubild" src="assets/images/Locatetable_LOGO_RGB.png">
                                    <span class="tourcat">
                                        <img *ngIf="item.kategorie == 1" src="assets/images/categorie/Locatetable__Österreichisch.png" class="icon" placement="top" ngbTooltip="Österreichische Küche"  alt="LocateTable - Österreichisch"> 
                                        <img *ngIf="item.kategorie == 2" src="assets/images/categorie/Locatetable__Pizza.png" class="icon" placement="top" ngbTooltip="Italienisch" alt="LocateTable - Pizza"> 
                                        <img *ngIf="item.kategorie == 3" src="assets/images/categorie/Locatetable__Bar.png" class="icon" placement="top" ngbTooltip="Bar" alt="LocateTable - Bar"> 
                                        <img *ngIf="item.kategorie == 4" src="assets/images/categorie/Locatetable__Pizza.png" class="icon" placement="top" ngbTooltip="Pizza" alt="LocateTable - Pizza"> 
                                        <img *ngIf="item.kategorie == 5" src="assets/images/categorie/Locatetable__Fastfood.png" class="icon" placement="top" ngbTooltip="FastFood" alt="LocateTable - FastFood"> 
                                        <img *ngIf="item.kategorie == 6" src="assets/images/categorie/Locatetable__Pasta.png" class="icon" placement="top" ngbTooltip="Pasta" alt="LocateTable - Pasta"> 
                                        <img *ngIf="item.kategorie == 7" src="assets/images/categorie/Locatetable__Asiatisch.png" class="icon" placement="top" ngbTooltip="Asiatisch" alt="LocateTable - Asiatisch"> 
                                        <img *ngIf="item.kategorie == 8" src="assets/images/categorie/Locatetable__Cafe.png" class="icon" placement="top" ngbTooltip="Cafe" alt="LocateTable - Cafe"> 
                                        <img *ngIf="item.kategorie == 9" src="assets/images/categorie/Locatetable__Burger.png" class="icon" placement="top" ngbTooltip="Burger" alt="LocateTable - Burger">
                                        <img *ngIf="item.kategorie == 10" src="assets/images/categorie/Locatetable__Mexikanisch.png" class="icon" placement="top" ngbTooltip="Mexikanisch" alt="LocateTable - Mexikanisch">
                                        <!-- Kategorie 2 -->
                                        <img *ngIf="item.kategorie1 == 1" src="assets/images/categorie/Locatetable__Österreichisch.png" class="icon" placement="top" ngbTooltip="Österreichische Küche"  alt="LocateTable - Österreichisch"> 
                                        <img *ngIf="item.kategorie1 == 2" src="assets/images/categorie/Locatetable__Pizza.png" class="icon" placement="top" ngbTooltip="Italienisch" alt="LocateTable - Pizza"> 
                                        <img *ngIf="item.kategorie1 == 3" src="assets/images/categorie/Locatetable__Bar.png" class="icon" placement="top" ngbTooltip="Bar" alt="LocateTable - Bar"> 
                                        <img *ngIf="item.kategorie1 == 4" src="assets/images/categorie/Locatetable__Pizza.png" class="icon" placement="top" ngbTooltip="Pizza" alt="LocateTable - Pizza"> 
                                        <img *ngIf="item.kategorie1 == 5" src="assets/images/categorie/Locatetable__Fastfood.png" class="icon" placement="top" ngbTooltip="FastFood" alt="LocateTable - FastDoof"> 
                                        <img *ngIf="item.kategorie1 == 6" src="assets/images/categorie/Locatetable__Pasta.png" class="icon" placement="top" ngbTooltip="Pasta" alt="LocateTable - Pasta"> 
                                        <img *ngIf="item.kategorie1 == 7" src="assets/images/categorie/Locatetable__Asiatisch.png" class="icon" placement="top" ngbTooltip="Asiatisch" alt="LocateTable - Asiatisch"> 
                                        <img *ngIf="item.kategorie1 == 8" src="assets/images/categorie/Locatetable__Cafe.png" class="icon" placement="top" ngbTooltip="Cafe" alt="LocateTable - Cafe"> 
                                        <img *ngIf="item.kategorie1 == 9" src="assets/images/categorie/Locatetable__Burger.png" class="icon" placement="top" ngbTooltip="Burger" alt="LocateTable - Burger">
                                        <img *ngIf="item.kategorie1 == 10" src="assets/images/categorie/Locatetable__Mexikanisch.png" class="icon" placement="top" ngbTooltip="Mexikanisch" alt="LocateTable - Mexikanisch">
                                        
                                        </span>

                                    <span class="tourday hot">{{item.preis}}</span>
                                </div>
                                <figcaption>
                                    <h4 class="entry-title">
                                        <span class="restaurant">{{item.name}}</span></h4>
                                    <span class="standort">{{item.standort}}</span>

                                </figcaption>
                                <div class="maps">
                                    <i class="fas fa-map-marker-alt"></i><a target="_blank" href="{{'https://www.google.com/maps/search/?api=1&query=' + item.standort}}">In Google Maps zeigen</a>
                                </div>
                                <div class="tourbtn">
                                    <a [routerLink]="['/reserkunde', item.id]" class="btn btn-sm">
                                        <span>TISCH RESERVIEREN</span>
                                    </a>
                                </div>
                            </figure>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </section>
    <section id="description">

        <img src="assets/images/Locatetable_LOGOMARK_RGB.svg">
        <h3>Das ist LocateTable</h3>
        <hr class="solid">
        <div class="container">
            <p>Wir machen dein Restaurant online sichtbar! Potenzielle Gäste finden dich über unsere Website und buchen unkompliziert und schnell einen Tisch in deinem Restaurant. Das beste daran: Unser Service ist zu 100% kostenlos! </p>
        </div>
        <div class="tourbtn"><a class="btn btn-sm" [routerLink]="['register']">Restaurant registrieren</a>
        </div>
    </section>
    <section id="Impressum">


        <div class="tourbtn">

            <div class="row d-flex justify-content-center " style="margin-right: 0px; margin-left: 0px;">
                <h3>Teile uns gerne mit deinen Freunden</h3>
                <share-buttons [theme]="'default'" [include]="['facebook','telegram','messenger','whatsapp','sms','email','print','copy']" [show]="6" [size]="1" [url]="this.url" [autoSetMeta]="false"></share-buttons>

            </div>
            <div class="row d-flex justify-content-center">
                <h4>
                    <a [routerLink]="['register']">Registrieren</a> -
                    <a [routerLink]="['anleitung']" style="margin-bottom: 5rem;">Anleitung für Restaurants</a>
                </h4>
            </div>
            <div class="row d-flex justify-content-center">

                <a [routerLink]="['impressum']">Impressum</a> -
                <a [routerLink]="['datenschutz']" style="margin-bottom: 5rem;">Datenschutz</a>
            </div>
        </div>
    </section>
</body>