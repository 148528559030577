import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'app-value',
  templateUrl: './value.component.html',
  styleUrls: ['./value.component.css']
})
export class ValueComponent implements OnInit {
values: any;
  constructor(private http: HttpClient) { }

  ngOnInit(): void {
  }

  getValues(){
    return this.http.get("http://locatetable.dynv6.net/regapi/user-info.php").subscribe(response=>{
     //console.log(response);
      this.values = response;
    },error => {
     //console.log("error");
    });
  }





}
