<div style="margin-top: 10rem; margin-bottom: 10rem;" class="row">
    <div class="col-md-6 offset-md-3 mt-4">
        <h3>Passwort ändern</h3>
        <form #f="ngForm" (ngSubmit)="onSubmit(f)">
            <div class="form-group">
                <label for="registerUsername">Code</label>
                <input type="text" name="code" ngModel required #username="ngModel" class="form-control" id="Code" placeholder="Code">
            </div>
            <div class="form-group">
                <label for="registerPassword">Neues Passwort</label>
                <input type="password" name="password" ngModel required #password="ngModel" class="form-control" id="registerPassword" placeholder="Passwort">
            </div>
            <button type="submit" [disabled]="f.invalid" class="btn btn-primary">Ändern <i class="fas fa-sign-in-alt"></i></button>
        </form>
    </div>
</div>