import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ProgressBarService } from 'src/app/shared/services/progress-bar.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  constructor(private authService: AuthService, public progressBar: ProgressBarService, private router: Router) { }

  ngOnInit(): void {
  }
  onSubmit(f: NgForm) {
    this.progressBar.startLoading();
    const pwresetObserver = {
      next: () => {
        //console.log('Service Ok');
        this.progressBar.setSuccess();
        this.progressBar.completeLoading();
        localStorage.setItem('email', f.value.email);
        this.router.navigate(['/change-password']);
    },
      error: (err: any) => {
        //console.log(err);
        this.progressBar.completeLoading();
      }
    };
    this.authService.resetpw(f.value).subscribe(pwresetObserver);

  }
}
