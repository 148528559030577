import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-lastrow',
  templateUrl: './lastrow.component.html',
  styleUrls: ['./lastrow.component.css']
})
export class LastrowComponent implements OnInit {
  url: string = "https://www.locatetable.at/";
  constructor(public router:Router) { }

  ngOnInit(): void {
   //console.log(this.router.url)
    
  }
  isHomeRoute() {
    return this.router.url === '/';
  }

}
