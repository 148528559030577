<!-- <footer id="sticky-footer" class="page-footer font-small purple pt-4 fixed-bottom" style="background-color: #95c356;">
    <div class="container text-center mb-3">
        <a [routerLink]="['resbearbeiten']"><span class="material-icons">
            list_alt
            </span></a> <a [routerLink]="['']"><span class="material-icons">
                search
                </span></a>
    </div>
</footer>

<style>
    .footer {
        position: relative;
        margin-top: -150px;
        /* negative value of footer height */
        height: 150px;
        clear: both;
        padding-top: 20px;
    }
</style> -->


<footer class="d-inline-flex page-footer fixed-bottom" id="sticky-footer">
    <div class="col-2 offset-4">
        <div><a [routerLink]="['resbearbeiten']"><span class="d-inline-flex borderfooter"><i class="fa fa-calendar"></i></span>
                <p class="footertext d-none d-lg-block">Reservierungen</p>
            </a></div>
    </div>
    <div class="col-2">
        <div><a [routerLink]="['']"><span><i class="fa fa-search"></i></span>
                <p class="footertext d-none d-lg-block">Suchen</p>
            </a></div>
    </div>

</footer>