import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ProgressBarService } from 'src/app/shared/services/progress-bar.service';

import Swal from 'sweetalert2';


@Component({
  selector: 'app-speisekarte',
  templateUrl: './speisekarte.component.html',
  styleUrls: ['./speisekarte.component.css']
})
export class SpeisekarteComponent implements OnInit {
  constructor(private router: Router, public authService: AuthService, public activerouter:ActivatedRoute, public progressBar: ProgressBarService, public _location: Location) { }
   ngOnInit(): void {
   //console.log(this.activerouter.snapshot.params.id);
    this.progressBar.startLoading();
    /* let valueObserver = {
      next: () => {
        console.log('Service Ok');
        this.progressBar.setSuccess();
        this.progressBar.completeLoading();
        this.router.navigate(['/profile']);
    },
      error: (err: any) => {
        console.log(err);
        this.progressBar.completeLoading();
      }
    }; */
 this.authService.getpdf(this.activerouter.snapshot.params.id);


    if (this.authService.values?.success){
      this.progressBar.setSuccess();
      this.progressBar.completeLoading();
      
    }
    else {
      this.progressBar.completeLoading();

    }
 
  }
  backClicked() {
    this._location.back();
   //console.log("TEST");
  }


}
