<div class="container reserv" style="margin-top: 6rem; margin-bottom: 6rem;" *ngIf="this.authService.valuesallres">
    <div class="container reserv">
        <h3>Deine Reservierungen</h3>
    </div>
    <div class="container reserv">
        <div *ngFor="let item of this.authService.valuesallres.message">
            <div class="row" *ngIf="item.status == 0" style="margin-top: 2rem;">
                <div class="container reservationcard">
                    <div class="row">
                        <div class="col-md-12 resID"><span class="reservationid">Reservierung ID:&nbsp; {{item.resid}}</span></div>
                    </div>
                    <div class="row reserv">
                        <div class="col">
                            <h6>Name des Gastes</h6>
                            <p class="dates">{{item.name}}</p>
                        </div>
                        <div class="col-md-4">
                            <h6>Personen</h6>
                            <p class="dates">{{item.personen}}</p>
                        </div>
                        <div class="col-md-4">
                            <h6>Datum/Uhrzeit</h6>
                            <p class="dates">{{item.datum | date:'dd-MM-yyyy'}} - {{item.zeit}} Uhr</p>
                        </div>
                    </div>
                    <div class="row reserv">
                        <div class="col col-md-4">
                            <h6>Telefonnummer</h6>
                            <p class="dates">+{{item.telefon}}</p>
                        </div>
                        <div class="col col-md-5">
                            <h6>E-Mail Adresse</h6>
                            <p class="dates">{{item.email}}</p>
                        </div>
                        <div class="col col-md-3">
                            <h6>Status</h6>
                            <p class="dates" *ngIf="item.status == 0">Wartet</p>
                            <p class="dates" *ngIf="item.status == 1">Aktiv</p>
                            <p class="dates" *ngIf="item.status == 2">Abgelehnt</p>

                        </div>
                    </div>
                    <div class="row reserv">
                        <div class="col">
                            <h6>Anmerkungen</h6>
                            <p class="dates">{{item.bemerkung}}</p>
                        </div>
                        <div class="col-md-4">
                            <button class="btn btn-primary col-md-12 ablehnen" type="button" (click)="bearbeiten(2, item.resid)" *ngIf="item.status == '0'">Ablehnen</button>
                            <button class="btn btn-primary col-md-12" type="button" (click)="bearbeiten(1, item.resid)" *ngIf="item.status == '0'">Bestätigen</button>
                            <button class="btn btn-primary col-md-12" type="button" (click)="bearbeiten(3, item.resid)" *ngIf="item.status == '1'">Stornieren</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div class="container reserv" style="margin-top: 2rem;">
        <h4>Über Unterstützung - freuen wir uns</h4>

        <div class="row mt-1">
            <p>
                Pro Resverierung entstehen Kosten von 40Cent, da vor allem SMS Nachrichten kostenpflichtig sind. Zudem kommen Server Kosten etc. dazu. Mit einer Spende von 40Cent pro Reservierung würde es beim fortbestehen von <strong>LocateTable</strong>                helfen.
                <br>
                <strong>Es ist natürlich auch jeder andere Betrag möglich. Jedoch min. 1€</strong></p>
        </div>
        <div class="row">
            <div class="col-6" *ngIf="this.authService.valuesallres">
                <div class="row">
                    <div class="col-3">
                        <input type="number" class="form-control offset-1" ngModel (change)="onChangeEventBetrag()" [(ngModel)]="this.authService.betrag" name="betrag" id="betrag" placeholder="this.authService.betrag">
                    </div>
                    <div class="col-1">
                        <p>€</p>
                    </div>
                    <div class="col-5">
                        Reservierungen {{this.authService.valuesallres ? this.authService.valuesallres.rescount : ""}} seit: {{this.authService.valuesallres ? this.authService.valuesallres.letztespende : ""}}
                    </div>
                </div>
            </div>

            <div class="col-4" style="margin-top: 2rem;" *ngIf="this.showSuccess">
                <!-- <p style="color: red;">Achtung!!! Test System.</p> -->
                <ngx-paypal [config]="payPalConfig"></ngx-paypal>
            </div>
        </div>
    </div>
    <div class="container reserv" style="margin-top: 2rem;">
        <div *ngIf="this.authService.dataArray" class="container reservationcard">
            <ngx-event-calendar [showAddButton]="true" [language]="'en'" [dataSource]="this.authService.dataArray" (dayEvents)="selectDay($event)">
            </ngx-event-calendar>
        </div>
    </div>
    <div *ngIf="show" class="container reserv" style="margin-top: 2rem;">
        <div *ngFor="let item of this.OutputHtml">
            <div class="container reservationcard">
                <div class="row">
                    <div class="col-md-12 resID"><span class="reservationid">Reservierung ID:&nbsp; {{item.resid}}</span></div>
                </div>
                <div class="row reserv">
                    <div class="col">
                        <h6>Name des Gastes</h6>
                        <p class="dates">{{item.name}}</p>
                    </div>
                    <div class="col-md-4">
                        <h6>Personen</h6>
                        <p class="dates">{{item.personen}}</p>
                    </div>
                    <div class="col-md-4">
                        <h6>Datum/Uhrzeit</h6>
                        <p class="dates">{{item.datum | date:'dd-MM-yyyy'}} - {{item.zeit}} Uhr</p>
                    </div>
                </div>
                <div class="row reserv">
                    <div class="col col-md-4">
                        <h6>Telefonnummer</h6>
                        <p class="dates">+{{item.telefon}}</p>
                    </div>
                    <div class="col col-md-5">
                        <h6>E-Mail Adresse</h6>
                        <p class="dates">{{item.email}}</p>
                    </div>
                    <div class="col col-md-3">
                        <h6>Status</h6>
                        <p class="dates" *ngIf="item.status == 0">Wartet</p>
                        <p class="dates" *ngIf="item.status == 1">Aktiv</p>
                        <p class="dates" *ngIf="item.status == 2">Abgelehnt</p>
                        <p class="dates" *ngIf="item.status == 3">Storniert</p>
                    </div>
                </div>
                <div class="row reserv">
                    <div class="col">
                        <h6>Anmerkungen</h6>
                        <p class="dates">{{item.bemerkung}}</p>
                    </div>
                    <div class="col-md-4">
                        <button class="btn btn-primary col-md-12 ablehnen" type="button" (click)="bearbeiten(2, item.resid)" *ngIf="item.status == '0'">Ablehnen</button>
                        <button class="btn btn-primary col-md-12" type="button" (click)="bearbeiten(1, item.resid)" *ngIf="item.status == '0'">Bestätigen</button>
                        <button class="btn btn-primary col-md-12" type="button" (click)="bearbeiten(3, item.resid)" *ngIf="item.status == '1'">Stornieren</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>