import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ProgressBarService } from 'src/app/shared/services/progress-bar.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  constructor(private authService: AuthService, public progressBar: ProgressBarService,) { }
  changevalue: any = {};
  ngOnInit(): void {
  }

  onSubmit(f: NgForm) {
    this.progressBar.startLoading();
    const changepwObserver = {
      next: () => {
        //console.log('Service Ok');
        this.progressBar.setSuccess();
        this.progressBar.completeLoading();
    },
      error: (err: any) => {
        //console.log(err);
        this.progressBar.completeLoading();
      }
    };
    this.changevalue.email = localStorage.getItem('email') as string | null;
    this.changevalue.code = f.value.code;
    this.changevalue.password = f.value.password;
    //console.log(this.changevalue);
    this.authService.changepw(this.changevalue).subscribe(changepwObserver);

  }
}
