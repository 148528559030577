import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProfileComponent } from './components/profile/profile.component';
import { ReservierungWComponent } from './components/reservierung-w/reservierung-w.component';
import { SpeisekarteComponent } from './components/speisekarte/speisekarte.component';

const routes: Routes = [
  {path: 'profile', component: ProfileComponent},
  {path: 'speisekarte/:id', component: SpeisekarteComponent},
  {path: 'reserlogin', component: ReservierungWComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
