import { Component, OnInit, NgModule, EventEmitter, Output } from '@angular/core';
import { Options } from 'ngx-google-places-autocomplete/objects/options/options';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';






@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  //Local Variable defined 
  formattedaddress : any = "";
  addresslat = "";
  addresslng = "";
  address: string = "";
  input: string = "";
  showalert: string | undefined = undefined;
  private geoCoder = new google.maps.Geocoder();
  url: string = "https://www.locatetable.at/";

  
  constructor( private router:Router, public authService: AuthService) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.formattedaddress = undefined;
    this.addresslat = "";
    this.addresslng = "";
    this.showalert = undefined;
    this.url = this.url + this.router.url;


    this.authService.getnewbest();

   //console.log(window.location.hostname);


  }




  
  public AddressChange(address: any) {

    //setting address from API to local variable 
    this.formattedaddress = address.formatted_address;
    this.addresslat = address.geometry.location.lat();
    this.addresslng = address.geometry.location.lng();
   //console.log(address);
   //console.log(address.geometry.location.lat());
   //console.log(address.geometry.location.lng());
   //console.log(this.formattedaddress);
    this.showalert = undefined;

    
  }


  options: Options = {
    types: [],
    componentRestrictions: { country: 'AT' },
    bounds: null as any,
    fields: null as any,
    strictBounds: false,
    origin: null as any
  };
  onSubmit(f: NgForm) {
    if (this.formattedaddress == undefined ){
      if ( this.input == ""){
       //console.log("Get Location");
        this.getLocationService();
        
      }else{
        this.showalert = "true";
      };
      
    }else{
      this.router.navigate(['/ergebnis', this.addresslat + ";"+ this.addresslng + ";" + this.formattedaddress]);
    }
  };
  getLocationService():Promise<any>{
   //console.log("In function");
    return new Promise((resolve, reject)=>{
      navigator.geolocation.getCurrentPosition(resp=>{
        resolve({lng: resp.coords.longitude, lat: resp.coords.latitude})
       //console.log(resp.coords.latitude);
       //console.log(resp.coords.longitude);

        this.router.navigate(['/ergebnis', resp.coords.latitude + ";"+ resp.coords.longitude ]);
        
      },
      
        err => {
          reject(err);
        });
    });
  };

  changeFn(e : any) {
    this.input = e.target.value;
   //console.log(e.target.value);
    setTimeout(() => {
      if ((this.formattedaddress !== "" && this.formattedaddress !== undefined) && e.target.value == ""){
        this.showalert = undefined;
        this.formattedaddress = undefined;
        this.addresslat = "";
        this.addresslng = "";
      
      }else if(this.formattedaddress == undefined && e.target.value !== ""){
          this.showalert = "true"; 
      }else{
        this.showalert = undefined;
      }
    }, 500)
  };

  



}
