import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GooglePlaceModule } from "ngx-google-places-autocomplete"; 

import { DashboardRoutingModule } from './dashboard-routing.module';
import { ProfileComponent } from './components/profile/profile.component';
import { SpeisekarteComponent } from './components/speisekarte/speisekarte.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { SharedModule } from '../shared/shared.module';
import Swal from 'sweetalert2';
import { UiSwitchModule } from 'ngx-toggle-switch';
import { ReservierungWComponent } from './components/reservierung-w/reservierung-w.component';
import { NgxPayPalModule } from 'ngx-paypal';
import {NgxEventCalendarModule} from 'ngx-event-calendar';
import { FlexLayoutModule } from '@angular/flex-layout';






@NgModule({
  declarations: [ProfileComponent, SpeisekarteComponent, ReservierungWComponent],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    GooglePlaceModule,
    PdfViewerModule,
    SharedModule,
    UiSwitchModule,
    NgxPayPalModule,
    NgxEventCalendarModule,
    FlexLayoutModule


  ],
  exports: [
    ProfileComponent,
    SpeisekarteComponent,
    ReservierungWComponent

  ]
})
export class DashboardModule { }
