import { Component, OnInit, NgModule, ElementRef } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ProgressBarService } from 'src/app/shared/services/progress-bar.service';
import { NgForm, ReactiveFormsModule, FormGroup, FormControl  } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';
import { ThrowStmt } from '@angular/compiler';
import Swal from 'sweetalert2';




@Component({
  selector: 'app-reservierung-k',
  templateUrl: './reservierung-k.component.html',
  styleUrls: ['./reservierung-k.component.css']
})
export class ReservierungKComponent implements OnInit {
 
  options: string[] | any;
  
  selectedOption: string | any;

  tischejson: Tischinterface | any;
  
  rows: string[] | any;

  isEditable: boolean = false;

  zeit: string | any;
  zeiteingabe: string[] = ["00" , "00"];
  zeitausgabe: number = 0;
  zeitausgabeh: number = 0;

  datum: Date | any;
  date: Date | any;

  phonenum: string | any;
  isPhonevalide: boolean = false;

  mintime: string = "00:00";
  maxtime: string = "00:00";
  
datenreser: any;
  constructor( public activerouter:ActivatedRoute, public router: Router, public authService: AuthService, public progressBar: ProgressBarService, public http: HttpClient, public elementRef: ElementRef, public _location: Location) { }
  minDate: any;
  showform = true;
  
  ngOnInit(): void {
/*     this.http.get("http://localhost/login/regapi/uploads/50/tische50.json").subscribe(data =>{
     //console.log(data);
      this.tischejson = data;
      this.rows = this.tischejson;
     //console.log(this.rows);
    }) */
    this.options = [
      '+43',
      '+49',
   ];
   
   this.selectedOption = this.options[0];
   //console.log(this.activerouter.snapshot.params.id);
    window.scrollTo(0, 0);

    this.minDate = new Date;
    this.minDate = new Date().getFullYear() + '-' + ("0" + ((new Date()).getMonth() + 1)).slice(-2) + '-' + ("0" + ((new Date()).getDate())).slice(-2);
 //console.log(this.minDate);
    this.authService.getreserdata(this.activerouter.snapshot.params.id);
    

      if (this.authService.values?.success){
        this.progressBar.setSuccess();
        this.progressBar.completeLoading();
        
      }
      else {
        this.progressBar.completeLoading();
  
      }


  }
  onChangeEventDate(event: any){
    this.date = new Date(event.target.value);
   //console.log(this.date.getDay());
   //console.log(this.authService.splitted[this.date.getDay()]);

    if (this.authService.splitted[(this.date.getDay() - 1) * 4] == "" )
    {
     //console.log("Tag ist nicht verfügbar");
      this.isEditable = true;
    }
    else{
      this.isEditable = false;
    }
  }
  phoneonchange(event: any){
    //this.phonenum = Number(event.target.value);
   //console.log(this.date.getDay());
   //console.log(this.authService.splitted[this.date.getDay()]);
    if(this.phonenum[0] == 0){
      
      this.phonenum = Number(this.phonenum);
      
    }
    if (this.phonenum.length < 7){
      this.isPhonevalide = true;
      
    }else{
      this.isPhonevalide = false;
      
    }
 
  }
  
  onChangeEvent(event: any){
    //minDate - date

   //console.log(event.target.value);
    this.zeiteingabe = event.target.value.split(":");
   //console.log(this.authService.splitted);
    if(this.date != undefined){
      this.mintime = this.authService.splitted[(this.date.getDay() - 1) * 4];
      this.maxtime = this.authService.splitted[(this.date.getDay() + 3) * 4] 
      if (this.authService.splitted[(this.date.getDay() +3) * 4] != ""){
       //console.log("Datum ist vorhanden");
        this.maxtime = this.authService.splitted[(this.date.getDay() +3) * 4] 
      }
    }
    if (parseInt(this.zeiteingabe[1]) % 15 != 0){
      this.zeitausgabe = parseInt((parseInt(this.zeiteingabe[1]) / 15).toFixed(0));
      this.zeitausgabeh = parseInt(this.zeiteingabe[0]);
     //console.log(this.zeitausgabe * 15);
      this.zeitausgabe = this.zeitausgabe * 15;
      if (this.zeitausgabe == 60){
        this.zeitausgabeh = parseInt(this.zeiteingabe[0]) + 1;
        this.zeitausgabe = 0;
      }
      //this.zeitausgabe[1] = ((parseInt(this.zeiteingabe[1]) / 15)) * 15;
      if ( this.zeitausgabe < 10){
        this.zeit = this.zeitausgabeh + ":0" + this.zeitausgabe;
      }else{
        this.zeit = this.zeitausgabeh + ":" + this.zeitausgabe;
      }
      
    }
    
   //console.log(this.zeit);

  }
  backClicked() {
    this._location.back();
   //console.log("TEST");
  }

 
  changeSelectedOption(option: string) {
    this.selectedOption = option;
    
 }
 

 tischwahl(value: string){
  //console.log(value);
 }

  onSubmit(f: NgForm) {
   //console.log(f.value);
   
    this.datenreser = f.value;
   //console.log(this.activerouter.snapshot.params.id);
    this.datenreser.id = this.activerouter.snapshot.params.id;
    
   //console.log(this.datenreser);
    this.datenreser.telefon = this.selectedOption + this.datenreser.telefon;
    if(this.datenreser.beintraechtigung){
      this.datenreser.beintraechtigung = "1";
    }else
    {
      this.datenreser.beintraechtigung = "0";
    }
    if(this.datenreser.kinder){
      this.datenreser.kinder = "1";
    }else
    {
      this.datenreser.kinder = "0";
    }
    let timerInterval: any;
    Swal.fire({
      title: 'Reservierung wird verarbeitet',
      html: 'Bitte warten Sie noch einen Augenblick',
      timer: 20000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading()
        timerInterval = setInterval(() => {
          const content = Swal.getHtmlContainer()
          
        }, 100)
      },
      willClose: () => {
        clearInterval(timerInterval)
      }
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
        console.log('I was closed by the timer')
      }
    })


    this.progressBar.startLoading();
    const reserObserver = {
      next: () => {
       //console.log('Service Ok');
        this.progressBar.setSuccess();
        this.progressBar.completeLoading();
        this.showform = this.authService.reserviert;
        this.backClicked();
    },
      error: (err: any) => {
       //console.log(err);
        this.progressBar.completeLoading();
      }
    };
    this.authService.saveres(this.datenreser).subscribe(reserObserver);
 


  }
  telInputObject(obj: any) {
   //console.log(obj);
    obj.setCountry('in');
  }
  isShown: boolean = false ; // hidden by default


  toggleShow() {
  
  this.isShown = ! this.isShown;
  
  }





}


export interface Tischinterface {
  
  id: Number,
  name: String,
  email: String,
  gender: String,

}


