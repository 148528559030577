import { NgModule,  CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { SharedModule } from './shared/shared.module';
import { ValueComponent } from './value/value.component';
import { HttpClientModule } from '@angular/common/http';
import { HomeComponent } from './pages/home/home.component';
import { DashboardModule } from './dashboard/dashboard.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CollapseComponent, MDBBootstrapModule } from 'angular-bootstrap-md';
import { OverviewComponent } from './pages/overview/overview.component';

import { GooglePlaceModule } from "ngx-google-places-autocomplete"; 
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule } from "ngx-spinner";
import { ReservierungKComponent } from './pages/reservierung-k/reservierung-k.component';
import { CommonModule } from '@angular/common';
import { ReservierungbearbeitenComponent } from './pages/reservierungbearbeiten/reservierungbearbeiten.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import {MatIconModule} from '@angular/material/icon';
import { MatButtonModule } from "@angular/material/button";
import { NgxMaskModule, IConfig } from 'ngx-mask';



import { SafePipe } from './safe.pipe';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ImpressumComponent } from './pages/impressum/impressum.component';
import { DatenschutzComponent } from './pages/datenschutz/datenschutz.component';
import {NgcCookieConsentModule, NgcCookieConsentConfig} from 'ngx-cookieconsent';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';
import { AnleitungComponent } from './pages/anleitung/anleitung.component';

const cookieConfig:NgcCookieConsentConfig = {
  "cookie": {
    "domain": "locatetable.at"
  },
  "position": "top",
  "theme": "block",
  "palette": {
    "popup": {
      "background": "#000000",
      "text": "#ffffff",
      "link": "#ffffff"
    },
    "button": {
      "background": "#94c357",
      "text": "#000000",
      "border": "transparent"
    }
  },
  "type": "info",
  "content": {
    "message": "Diese Seite verwendet Cookies. Eventuell gibt auch bei Ihrer Reservierung einen Keks dazu.",
    "dismiss": "Einverstanden!",
    "deny": "Refuse cookies",
    "link": "Erfahre mehr!",
    "href": "https://cookiesandyou.com",
    "policy": "Cookie Policy"
  }
};
 

 


@NgModule({
  declarations: [
    AppComponent,
    ValueComponent,
    HomeComponent,
    OverviewComponent,
    ReservierungKComponent,
    ReservierungbearbeitenComponent,
    SafePipe,
    ImpressumComponent,
    DatenschutzComponent,
    AnleitungComponent,
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AuthModule,
    SharedModule,
    HttpClientModule,
    DashboardModule,
    BrowserAnimationsModule,
    MDBBootstrapModule.forRoot(),
    GooglePlaceModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    CommonModule,
    BsDropdownModule.forRoot(),
    MatIconModule,
    MatButtonModule,
    NgbModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    NgcCookieConsentModule.forRoot(cookieConfig),
    NgxGoogleAnalyticsModule.forRoot('G-LH1RFPPX0Q'),
    NgxMaskModule.forRoot({showMaskTyped : true,
      // clearIfNotMatch : true
    }),
    
 

 
    
    
  
 
  ],
  providers: [],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
