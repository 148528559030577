import { Component, OnInit } from '@angular/core';
import { AuthService } from './shared/services/auth.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { SwUpdate } from '@angular/service-worker';

import { Subscription } from 'rxjs';
import { NgcCookieConsentService, NgcInitializeEvent, NgcNoCookieLawEvent, NgcStatusChangeEvent } from 'ngx-cookieconsent';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
   //keep refs to subscriptions to be able to unsubscribe later
   private popupOpenSubscription: Subscription | undefined;
   private popupCloseSubscription: Subscription | undefined;
   private initializeSubscription: Subscription| undefined;
   private statusChangeSubscription: Subscription| undefined;
   private revokeChoiceSubscription: Subscription| undefined;
   private noCookieLawSubscription: Subscription| undefined;
  helper = new JwtHelperService();
  constructor(private authService : AuthService, private swUpdate: SwUpdate, private ccService: NgcCookieConsentService){}
  ngOnInit(): void {
    this.setupUpdates();
    const token: string | undefined  = localStorage.getItem('token') as string | undefined;
    this.authService.decodeToken = this.helper.decodeToken(token);
    if (this.authService.decodeToken) {
      //console.log("Token is not Deleted");
    }
    window.scrollTo(0, 0);
    
    this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      });
 
    this.popupCloseSubscription = this.ccService.popupClose$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      });
 
    this.initializeSubscription = this.ccService.initialize$.subscribe(
      (event: NgcInitializeEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      });
 
    this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      });
 
    this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      });
 
      this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
      (event: NgcNoCookieLawEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      });
  }

  setupUpdates() {
    if (this.swUpdate.isEnabled){
      this.swUpdate.available.subscribe(u => {
        // Update wurde entdeckt
  
        // Update herunterladen
        this.swUpdate.activateUpdate().then(e => {
          // Update wurde heruntergeladen
  
          const message = 'Application has been updated';
          const action = 'Ok, Reload!';
  
          // Benutzer auf Update hinweisen und Seite neu laden
          location.reload()

        });
      });
      // Auf Updates prüfen
    this.swUpdate.checkForUpdate();
    }
  }
}
