import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2';
import { EventData } from 'ngx-event-calendar/lib/interface/event-data';
import { Router } from '@angular/router';





@Injectable({
  providedIn: 'root'
})
export class AuthService { 
  dataArray: EventData[] = testData ; 
  //authURL = "http://localhost/Login/regapi/";
  authURL = "/regapi/"; 
  helper = new JwtHelperService;
  decodeToken: I1 | undefined;
  //confirmeEmailUrl = "http://localhost:4200/confirm-email/";
  confirmeEmailUrl= "/confirm-email/";
  values: databack | any;
  valuesuser: databackuser | any;
  valuesres: databackres | any;
  valuesstorno: databackres | any;
  valuesallres: databackallreser | any;
  uploadret: returnupload | any;
  regid: any;
  reserviert = false;
  buttonstorno: number | undefined;
  buttonwarten: number | undefined;
  buttonstornow: number | undefined;
  foundres: number | undefined;
  status: string = "";
  zeitenaus: zeitentag[] | any;
  zeitenausgabe: MoSo[] = [];
  zeitenausgabeoverview: MoSo[]= [];
  zeitenausgabezwischen: string= "";
  showprofile: boolean = false;
  wochentag = ["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"] ;


  betrag = 0.00;

  splitted = [];

  constructor(private http: HttpClient, public router: Router) { }

  login(model: any) {
    ////console.log(model);
    return this.http.post(this.authURL + "login.php", model).pipe(
      map((response: any) => {
        const user: IResponseLogin = response as IResponseLogin;

        ////console.log(user);
        if (user.success) {
          localStorage.setItem('token', user.token);
          this.decodeToken = this.helper.decodeToken(user.token);
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Login war erfolgreich',
            showConfirmButton: false,
            timer: 1500
          })
          this.router.navigate(['/profile']);
        } else {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Leider ist ein Fehler aufgetreten - ' + user.message,
            showConfirmButton: false,
            timer: 1500
          })
        }
      })
    )
  }
  // Method 
  register(model: any) {

    return this.http.post(this.authURL + "register.php", model).pipe(
      map((response: any) => {
        const user: IResponseLogin = response as IResponseLogin;

        ////console.log(user);
        if (user.success) {
          ////console.log('erfolgreich Registriert!');
          //localStorage.setItem('token', user.token);
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Registrierung war erfolgreich! Warte nun auf deine E-Mail',
            confirmButtonText:
              '<i class="fa fa-thumbs-up"></i> Gelesen!',
          })
          this.router.navigate(['/login']);
        }
        else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: user.message,
            showConfirmButton: false,
            timer: 4000
          })

        }
      })
    )
  }

  confirmEmail(model: any) {
    ////console.log(model);
    return this.http.post(this.authURL + "confirmmail.php", model).pipe(
      map((response: any) => {
        const user: IResponseLogin = response as IResponseLogin;
        //console.log(user);
      })
    )
  }

  resetpw(model: any) {
    //console.log(model);
    return this.http.post(this.authURL + "fpwcode.php", model).pipe(
      map((response: any) => {
        const user: IResponseLogin = response as IResponseLogin;

        //console.log(user);
        if (user.success) {
          //console.log("Code wurde gesendet!");
        }
      })
    )
  }

  loggedIn() {
    const token: string | undefined = localStorage.getItem('token') as string | undefined;
    return !this.helper.isTokenExpired(token);

  }

  changepw(model: any) {
    return this.http.post(this.authURL + "fpwchange.php", model).pipe(
      map((response: any) => {
        const user: IResponseLogin = response as IResponseLogin;

        //console.log(user);
        if (user.success) {
          //console.log('erfolgreich geändert!');
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Passwort erfoglreich geändert',
              showConfirmButton: false,
              timer: 1500
            })
            this.router.navigate(['/profile']);
        }else {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Leider ist ein Fehler aufgetreten' + user.message,
            showConfirmButton: false,
            timer: 1500
          })
        }
      })
    )
  }
  
  userchangepw(model: any) {
    let token = 'Bearer ' + localStorage.getItem('token') as string;
    const headers = { 'X-Authorization': token }
    //console.log(model);
    return this.http.post(this.authURL + "userpwchange.php",  model, { headers }).pipe(
      map((response: any) => {
        const user: IResponseLogin = response as IResponseLogin;

        //console.log(user);
        if (user.success) {
          //console.log('erfolgreich geändert!');
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Passwort erfoglreich geändert',
              showConfirmButton: false,
              timer: 1500
            })
            this.router.navigate(['/login']);
        }else {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Leider ist ein Fehler aufgetreten' + user.message,
            showConfirmButton: false,
            timer: 1500
          })
        }
      })
    )
  }
  

  getValues() {
    let token = 'Bearer ' + localStorage.getItem('token') as string;
    const headers = { 'X-Authorization': token }
    //console.log(headers);
    return this.http.get(this.authURL + "user-data.php", { headers }).subscribe(response => {
      //console.log(response);
      this.values = response as databack;
      
      if (this.values.success){
        this.values.success = true;

        this.stringtotime(this.values.message.oeffnungszeiten);
        if (this.values.message.rescount != 0){
          this.betrag = Number(this.values.message.rescount) * 0.40;
          this.betrag = Math.round(this.betrag * 100) / 100;
        }
        //console.log(this.betrag);




        //console.log(this.values.message.oeffnungszeiten);
        this.values.message.vorschaubild = this.authURL + "uploads/" + this.values.message.id + "/" + this.values.message.vorschaubild;
        if (this.values.message.confirmemail === "1") {
          this.values.message.status = "Aktivieren";
        } else if (this.values.message.confirmemail === "2") {
          this.values.message.status = "Deaktivieren";
        } else {
          this.values.message.status = "E-Mail muss erst bestätigt werden";
        }
        this.showprofile = true;
      }else{
        this.router.navigate(['']);
        this.showprofile = false;
      }

    }, error => {
      //console.log(error);
    });
  }


  getpdf(id: any) {
    let params = { 'id': id }
    return this.http.get(this.authURL + "getpdf.php", { params: params }).subscribe(data => {
      this.values = data as databack;
      this.values.message.speisekarte = this.authURL + "uploads/" + id + "/" + this.values.message.speisekarte;

      if (!this.values?.success) {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Leider ist ein Fehler aufgetreten',
          showConfirmButton: false,
          timer: 1500
        })
      }
    });
  }

  getUsers(lat: string, lng: string, filter: string[]) {
    let filterstring = filter.toString();
    let params = { 'lat': lat, 'lng': lng, 'filter': filterstring }

    //console.log(params);
    return this.http.get(this.authURL + "getUsers.php", { params: params }).subscribe(data => {
      this.valuesuser = data as databackuser;
      //console.log(data);
      //console.log(this.valuesuser.message);
      //console.log(this.valuesuser.message.length);
      if (this.valuesuser.success) {
        //this.convertOeffnungszeiten(this.valuesuser.message);
        for (let i = 0; i < this.valuesuser.message.length; i++) {
        this.convertOeffnungszeitenWithoutArray(this.valuesuser.message[i]);
        //console.log(this.zeitenausgabe);
        this.valuesuser.message[i].zeitausgabe =this.zeitenausgabe;
        this.zeitenausgabe = [];
        }
      }
      //console.log(this.valuesuser.message);
      if (!this.valuesuser?.success) {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Leider ist ein Fehler aufgetreten',
          showConfirmButton: false,
          timer: 1500
        })
      }
    });
  }



  

  convertOeffnungszeitenWithoutArray(message : any){
    
      if (message.oeffnungszeiten != null && message.oeffnungszeiten != undefined) {
        this.stringtotime(message.oeffnungszeiten);
        let x = 0;
        let d = 0;
        let z = 0;
        let y = 0;
        let found = -1;


        for (let s = 0; s < (this.splitted.length - 1); s++) {
          if (this.splitted[s] != undefined && s < (this.splitted.length - 1)) {
            this.zeitenausgabezwischen = this.zeitenausgabezwischen + this.splitted[s]; 
          }
          if ((s + 1) % 4 == 0) {
            
            if (x > 0){
              z = y;
              do{
                z --;
                if (this.zeitenausgabezwischen == this.zeitenausgabe[z].time && this.zeitenausgabezwischen != ""){
                  found = z;
                }
              }while(z > 0)
              if (found != -1){
                this.zeitenausgabe.push({ wtag : "", id : message.id ,time : this.zeitenausgabezwischen}); 
               
                this.zeitenausgabe[found].wtag = this.zeitenausgabe[found].wtag.substr(0,2) + "-" + this.wochentag[d];
              }else{
                if (this.zeitenausgabezwischen != ""){
                  this.zeitenausgabe.push({ wtag : this.wochentag[d], id : message.id ,time : this.zeitenausgabezwischen}); 
                  z = 0;
                }else{
                  this.zeitenausgabe.push({ wtag : "", id : message.id ,time : this.zeitenausgabezwischen}); 
                }
              }
              found = -1;
            }else{
              this.zeitenausgabe.push({ wtag : this.wochentag[d], id : message.id ,time : this.zeitenausgabezwischen}); 
        
            }
            x++;
              this.zeitenausgabezwischen = "";
            d ++;
            y ++;
            if (y == 7){
              y = 0;
            }
          } else {
            if (this.splitted[s + 1] != "") {
              this.zeitenausgabezwischen = this.zeitenausgabezwischen + "-";
            }
          }
        }
        //this.splitted = [];
      }
      
  }

  getAllReser() {
    let token = 'Bearer ' + localStorage.getItem('token') as string;
    const headers = { 'X-Authorization': token }
    //console.log(headers);
    return this.http.get(this.authURL + "getallreser.php", { headers }).subscribe(response => {
      //console.log(response);
      this.valuesallres = response as databackallreser;
      //console.log(this.valuesallres);
      this.fillcalender(this.valuesallres);
      if (this.valuesallres.rescount != 0){
        this.betrag = Number(this.valuesallres.rescount) * 0.40;
        this.betrag = Math.round(this.betrag * 100) / 100;
      }
      //console.log(this.betrag);

    }, error => {
      //console.log(error);
    });
  }

  saveStatus(model: any) {
    let token = 'Bearer ' + localStorage.getItem('token') as string;
    const headers = { 'X-Authorization': token }
    //console.log(model);
    return this.http.post(this.authURL + "savestatus.php", model, { headers }).pipe(
      map((response: any) => {
        const user: IResponseLogin = response as IResponseLogin;

        //console.log(user);
        if (user.success) {
          //console.log('erfolgreich geändert!');
          //localStorage.setItem('token', user.token);
        }
      })
    )
  }
  saveDonation(model: any) {
    let token = 'Bearer ' + localStorage.getItem('token') as string;
    const headers = { 'X-Authorization': token }
    //console.log(model);
    return this.http.post(this.authURL + "savedonation.php", model, { headers }).pipe(
      map((response: any) => {
        const user: IResponseLogin = response as IResponseLogin;

        //console.log(user);
        if (user.success) {
          //console.log('erfolgreich gespendet!');
          //localStorage.setItem('token', user.token);
        }
      })
    )
  }

  resbearbeit(value: number, resid: number) {
    let token = 'Bearer ' + localStorage.getItem('token') as string;
    const headers = { 'X-Authorization': token }
    let model = { 'value': value, 'resid': resid }
    //console.log(model);
    return this.http.post(this.authURL + "resbearbeiten.php", model, { headers }).pipe(
      map((response: any) => {
        //console.log(response);
        const resstatusw: IResponseLogin = response as IResponseLogin;

        //console.log(resstatusw);
        if (resstatusw.success) {
          //console.log('erfolgreich geändert!');
          //localStorage.setItem('token', user.token);
        }
      })
    )
  }



  saveTime(model: any) {
    let token = 'Bearer ' + localStorage.getItem('token') as string;
    const headers = { 'X-Authorization': token }
    //console.log(headers);
    return this.http.post(this.authURL + "savetime.php", model, { headers }).pipe(
      map((response: any) => {
        const user: IResponseLogin = response as IResponseLogin;

        //console.log(user);
        if (user.success) {
          //console.log('erfolgreich geändert!');
          //localStorage.setItem('token', user.token);
        }
      })
    )
  }
  stringtotime(timestring: any) {
    this.splitted = timestring.split(",");
  }

  saveData(model: any) {
    let token = 'Bearer ' + localStorage.getItem('token') as string;
    const headers = { 'X-Authorization': token }
    //console.log(headers);
    return this.http.post(this.authURL + "saveinfo.php", model, { headers }).pipe(
      map((response: any) => {
        const user: IResponseLogin = response as IResponseLogin;

        //console.log(user);
        if (user.success) {
          //console.log('erfolgreich geändert!');
          //localStorage.setItem('token', user.token);
        }

      })
    )
  }
  getreserdata(id: any) {
    let params = { 'id': id }
    return this.http.get(this.authURL + "getreserdata.php", { params: params }).subscribe(data => {
      this.values = data as databack;
      this.values.message.vorschaubild = this.authURL + "uploads/" + id + "/" + this.values.message.vorschaubild;
      //console.log(this.values.message);
      this.convertOeffnungszeitenWithoutArray(this.values.message);
      //console.log(this.zeitenausgabe);
      console.log(this.values.message.confirmemail);
      if (this.values.message.confirmemail != "2" || !this.values?.success){
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Die Reservierung für diesen Wirt ist momentan deaktiviert.',
          showConfirmButton: false,
          timer: 3000
        })
        this.router.navigate(['/']);
      }
/*       if (!this.values?.success) {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Leider ist ein Fehler aufgetreten',
          showConfirmButton: false,
          timer: 1500
        })
        this.router.navigate(['/']);
      } */
    });
  }


  getreseriddata(id: any, telefon: any) {
    let params = { 'resid': id, 'telefon': telefon }
    return this.http.get(this.authURL + "getreseriddata.php", { params: params }).subscribe(data => {
      this.valuesres = data as databackres;
      //console.log(this.valuesres.message);
      
      if (this.valuesres.status == 201){
        this.foundres = 1;
        if (this.valuesres.message.status != 2) {
          this.buttonstorno = this.valuesres.message.status;
        } else {
          this.buttonstorno = undefined;
        }
      }else{
        this.foundres = undefined;
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: this.valuesres.message,
          showConfirmButton: false,
          timer: 1500
        })
      }
      //console.log(this.buttonstorno);
      if (!this.valuesres.success) {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Leider ist ein Fehler aufgetreten',
          showConfirmButton: false,
          timer: 1500
        })
      }
    });
  }

  stornores(id: any, telefon: any, datumres: any, resid: any) {
    let params = { 'resid': resid, 'telefon': telefon, 'datumres': datumres, 'id': id }
    return this.http.get(this.authURL + "stornores.php", { params: params }).subscribe(data => {
      this.valuesstorno = data as databackres;
      //console.log(this.valuesstorno.message);
      this.valuesres.message.status = 2;
      if (!this.valuesstorno.success) {

        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Leider ist ein Fehler aufgetreten',
          showConfirmButton: false,
          timer: 1500
        })
      }
    });
  }




  imageupload(upload: NgForm, filedata: any) {
    var myFormData = new FormData();
    let token = 'Bearer ' + localStorage.getItem('token') as string;
    const headers = { 'X-Authorization': token }
    myFormData.append('image', filedata);
    //console.log(filedata.name); //TODO Dateitypen einschränken
    /* Image Post Request */
    this.http.post(this.authURL + 'uploadimage.php', myFormData, {
      headers: headers
    }).subscribe(data => {
      //Check success message
      this.uploadret = data;
      this.uploadret = data;
      this.getValues();
      //console.log(this.uploadret);
      if (this.uploadret.success) {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Upload war erfolgreich!',
          showConfirmButton: false,
          timer: 1500
        })
      } else {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: this.uploadret,
          showConfirmButton: false,
          timer: 1500
        })
      }
    });
  }


  speiseupload(upload: NgForm, filedata: any) {
    var myFormData = new FormData();
    let token = 'Bearer ' + localStorage.getItem('token') as string;
    const headers = { 'X-Authorization': token }
    myFormData.append('image', filedata);
    //console.log(filedata.name); //TODO Dateitypen einschränken
    /* Image Post Request */
    this.http.post(this.authURL + 'uploadspeise.php', myFormData, {
      headers: headers
    }).subscribe(data => {
      //Check success message
      this.uploadret = data;
      this.getValues();
      if (this.uploadret.success) {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Upload war erfolgreich!',
          showConfirmButton: false,
          timer: 1500
        })
      } else {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Leider ist ein Fehler aufgetreten',
          showConfirmButton: false,
          timer: 1500
        })
      }
    });
  }



  saveres(model: any) {
    
    return this.http.post(this.authURL + "saveres.php", model).pipe(
      map((response: any) => {
        //console.log(response); 
        const user: IResponseLogin = response as IResponseLogin;
        this.regid = user.token;
        
        if (user.success) {
          //console.log('erfolgreich Reserviert!');
          //localStorage.setItem('token', user.token);
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Reservierung war erfolgreich! Deine Reservierungs ID lautet: ' + user.message + ', sobald das Restaurant die Reservierung bestätigt, wirst du per SMS benachrichtigt.',
            confirmButtonText:
              '<i class="fa fa-thumbs-up"></i> Verstanden',
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              window.location.reload();
              /*  if (localStorage.getItem("resid")  !== null){
                 localStorage.setItem("resid", user.message);
               }else{
                 const saveragid = localStorage.getItem("resid") + ";" + user.message;
                 localStorage.setItem("resid", saveragid);
               } */

            }
          })
          this.reserviert = false;
          //window.location.reload(); 
        }
        else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: user.message,
            showConfirmButton: false,
            timer: 4000
          })

        }
      })
    )
  }


  fillcalender(info : databackallreser){
    //console.log(info.message);
    let message = info.message ;
    for (let i = 0; i < message.length; i++) {

      //console.log(message[i]);
       this.dataArray.push({
      id: Number(message[i].resid),
  title: 'Reservierung',
  startDate: new Date(message[i].datum + "T" + message[i].zeit + ":00"),
  endDate: new Date(message[i].datum + "T" + message[i].zeit + ":00"),
  createdBy: message[i].name,
  createdAt: new Date(message[i].date),
  type: 2,
  color: "blue"});
    }
    //console.log(this.dataArray);
  }


  getnewbest() {
    return this.http.get(this.authURL + "getnewbest.php").subscribe(data => {
      this.valuesuser = data as databackuser;
      //console.log(data);
      //console.log(this.valuesuser.message);
      //console.log(this.valuesuser.message.length);
      if (this.valuesuser.success) {
        
      }
      //console.log(this.valuesuser.message);
      if (!this.valuesuser?.success) {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Leider ist ein Fehler aufgetreten',
          showConfirmButton: false,
          timer: 1500
        })
      }
    });
  }
  //TODO Mail darf nie leer sein!!!!
}

export interface IResponseLogin {
  success: boolean,
  message: string,
  token: string
}
export interface I2 {
  user_id: string;
  user_name: string;
}
export interface I1 {
  data: I2;
}

export interface databack {
  success: boolean;
  status: string;
  message: datainfo;
}
export interface datainfo {
  email: string;
  id: string;
  name: string;
  since: string;
  kategorie: string;
  kategorie1: string;
  preis: number;
  standort: string;
  lat: string;
  lng: string;
  telefon: string;
  oeffnungszeiten: string;
  beschreib: string;
  vorschaubild: string;
  speisekarte: string;
  confirmemail: string;
  status: string;
  zahlung: string;
  rescount: string;
  letztespende: string;
}
export interface returnupload {
  success: boolean;
  status: string;
  message: any;
}


export interface databackuser {
  success: boolean;
  status: string;
  message: [{ id: string, name: string, standort: string, distance: string, vorschaubild: string, oeffnungszeiten: string, zahlung: string}];
  count: number;
}

export interface databackallreser {
  success: boolean;
  status: string;
  rescount: number;
  letztespende: string;
  message: [{
    date: any,
    zeit: any,
    resid: any,
    datum: any,
    name: any,
    id: any,
    kinder: any,
    beeintraechtigung: any,
    personen: any,
    bemerkung: any,
    telefon: any,
    status: any
  }];
}

export interface databackres {
  success: boolean;
  status: string;
  message: datainfores;
}
export interface zeitentag {
  val: number; day: string; time: string;
}

export interface datainfores {
  zeit: any;
  resid: any;
  datum: any;
  name: any;
  namewirt: any;
  kinder: any;
  beeintraechtigung: any;
  personen: any;
  bemerkung: any;
  standort: any;
  telefonwirt: any;
  status: any;
}

export interface MoSo {
  wtag: string,
  id: string,
  time: string,
}

export const testData: EventDataE[] = [
 
];
export interface Day {
  day: number;
  month: number;
  year: number;
  events: any[];
}
export interface EventDataE {
  id: number;
  title: string;
  desc?: string;
  startDate: Date;
  endDate: Date;
  createdBy?: string;
  createdAt?: Date;
  type?: number;
  color?: string;
}