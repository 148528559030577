import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProgressBarService } from 'src/app/shared/services/progress-bar.service';
import { NgxSpinnerService } from "ngx-spinner";
import { AuthService } from 'src/app/shared/services/auth.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';



@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.css']
})
export class OverviewComponent implements OnInit {
  public address: string = "";
  private geoCoder = new google.maps.Geocoder();
  addresspar: string= "";
  isShown: boolean = false ;
  public isCollapsed = true;
  constructor(public activerouter: ActivatedRoute, public progressBar: ProgressBarService, private spinner: NgxSpinnerService, public authService: AuthService) { }
  kategories: Kategorie[] = [
    
    { value: '1', viewValue: 'Österreichische Küche' },
    { value: '2', viewValue: 'Italienisch' },
    { value: '3', viewValue: 'Bar' },
    { value: '4', viewValue: 'Pizza' },
    { value: '5', viewValue: 'Fast Food' },
    { value: '6', viewValue: 'Pasta' },
    { value: '7', viewValue: 'Asiatisch' },
    { value: '8', viewValue: 'Cafe' },
    { value: '9', viewValue: 'Burger' },
    { value: '10', viewValue: 'Mexikanisch' },

  ];

  

  zahlungs: Zahlung[] = [
    { value: '0', viewValue: 'Bar' },
    { value: '1', viewValue: 'Bar u. Karte' },
    { value: '2', viewValue: 'Bar u. Karte u. Bitcoin' }
  ];

  filter: string[] = [];
  act(){
    return true;
  }
  ngOnInit(): void {

   
   //console.log(this.activerouter.snapshot.params.params);
    if (this.authService.valuesuser){
      this.authService.valuesuser.success = false;
    }
    this.addresspar = this.activerouter.snapshot.params.params.split(";"); 
    //this.spinner.show();
 
    //setTimeout(() => {
      /** spinner ends after 5 seconds */
    //  this.spinner.hide();
    //}, 2000);

    if(this.addresspar[2]== undefined){
      this.progressBar.startLoading();
      this.getAddress(Number(this.addresspar[0]), Number(this.addresspar[1]) );
      this.progressBar.setSuccess();
      this.progressBar.completeLoading();
    } else {
      this.address = this.addresspar[2];
    }
    this.authService.getUsers(this.addresspar[0], this.addresspar[1], this.filter);
    
    
  }




  getAddress(latitude:number, longitude:number) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results:any, status:any) => {
      if (status === 'OK') {
        if (results[0]) {
          this.address = results[0].formatted_address;
      
         //console.log(this.address);
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }
    
    });
  }

  onCheckboxChange(e : any){ 
   console.log(e);
   
    if (e.target.checked){
      this.filter.push(e.target.defaultValue);
    }else{
      for (let i = 0; i < this.filter.length; i++) {
        if (this.filter[i] == e.target.defaultValue){
          this.filter.splice(i, 1);
         //console.log(this.filter);
        }
      }
    }
    this.authService.getUsers(this.addresspar[0], this.addresspar[1], this.filter);
   //console.log(this.filter);

  }


  




}
interface Kategorie {
  value: string;
  viewValue: string;
}
interface Zahlung {
  value: string;
  viewValue: string;
}