import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ProgressBarService } from 'src/app/shared/services/progress-bar.service';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(private authService : AuthService, public progressBar: ProgressBarService, private router: Router) { }

  ngOnInit(): void {
  }
  onSubmit(f: NgForm) {
    this.progressBar.startLoading();
      const loginObserver = {
        
      next: () => {
        //console.log(this.authService.values);
        //console.log('Service Ok');
        this.progressBar.setSuccess();
        this.progressBar.completeLoading();
        
    },
      error: (err: any) => {
        //console.log(loginObserver);
        this.progressBar.completeLoading();
      }
    };
    this.authService.login(f.value).subscribe(loginObserver);
  }
}


