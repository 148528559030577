import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GooglePlaceModule } from "ngx-google-places-autocomplete"; 

import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { ConfirmEmailComponent } from './components/confirm-email/confirm-email.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { UserchangePasswordComponent } from './components/userchange-password/userchange-password.component';




@NgModule({
  declarations: [LoginComponent, RegisterComponent, ResetPasswordComponent, ConfirmEmailComponent, ChangePasswordComponent, UserchangePasswordComponent],
  imports: [
    CommonModule,
    AuthRoutingModule,
    FormsModule,
    GooglePlaceModule,
    ReactiveFormsModule,
  ],
  exports: [
    LoginComponent, 
    RegisterComponent, 
    ResetPasswordComponent,
    ChangePasswordComponent,
    UserchangePasswordComponent
  ]
})
export class AuthModule { }
