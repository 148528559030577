<div style="margin-top: 10rem;" class="row">
    <div class="col-md-6 offset-md-3 mt-4">
        <h3>Passwort Ändern</h3>
        <form #f="ngForm" (ngSubmit)="onSubmit(f)">
            <div class="form-group">
                <label for="uchangepwPassword">Altes Passwort</label>
                <input type="password" name="passwordold" ngModel required #password="ngModel" class="form-control" id="oldPassword" placeholder="Password">
            </div>
            <div class="form-group">
                <label for="uchangepwPassword">Passwort</label>
                <input type="password" name="passwordnew" ngModel required #password="ngModel" class="form-control" id="newPassword" [(ngModel)]="new" placeholder="Password">
            </div>
            <div class="form-group">
                <label for="uchangepwPassword">Bestätigen</label>
                <input type="password" name="password" ngModel required #password="ngModel" class="form-control" (change)="onChangeEvent($event)" [(ngModel)]="new1" id="new1Password" placeholder="Password">
                <p *ngIf="this.ungleich" style="color: tomato;">Passwort ist nicht gleich!</p>
            </div>
            <button type="submit" [disabled]="f.invalid" class="btn btn-primary">Ändern<i class="fas fa-sign-in-alt"></i></button>
        </form>
    </div>
</div>