<div class="row" style="margin-top: 5rem; margin-bottom: 10rem;">
    <div class="col-md-6 offset-md-3 mt-4">
        <h3>Passwort vergessen?</h3>
        <form #f="ngForm" (ngSubmit)="onSubmit(f)">

            <div class="form-group">
                <label for="resetPasswordEmail">Email</label>
                <input type="email" name="email" ngModel required #email="ngModel" class="form-control" id="resetPasswordEmail" placeholder="E-Mail">
            </div>

            <button type="submit" [disabled]="f.invalid" class="btn btn-primary">Passwort vergessen? <i class="fas fa-paper-plane"></i></button>
        </form>
    </div>
</div>