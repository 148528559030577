<div class="container" style="margin-top: 10rem;">
    <h1 class="adsimple-111729218" style="color:black">Datenschutzerklärung</h1>
    <h2 style="color: black;">Speicherung und weitrergabe Ihrer persönlichen Daten</h2>
    <p>Die Daten welche bei einer Reservierung angegeben werden:
        <ul>
            <li>Name</li>
            <li>E-Mail</li>
            <li>Telefonnummer</li>
        </ul>
        werden gespeichert und an das Unternehmen der bei dem Sie reserviert haben weitergeleitet. Die Telefonnummer wird verwendet um SMS zur Benachrichtigung zu versenden. Falls dies nicht erfolgt, werden Sie mittels E-Mail Adresse benachrichtigt.

        <h2>Speicherung der Daten mit Login</h2>
        <p> Welche Daten werden erhoben:
            <ul>
                <li>E-Mail</li>
                <li>Telefonnummer</li>
                <li>Adresse</li>
            </ul>
            Diese Daten werden verwendet um eine sicheren Login zu gewährleisten. Die angegeben Daten und weitere, werden den Besuchern öffentlich auf einer Übersicht dargestellt. Ebenso wie die übermittelten Bilder.



            <h2 id="datenschutz" class="adsimple-111729218">Datenschutz</h2>
            <p>Wir haben diese Datenschutzerklärung (Fassung 29.04.2021-111729218) verfasst, um Ihnen gemäß der Vorgaben der <a class="adsimple-111729218" href="https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679&tid=111729218" target="_blank"
                    rel="noopener">Datenschutz-Grundverordnung (EU) 2016/679</a> und anwendbaren nationalen Gesetzen zu erklären, welche personenbezogenen Daten wir verarbeiten, zukünftig verarbeiten werden und welche rechtmäßigen Entscheidungsmöglichkeiten
                Sie als Besucher dieser Webseite haben.</p>
            <p>Datenschutzerklärungen klingen für gewöhnlich sehr technisch. Diese Version soll Ihnen hingegen die wichtigsten Dinge so einfach und klar wie möglich beschreiben. Soweit es möglich ist, werden technische Begriffe leserfreundlich erklärt. Außerdem
                möchten wir vermitteln, dass wir mit dieser Website nur dann Informationen sammeln und verwenden, wenn eine entsprechende gesetzliche Grundlage gegeben ist. Das ist sicher nicht möglich, wenn man möglichst knappe, technische Erklärungen
                abgibt, so wie sie im Internet oft Standard sind, wenn es um Datenschutz geht. Ich hoffe Sie finden die folgenden Erläuterungen interessant und informativ und vielleicht ist die eine oder andere Information dabei, die Sie noch nicht kannten.<br
                /> Wenn trotzdem Fragen bleiben, möchten wir Sie bitten den vorhandenen Links zu folgen und sich weitere Informationen auf Drittseiten anzusehen, oder uns einfach eine E-Mail zu schreiben. Unsere Kontaktdaten finden Sie im Impressum.</p>








            <h2 id="automatische-datenspeicherung" class="adsimple-111729218">Automatische Datenspeicherung</h2>
            <p>Wenn Sie heutzutage Websites besuchen, werden gewisse Informationen automatisch erstellt und gespeichert, so auch auf dieser Website. Diese gesammelten Daten sollten möglichst sparsam und nur mit Begründung gesammelt werden werden. Mit Website
                meinen wir übrigens die Gesamtheit aller Webseiten auf Ihrer Domain, d.h. alles von der Startseite (Homepage) bis hin zur aller letzten Unterseite (wie dieser hier). Mit Domain meinen wir zum Beispiel beispiel.de oder musterbeispiel.com.</p>
            <p>Auch während Sie unsere Website jetzt gerade besuchen, speichert unser Webserver – das ist der Computer auf dem diese Webseite gespeichert ist –  aus Gründen der Betriebssicherheit, zur Erstellung von Zugriffsstatistik usw. in der Regel automatisch
                Daten wie</p>
            <ul class="adsimple-111729218">
                <li class="adsimple-111729218">die komplette Internetadresse (URL) der aufgerufenen Webseite (z. B. https://www.beispielwebsite.de/beispielunterseite.html/)</li>
                <li class="adsimple-111729218">Browser und Browserversion (z. B. Chrome 87)</li>
                <li class="adsimple-111729218">das verwendete Betriebssystem (z. B. Windows 10)</li>
                <li class="adsimple-111729218">die Adresse (URL) der zuvor besuchten Seite (Referrer URL) (z. B. https://www.beispielquellsite.de/vondabinichgekommen.html/)</li>
                <li class="adsimple-111729218">den Hostname und die IP-Adresse des Geräts von welchem aus zugegriffen wird (z. B. COMPUTERNAME und 194.23.43.121)</li>
                <li class="adsimple-111729218">Datum und Uhrzeit</li>
                <li class="adsimple-111729218">in Dateien, den sogenannten Webserver-Logfiles.</li>
            </ul>
            <p>Zur Veranschaulichung:</p>
            <p>
                <img role="img" src="https://www.adsimple.at/wp-content/uploads/2018/03/browser-und-webserver.svg" alt="Browser und Webserver" width="100%" />
            </p>
            <p>In der Regel werden diese Dateien zwei Wochen gespeichert und danach automatisch gelöscht. Wir geben diese Daten nicht weiter, können jedoch nicht ausschließen, dass diese Daten beim Vorliegen von rechtswidrigem Verhalten von Behörden eingesehen
                werden.
            </p>
            <p>Kurz gesagt: Ihr Besuch wird durch unseren Provider (Firma, die unsere Website auf speziellen Computern (Servern) laufen lässt), protokolliert, aber wir geben Ihre Daten nicht weiter!</p>
            <h2 id="cookies" class="adsimple-111729218">Cookies</h2>
            <p>Unsere Webseite verwendet HTTP-Cookies, um nutzerspezifische Daten zu speichern.<br /> Im Folgenden erklären wir, was Cookies sind und warum Sie genutzt werden, damit Sie die folgende Datenschutzerklärung besser verstehen.</p>
            <h3 class="adsimple-111729218">Was genau sind Cookies?</h3>
            <p>Immer wenn Sie durch das Internet surfen, verwenden Sie einen Browser. Bekannte Browser sind beispielsweise Chrome, Safari, Firefox, Internet Explorer und Microsoft Edge. Die meisten Webseiten speichern kleine Text-Dateien in Ihrem Browser.
                Diese Dateien nennt man Cookies.</p>
            <p>Eines ist nicht von der Hand zu weisen: Cookies sind echt nützliche Helferlein. Fast alle Webseiten verwenden Cookies. Genauer gesprochen sind es HTTP-Cookies, da es auch noch andere Cookies für andere Anwendungsbereiche gibt. HTTP-Cookies sind
                kleine Dateien, die von unserer Webseite auf Ihrem Computer gespeichert werden. Diese Cookie-Dateien werden automatisch im Cookie-Ordner, quasi dem “Hirn” Ihres Browsers, untergebracht. Ein Cookie besteht aus einem Namen und einem Wert.
                Bei der Definition eines Cookies müssen zusätzlich ein oder mehrere Attribute angegeben werden.</p>
            <p>Cookies speichern gewisse Nutzerdaten von Ihnen, wie beispielsweise Sprache oder persönliche Seiteneinstellungen. Wenn Sie unsere Seite wieder aufrufen, übermittelt Ihr Browser die „userbezogenen“ Informationen an unsere Seite zurück. Dank
                der Cookies weiß unsere Webseite, wer Sie sind und bietet Ihnen die Einstellung, die Sie gewohnt sind. In einigen Browsern hat jedes Cookie eine eigene Datei, in anderen wie beispielsweise Firefox sind alle Cookies in einer einzigen Datei
                gespeichert.
            </p>
            <p>Die folgende Grafik zeigt eine mögliche Interaktion zwischen einem Webbrowser wie z. B. Chrome und dem Webserver. Dabei fordert der Webbrowser eine Website an und erhält vom Server ein Cookie zurück, welches der Browser erneut verwendet, sobald
                eine andere Seite angefordert wird.</p>
            <p>
                <img role="img" src="https://www.adsimple.at/wp-content/uploads/2018/03/http-cookie-interaction.svg" alt="HTTP Cookie Interaktion zwischen Browser und Webserver" width="100%" />
            </p>
            <p>Es gibt sowohl Erstanbieter Cookies als auch Drittanbieter-Cookies. Erstanbieter-Cookies werden direkt von unserer Seite erstellt, Drittanbieter-Cookies werden von Partner-Webseiten (z.B. Google Analytics) erstellt. Jedes Cookie ist individuell
                zu bewerten, da jedes Cookie andere Daten speichert. Auch die Ablaufzeit eines Cookies variiert von ein paar Minuten bis hin zu ein paar Jahren. Cookies sind keine Software-Programme und enthalten keine Viren, Trojaner oder andere „Schädlinge“.
                Cookies können auch nicht auf Informationen Ihres PCs zugreifen.</p>
            <p>So können zum Beispiel Cookie-Daten aussehen:</p>
            <p>
                <strong class="adsimple-111729218">Name:</strong> _ga<br />
                <strong class="adsimple-111729218">Wert:</strong> GA1.2.1326744211.152111729218-9<br />
                <strong class="adsimple-111729218">Verwendungszweck:</strong> Unterscheidung der Webseitenbesucher<br />
                <strong class="adsimple-111729218">Ablaufdatum:</strong> nach 2 Jahren</p>
            <p>Diese Mindestgrößen sollte ein Browser unterstützen können:</p>
            <ul class="adsimple-111729218">
                <li class="adsimple-111729218">Mindestens 4096 Bytes pro Cookie</li>
                <li class="adsimple-111729218">Mindestens 50 Cookies pro Domain</li>
                <li class="adsimple-111729218">Mindestens 3000 Cookies insgesamt</li>
            </ul>
            <h3 class="adsimple-111729218">Welche Arten von Cookies gibt es?</h3>
            <p>Die Frage welche Cookies wir im Speziellen verwenden, hängt von den verwendeten Diensten ab und wird in den folgenden Abschnitten der Datenschutzerklärung geklärt. An dieser Stelle möchten wir kurz auf die verschiedenen Arten von HTTP-Cookies
                eingehen.
            </p>
            <p>Man kann 4 Arten von Cookies unterscheiden:</p>
            <p>
                <strong class="adsimple-111729218">Unerlässliche Cookies<br />
</strong>Diese Cookies sind nötig, um grundlegende Funktionen der Webseite sicherzustellen. Zum Beispiel braucht es diese Cookies, wenn ein User ein Produkt in den Warenkorb legt, dann auf anderen Seiten weitersurft und später erst zur
                Kasse geht. Durch diese Cookies wird der Warenkorb nicht gelöscht, selbst wenn der User sein Browserfenster schließt.</p>
            <p>
                <strong class="adsimple-111729218">Zweckmäßige Cookies<br />
</strong>Diese Cookies sammeln Infos über das Userverhalten und ob der User etwaige Fehlermeldungen bekommt. Zudem werden mithilfe dieser Cookies auch die Ladezeit und das Verhalten der Webseite bei verschiedenen Browsern gemessen.</p>
            <p>
                <strong class="adsimple-111729218">Zielorientierte Cookies<br />
</strong>Diese Cookies sorgen für eine bessere Nutzerfreundlichkeit. Beispielsweise werden eingegebene Standorte, Schriftgrößen oder Formulardaten gespeichert.</p>
            <p>
                <strong class="adsimple-111729218">Werbe-Cookies<br />
</strong>Diese Cookies werden auch Targeting-Cookies genannt. Sie dienen dazu dem User individuell angepasste Werbung zu liefern. Das kann sehr praktisch, aber auch sehr nervig sein.</p>
            <p>Üblicherweise werden Sie beim erstmaligen Besuch einer Webseite gefragt, welche dieser Cookiearten Sie zulassen möchten. Und natürlich wird diese Entscheidung auch in einem Cookie gespeichert.</p>
            <h3 class="adsimple-111729218">Wie kann ich Cookies löschen?</h3>
            <p>Wie und ob Sie Cookies verwenden wollen, entscheiden Sie selbst. Unabhängig von welchem Service oder welcher Webseite die Cookies stammen, haben Sie immer die Möglichkeit Cookies zu löschen, zu deaktivieren oder nur teilweise zuzulassen. Zum
                Beispiel können Sie Cookies von Drittanbietern blockieren, aber alle anderen Cookies zulassen.</p>
            <p>Wenn Sie feststellen möchten, welche Cookies in Ihrem Browser gespeichert wurden, wenn Sie Cookie-Einstellungen ändern oder löschen wollen, können Sie dies in Ihren Browser-Einstellungen finden:</p>
            <p>
                <a class="adsimple-111729218" href="https://support.google.com/chrome/answer/95647?tid=111729218" target="_blank" rel="noopener noreferrer">Chrome: Cookies in Chrome löschen, aktivieren und verwalten</a>
            </p>
            <p>
                <a class="adsimple-111729218" href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=111729218" target="_blank" rel="noopener noreferrer">Safari: Verwalten von Cookies und Websitedaten mit Safari</a>
            </p>
            <p>
                <a class="adsimple-111729218" href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=111729218" target="_blank" rel="noopener noreferrer">Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf Ihrem Computer abgelegt haben</a>
            </p>
            <p>
                <a class="adsimple-111729218" href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=111729218" target="_blank" rel="noopener noreferrer">Internet Explorer: Löschen und Verwalten von Cookies</a>
            </p>
            <p>
                <a class="adsimple-111729218" href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=111729218" target="_blank" rel="noopener noreferrer">Microsoft Edge: Löschen und Verwalten von Cookies</a>
            </p>
            <p>Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren Browser so einrichten, dass er Sie immer informiert, wenn ein Cookie gesetzt werden soll. So können Sie bei jedem einzelnen Cookie entscheiden, ob Sie das Cookie erlauben
                oder nicht. Die Vorgangsweise ist je nach Browser verschieden. Am besten Sie suchen die Anleitung in Google mit dem Suchbegriff “Cookies löschen Chrome” oder “Cookies deaktivieren Chrome” im Falle eines Chrome Browsers.</p>
            <h3 class="adsimple-111729218">Wie sieht es mit meinem Datenschutz aus?</h3>
            <p>Seit 2009 gibt es die sogenannten „Cookie-Richtlinien“. Darin ist festgehalten, dass das Speichern von Cookies eine Einwilligung von Ihnen verlangt. Innerhalb der EU-Länder gibt es allerdings noch sehr unterschiedliche Reaktionen auf diese
                Richtlinien. In Österreich erfolgte aber die Umsetzung dieser Richtlinie in § 96 Abs. 3 des Telekommunikationsgesetzes (TKG). In Deutschland wurden die Cookie-Richtlinien nicht als nationales Recht umgesetzt. Stattdessen erfolgte die Umsetzung
                dieser Richtlinie weitgehend in § 15 Abs.3 des Telemediengesetzes (TMG).</p>
            <p>Wenn Sie mehr über Cookies wissen möchten und technische Dokumentationen nicht scheuen, empfehlen wir <a class="adsimple-111729218" href="https://tools.ietf.org/html/rfc6265" target="_blank" rel="nofollow noopener noreferrer">https://tools.ietf.org/html/rfc6265</a>,
                dem Request for Comments der Internet Engineering Task Force (IETF) namens “HTTP State Management Mechanism”.</p>
            <h2 id="speicherung-persoenlicher-daten" class="adsimple-111729218">Speicherung persönlicher Daten</h2>
            <p>Persönliche Daten, die Sie uns auf dieser Website elektronisch übermitteln, wie zum Beispiel Name, E-Mail-Adresse, Adresse oder andere persönlichen Angaben im Rahmen der Übermittlung eines Formulars oder Kommentaren im Blog, werden von uns
                gemeinsam mit dem Zeitpunkt und der IP-Adresse nur zum jeweils angegebenen Zweck verwendet, sicher verwahrt und nicht an Dritte weitergegeben.</p>
            <p>Wir nutzen Ihre persönlichen Daten somit nur für die Kommunikation mit jenen Besuchern, die Kontakt ausdrücklich wünschen und für die Abwicklung der auf dieser Webseite angebotenen Dienstleistungen und Produkte. Wir geben Ihre persönlichen
                Daten ohne Zustimmung nicht weiter, können jedoch nicht ausschließen, dass diese Daten beim Vorliegen von rechtswidrigem Verhalten eingesehen werden.</p>
            <p>Wenn Sie uns persönliche Daten per E-Mail schicken – somit abseits dieser Webseite – können wir keine sichere Übertragung und den Schutz Ihrer Daten garantieren. Wir empfehlen Ihnen, vertrauliche Daten niemals unverschlüsselt per E-Mail zu
                übermitteln.
            </p>
            <h2 id="rechte-dsgvo" class="adsimple-111729218">Rechte laut Datenschutzgrundverordnung</h2>
            <p>Ihnen stehen laut den Bestimmungen der DSGVO grundsätzlich die folgende Rechte zu:</p>
            <ul class="adsimple-111729218">
                <li class="adsimple-111729218">Recht auf Berichtigung (Artikel 16 DSGVO)</li>
                <li class="adsimple-111729218">Recht auf Löschung („Recht auf Vergessenwerden“) (Artikel 17 DSGVO)</li>
                <li class="adsimple-111729218">Recht auf Einschränkung der Verarbeitung (Artikel 18 DSGVO)</li>
                <li class="adsimple-111729218">Recht auf Benachrichtigung – Mitteilungspflicht im Zusammenhang mit der Berichtigung oder Löschung personenbezogener Daten oder der Einschränkung der Verarbeitung (Artikel 19 DSGVO)</li>
                <li class="adsimple-111729218">Recht auf Datenübertragbarkeit (Artikel 20 DSGVO)</li>
                <li class="adsimple-111729218">Widerspruchsrecht (Artikel 21 DSGVO)</li>
                <li class="adsimple-111729218">Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung — einschließlich Profiling — beruhenden Entscheidung unterworfen zu werden (Artikel 22 DSGVO)</li>
            </ul>
            <p>Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche sonst in einer Weise verletzt worden sind, können Sie sich bei der Aufsichtsbehörde beschweren. Diese ist für
                Österreich die Datenschutzbehörde, deren Webseite Sie unter <a class="adsimple-111729218" href="https://www.dsb.gv.at/?tid=111729218">https://www.dsb.gv.at/</a> finden und für Deutschland können Sie sich an die <a class="adsimple-111729218"
                    href="https://www.bfdi.bund.de" target="_blank" rel="noopener">Bundesbeauftragte für den Datenschutz und die Informationsfreiheit (BfDI)</a> wenden.</p>
            <h2 id="auswertung-des-besucherverhaltens" class="adsimple-111729218">Auswertung des Besucherverhaltens</h2>
            <p>In der folgenden Datenschutzerklärung informieren wir Sie darüber, ob und wie wir Daten Ihres Besuchs dieser Website auswerten. Die Auswertung der gesammelten Daten erfolgt in der Regel anonym und wir können von Ihrem Verhalten auf dieser
                Website nicht auf Ihre Person schließen.</p>
            <p>Mehr über Möglichkeiten dieser Auswertung der Besuchsdaten zu widersprechen erfahren Sie in der folgenden Datenschutzerklärung.</p>
            <h2 id="tls-verschluesselung-https" class="adsimple-111729218">TLS-Verschlüsselung mit https</h2>
            <p>TLS, Verschlüsselung und https klingen sehr technisch und sind es auch. Wir verwenden HTTPS (das Hypertext Transfer Protocol Secure steht für „sicheres Hypertext-Übertragungsprotokoll“) um Daten abhörsicher im Internet zu übertragen.<br />                Das bedeutet, dass die komplette Übertragung aller Daten von Ihrem Browser zu unserem Webserver abgesichert ist – niemand kann “mithören”.</p>
            <p>Damit haben wir eine zusätzliche Sicherheitsschicht eingeführt und erfüllen Datenschutz durch Technikgestaltung <a class="adsimple-111729218" href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&from=DE&tid=111729218"
                    target="_blank" rel="noopener">Artikel 25 Absatz 1 DSGVO</a>). Durch den Einsatz von TLS (Transport Layer Security), einem Verschlüsselungsprotokoll zur sicheren Datenübertragung im Internet können wir den Schutz vertraulicher Daten sicherstellen.<br
                /> Sie erkennen die Benutzung dieser Absicherung der Datenübertragung am kleinen Schlosssymbol <img role="img" src="https://www.adsimple.at/wp-content/uploads/2018/03/schlosssymbol-https.svg" width="17" height="18" /> links oben im Browser
                links von der Internetadresse (z. B. beispielseite.de) und der Verwendung des Schemas https (anstatt http) als Teil unserer Internetadresse.<br /> Wenn Sie mehr zum Thema Verschlüsselung wissen möchten, empfehlen wir die Google Suche nach
                “Hypertext Transfer Protocol Secure wiki” um gute Links zu weiterführenden Informationen zu erhalten.</p>
            <h2 id="google-maps-datenschutzerklaerung" class="adsimple-111729218">Google Maps Datenschutzerklärung</h2>
            <p>Wir benützen auf unserer Website Google Maps der Firma Google Inc. Für den europäischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) für alle Google-Dienste verantwortlich. Mit Google Maps
                können wir Ihnen Standorte besser zeigen und damit unser Service an Ihre Bedürfnisse anpassen. Durch die Verwendung von Google Maps werden Daten an Google übertragen und auf den Google-Servern gespeichert. Hier wollen wir nun genauer darauf
                eingehen, was Google Maps ist, warum wir diesen Google-Dienst in Anspruch nehmen, welche Daten gespeichert werden und wie Sie dies unterbinden können.</p>
            <h3 class="adsimple-111729218">Was ist Google Maps?</h3>
            <p>Google Maps ist ein Internet-Kartendienst der Firma Google. Mit Google Maps können Sie online über einen PC, ein Tablet oder eine App genaue Standorte von Städten, Sehenswürdigkeiten, Unterkünften oder Unternehmen suchen. Wenn Unternehmen
                auf Google My Business vertreten sind, werden neben dem Standort noch weitere Informationen über die Firma angezeigt. Um die Anfahrtsmöglichkeit anzuzeigen, können Kartenausschnitte eines Standorts mittels HTML-Code in eine Website eingebunden
                werden. Google Maps zeigt die Erdoberfläche als Straßenkarte oder als Luft- bzw. Satellitenbild. Dank der Street View Bilder und den hochwertigen Satellitenbildern sind sehr genaue Darstellungen möglich.</p>
            <h3 class="adsimple-111729218">Warum verwenden wir Google Maps auf unserer Website?</h3>
            <p>All unsere Bemühungen auf dieser Seite verfolgen das Ziel, Ihnen eine nützliche und sinnvolle Zeit auf unserer Webseite zu bieten. Durch die Einbindung von Google Maps können wir Ihnen die wichtigsten Informationen zu diversen Standorten liefern.
                Sie sehen auf einen Blick wo wir unseren Firmensitz haben. Die Wegbeschreibung zeigt Ihnen immer den besten bzw. schnellsten Weg zu uns. Sie können den Anfahrtsweg für Routen mit dem Auto, mit öffentlichen Verkehrsmitteln, zu Fuß oder
                mit dem Fahrrad abrufen. Für uns ist die Bereitstellung von Google Maps Teil unseres Kundenservice.</p>
            <h3 class="adsimple-111729218">Welche Daten werden von Google Maps gespeichert?</h3>
            <p>Damit Google Maps ihren Dienst vollständig anbieten kann, muss das Unternehmen Daten von Ihnen aufnehmen und speichern. Dazu zählen unter anderem die eingegebenen Suchbegriffe, Ihre IP-Adresse und auch die Breiten- bzw. Längenkoordinaten.
                Benutzen Sie die Routenplaner-Funktion wird auch die eingegebene Startadresse gespeichert. Diese Datenspeicherung passiert allerdings auf den Webseiten von Google Maps. Wir können Sie darüber nur informieren, aber keinen Einfluss nehmen.
                Da wir Google Maps in unsere Webseite eingebunden haben, setzt Google mindestens ein Cookie (Name: NID) in Ihrem Browser. Dieses Cookie speichert Daten über Ihr Userverhalten. Google nutzt diese Daten in erster Linie, um eigene Dienste
                zu optimieren und individuelle, personalisierte Werbung für Sie bereitzustellen.</p>
            <p>Folgendes Cookie wird aufgrund der Einbindung von Google Maps in Ihrem Browser gesetzt:</p>
            <p>
                <strong class="adsimple-111729218">Name:</strong> NID<br />
                <strong class="adsimple-111729218">Wert:</strong> 188=h26c1Ktha7fCQTx8rXgLyATyITJ111729218-5<br />
                <strong class="adsimple-111729218">Verwendungszweck:</strong> NID wird von Google verwendet, um Werbeanzeigen an Ihre Google-Suche anzupassen. Mit Hilfe des Cookies „erinnert“ sich Google an Ihre am häufigsten eingegebenen Suchanfragen
                oder Ihre frühere Interaktion mit Anzeigen. So bekommen Sie immer maßgeschneiderte Werbeanzeigen. Das Cookie enthält eine einzigartige ID, die Google benutzt, um Ihre persönlichen Einstellungen für Werbezwecke zu sammeln.<br />
                <strong class="adsimple-111729218">Ablaufdatum:</strong> nach 6 Monaten</p>
            <p>
                <strong class="adsimple-111729218">Anmerkung:</strong> Wir können bei den Angaben der gespeicherten Daten keine Vollständigkeit gewährleisten. Speziell bei der Verwendung von Cookies sind Veränderungen nie auszuschließen. Um das Cookie
                NID zu identifizieren, wurde eine eigene Testseite angelegt, wo ausschließlich Google Maps eingebunden war.</p>
            <h3 class="adsimple-111729218">Wie lange und wo werden die Daten gespeichert?</h3>
            <p>Die Google-Server stehen in Rechenzentren auf der ganzen Welt. Die meisten Server befinden sich allerdings in Amerika. Aus diesem Grund werden Ihre Daten auch vermehrt in den USA gespeichert. Hier können Sie genau nachlesen wo sich die Google-Rechenzentren
                befinden: <a class="adsimple-111729218" href="https://www.google.com/about/datacenters/inside/locations/?hl=de" target="_blank" rel="noopener noreferrer">https://www.google.com/about/datacenters/inside/locations/?hl=de</a>
            </p>
            <p>Die Daten verteilt Google auf verschiedenen Datenträgern. Dadurch sind die Daten schneller abrufbar und werden vor etwaigen Manipulationsversuchen besser geschützt. Jedes Rechenzentrum hat auch spezielle Notfallprogramme. Wenn es zum Beispiel
                Probleme bei der Google-Hardware gibt oder eine Naturkatastrophe die Server lahm legt, bleiben die Daten ziemlich sicher trotzdem geschützt.</p>
            <p>Manche Daten speichert Google für einen festgelegten Zeitraum. Bei anderen Daten bietet Google lediglich die Möglichkeit, diese manuell zu löschen. Weiters anonymisiert das Unternehmen auch Informationen (wie zum Beispiel Werbedaten) in Serverprotokollen,
                indem es einen Teil der IP-Adresse und Cookie-Informationen nach 9 bzw.18 Monaten löscht.</p>
            <h3 class="adsimple-111729218">Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3>
            <p>Mit der 2019 eingeführten automatischen Löschfunktion von Standort- und Aktivitätsdaten werden Informationen zur Standortbestimmung und Web-/App-Aktivität – abhängig von Ihrer Entscheidung – entweder 3 oder 18 Monate gespeichert und dann gelöscht.
                Zudem kann man diese Daten über das Google-Konto auch jederzeit manuell aus dem Verlauf löschen. Wenn Sie Ihre Standorterfassung vollständig verhindern wollen, müssen Sie im Google-Konto die Rubrik „Web- und App-Aktivität“ pausieren. Klicken
                Sie „Daten und Personalisierung“ und dann auf die Option „Aktivitätseinstellung“. Hier können Sie die Aktivitäten ein- oder ausschalten.</p>
            <p>In Ihrem Browser können Sie weiters auch einzelne Cookies deaktivieren, löschen oder verwalten. Je nach dem welchen Browser Sie verwenden, funktioniert dies immer etwas anders. Die folgenden Anleitungen zeigen, wie Sie Cookies in Ihrem Browser
                verwalten:
            </p>
            <p>
                <a class="adsimple-111729218" href="https://support.google.com/chrome/answer/95647?tid=111729218" target="_blank" rel="noopener noreferrer">Chrome: Cookies in Chrome löschen, aktivieren und verwalten</a>
            </p>
            <p>
                <a class="adsimple-111729218" href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=111729218" target="_blank" rel="noopener noreferrer">Safari: Verwalten von Cookies und Websitedaten mit Safari</a>
            </p>
            <p>
                <a class="adsimple-111729218" href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=111729218" target="_blank" rel="noopener noreferrer">Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf Ihrem Computer abgelegt haben</a>
            </p>
            <p>
                <a class="adsimple-111729218" href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=111729218" target="_blank" rel="noopener noreferrer">Internet Explorer: Löschen und Verwalten von Cookies</a>
            </p>
            <p>
                <a class="adsimple-111729218" href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=111729218" target="_blank" rel="noopener noreferrer">Microsoft Edge: Löschen und Verwalten von Cookies</a>
            </p>
            <p>Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren Browser so einrichten, dass er Sie immer informiert, wenn ein Cookie gesetzt werden soll. So können Sie bei jedem einzelnen Cookie entscheiden, ob Sie es erlauben oder nicht.</p>
            <p>Bitte beachten Sie, dass bei der Verwendung dieses Tools Daten von Ihnen auch außerhalb der EU gespeichert und verarbeitet werden können. Die meisten Drittstaaten (darunter auch die USA) gelten nach derzeitigem europäischen Datenschutzrecht
                als nicht sicher. Daten an unsichere Drittstaaten dürfen also nicht einfach übertragen, dort gespeichert und verarbeitet werden, sofern es keine passenden Garantien (wie etwa EU-Standardvertragsklauseln) zwischen uns und dem außereuropäischen
                Dienstleister gibt.
            </p>
            <p>Wenn Sie mehr über die Datenverarbeitung von Google erfahren wollen, empfehlen wir Ihnen die hauseigene Datenschutzerklärung des Unternehmens unter <a class="adsimple-111729218" href="https://policies.google.com/privacy?hl=de" target="_blank"
                    rel="noopener noreferrer">https://policies.google.com/privacy?hl=de</a>.</p>
            <h2 id="font-awesome-datenschutzerklaerung" class="adsimple-111729218">Font Awesome Datenschutzerklärung</h2>
            <p>Wir verwenden auf unserer Website Font Awesome des amerikanischen Unternehmens Fonticons (307 S. Main St., Suite 202, Bentonville, AR 72712, USA). Wenn Sie eine unserer Webseiten aufrufen, wird die Web-Schriftart Font Awesome (im Speziellen
                Icons) über das Font Awesome Content Delivery Netzwerk (CDN) geladen. So werden die Texte bzw. Schriften und Icons auf jedem Endgerät passend angezeigt. In dieser Datenschutzerklärung gehen wir näher auf die Datenspeicherung und Datenverarbeitung
                durch diesen Service ein.</p>
            <h3 class="adsimple-111729218">Was ist Font Awesome?</h3>
            <p>Icons spielen für Websites eine immer wichtigere Rolle. Font Awesome ist eine Web-Schriftart, die speziell für Webdesigner und Webentwickler entwickelt wurde. Mit Font Awesome können etwa Icons mit Hilfe der Stylesheet-Sprache CSS nach Belieben
                skaliert und gefärbt werden. Sie ersetzen so alte Bild-Icons. Font Awesome CDN ist der einfachste Weg die Icons oder Schriftarten auf Ihre Website zu laden. Dafür mussten wir nur eine kleine Code-Zeile in unsere Website einbinden.</p>
            <h3 class="adsimple-111729218">Warum verwenden wir Font Awesome auf unserer Website?</h3>
            <p>Durch Font Awesome können Inhalte auf unserer Website besser aufbereitet werden. So können Sie sich auf unserer Website besser orientieren und die Inhalte leichter erfassen. Mit den Icons kann man sogar manchmal ganze Wörter ersetzen und Platz
                sparen. Da ist besonders praktisch, wenn wir Inhalte speziell für Smartphones optimieren.  Diese Icons werden statt als Bild als HMTL-Code eingefügt. Dadurch können wir die Icons mit CSS genauso bearbeiten, wie wir wollen. Gleichzeitig
                verbessern wir mit Font Awesome auch unsere Ladegeschwindigkeit, weil es sich nur um HTML-Elemente handelt und nicht um Icon-Bilder. All diese Vorteile helfen uns, die Website für Sie noch übersichtlicher, frischer und schneller zu machen.</p>
            <h3 class="adsimple-111729218">Welche Daten werden von Font Awesome gespeichert?</h3>
            <p>Zum Laden von Icons und Symbolen wird das Font Awesome Content Delivery Network (CDN) verwendet. CDNs sind Netzwerke von Servern, die weltweit verteilt sind und es möglich machen, schnell Dateien aus der Nähe zu laden. So werden auch, sobald
                Sie eine unserer Seiten aufrufen, die entsprechenden Icons von Font Awesome bereitgestellt.</p>
            <p>Damit die Web-Schriftarten geladen werden können, muss Ihr Browser eine Verbindung zu den Servern des Unternehmens Fonticons, Inc. herstellen. Dabei wird Ihre IP-Adresse erkannt. Font Awesome sammelt auch Daten darüber, welche Icon-Dateien
                wann heruntergeladen werden. Weiters werden auch technische Daten wie etwa Ihre Browser-Version, Bildschirmauflösung oder der Zeitpunkt der ausgerufenen Seite übertragen.</p>
            <p>Aus folgenden Gründen werden diese Daten gesammelt und gespeichert:</p>
            <ul class="adsimple-111729218">
                <li class="adsimple-111729218">um Content Delivery Netzwerke zu optimieren</li>
                <li class="adsimple-111729218">um technische Fehler zu erkennen und zu beheben</li>
                <li class="adsimple-111729218">um CDNs vor Missbrauch und Angriffen zu schützen</li>
                <li class="adsimple-111729218">um Gebühren von Font Awesome Pro-Kunden berechnen zu können</li>
                <li class="adsimple-111729218">um die Beliebtheit von Icons zu erfahren</li>
                <li class="adsimple-111729218">um zu wissen, welchen Computer und welche Software Sie verwenden</li>
            </ul>
            <p>Falls Ihr Browser Web-Schriftarten nicht zulässt, wird automatisch eine Standardschrift Ihres PCs verwendet. Nach derzeitigem Stand unserer Erkenntnis werden keine Cookies gesetzt. Wir sind mit der Datenschutzabteilung von Font Awesome in
                Kontakt und geben Ihnen Bescheid, sobald wir näheres in Erfahrung bringen.</p>
            <h3 class="adsimple-111729218">Wie lange und wo werden die Daten gespeichert?</h3>
            <p>Font Awesome speichert Daten über die Nutzung des Content Delivery Network auf Servern auch in den Vereinigten Staaten von Amerika. Die CDN-Server befinden sich allerdings weltweit und speichern Userdaten, wo Sie sich befinden. In identifizierbarer
                Form werden die Daten in der Regel nur wenige Wochen gespeichert. Aggregierte Statistiken über die Nutzung von den CDNs können auch länger gespeichert werden. Personenbezogene Daten sind hier nicht enthalten.</p>
            <h3 class="adsimple-111729218">Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3>
            <p>Font Awesome speichert nach aktuellem Stand unseres Wissens keine personenbezogenen Daten über die Content Delivery Netzwerke. Wenn Sie nicht wollen, dass Daten über die verwendeten Icons gespeichert werden, können Sie leider unsere Website
                nicht besuchen. Wenn Ihr Browser keine Web-Schriftarten erlaubt, werden auch keine Daten übertragen oder gespeichert. In diesem Fall wird einfach die Standard-Schrift Ihres Computers verwendet.</p>
            <p>Wenn Sie mehr über Font Awesome und deren Umgang mit Daten erfahren wollen, empfehlen wir Ihnen die Datenschutzerklärung unter <a class="adsimple-111729218" href="https://fontawesome.com/privacy">https://fontawesome.com/privacy</a> und die
                Hilfeseite unter <a class="adsimple-111729218" href="https://fontawesome.com/help?tid=111729218">https://fontawesome.com/help</a>.</p>
            <h2 id="google-analytics-datenschutzerklaerung" class="adsimple-111729218">Google Analytics Datenschutzerklärung</h2>
            <p>Wir verwenden auf unserer Website das Analyse-Tracking Tool Google Analytics (GA) des amerikanischen Unternehmens Google Inc. Für den europäischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland)
                für alle Google-Dienste verantwortlich. Google Analytics sammelt Daten über Ihre Handlungen auf unserer Website. Wenn Sie beispielsweise einen Link anklicken, wird diese Aktion in einem Cookie gespeichert und an Google Analytics versandt.
                Mithilfe der Berichte, die wir von Google Analytics erhalten, können wir unsere Website und unser Service besser an Ihre Wünsche anpassen. Im Folgenden gehen wir näher auf das Tracking-Tool ein und informieren Sie vor allem darüber, welche
                Daten gespeichert werden und wie Sie das verhindern können.</p>
            <h3 class="adsimple-111729218">Was ist Google Analytics?</h3>
            <p>Google Analytics ist ein Trackingtool, das der Datenverkehrsanalyse unserer Website dient. Damit Google Analytics funktioniert, wird ein Tracking-Code in den Code unserer Website eingebaut. Wenn Sie unsere Website besuchen, zeichnet dieser
                Code verschiedene Handlungen auf, die Sie auf unserer Website ausführen. Sobald Sie unsere Website verlassen, werden diese Daten an die Google-Analytics-Server gesendet und dort gespeichert.</p>
            <p>Google verarbeitet die Daten und wir bekommen Berichte über Ihr Userverhalten. Dabei kann es sich unter anderem um folgende Berichte handeln:</p>
            <ul class="adsimple-111729218">
                <li class="adsimple-111729218">Zielgruppenberichte: Über Zielgruppenberichte lernen wir unsere User besser kennen und wissen genauer, wer sich für unser Service interessiert.</li>
                <li class="adsimple-111729218">Anzeigeberichte: Durch Anzeigeberichte können wir unsere Onlinewerbung leichter analysieren und verbessern.</li>
                <li class="adsimple-111729218">Akquisitionsberichte: Akquisitionsberichte geben uns hilfreiche Informationen darüber, wie wir mehr Menschen für unser Service begeistern können.</li>
                <li class="adsimple-111729218">Verhaltensberichte: Hier erfahren wir, wie Sie mit unserer Website interagieren. Wir können nachvollziehen welchen Weg Sie auf unserer Seite zurücklegen und welche Links Sie anklicken.</li>
                <li class="adsimple-111729218">Conversionsberichte: Conversion nennt man einen Vorgang, bei dem Sie aufgrund einer Marketing-Botschaft eine gewünschte Handlung ausführen. Zum Beispiel, wenn Sie von einem reinen Websitebesucher zu einem Käufer oder Newsletter-Abonnent
                    werden. Mithilfe dieser Berichte erfahren wir mehr darüber, wie unsere Marketing-Maßnahmen bei Ihnen ankommen. So wollen wir unsere Conversionrate steigern.</li>
                <li class="adsimple-111729218">Echtzeitberichte: Hier erfahren wir immer sofort, was gerade auf unserer Website passiert. Zum Beispiel sehen wir wie viele User gerade diesen Text lesen.</li>
            </ul>
            <h3 class="adsimple-111729218">Warum verwenden wir Google Analytics auf unserer Webseite?</h3>
            <p>Unser Ziel mit dieser Website ist klar: Wir wollen Ihnen das bestmögliche Service bieten. Die Statistiken und Daten von Google Analytics helfen uns dieses Ziel zu erreichen.</p>
            <p>Die statistisch ausgewerteten Daten zeigen uns ein klares Bild von den Stärken und Schwächen unserer Website. Einerseits können wir unsere Seite so optimieren, dass sie von interessierten Menschen auf Google leichter gefunden wird. Andererseits
                helfen uns die Daten, Sie als Besucher besser zu verstehen. Wir wissen somit sehr genau, was wir an unserer Website verbessern müssen, um Ihnen das bestmögliche Service zu bieten. Die Daten dienen uns auch, unsere Werbe- und Marketing-Maßnahmen
                individueller und kostengünstiger durchzuführen. Schließlich macht es nur Sinn, unsere Produkte und Dienstleistungen Menschen zu zeigen, die sich dafür interessieren.</p>
            <h3 class="adsimple-111729218">Welche Daten werden von Google Analytics gespeichert?</h3>
            <p>Google Analytics erstellt mithilfe eines Tracking-Codes eine zufällige, eindeutige ID, die mit Ihrem Browser-Cookie verbunden ist. So erkennt Sie Google Analytics als neuen User. Wenn Sie das nächste Mal unsere Seite besuchen, werden Sie als
                „wiederkehrender“ User erkannt. Alle gesammelten Daten werden gemeinsam mit dieser User-ID gespeichert. So ist es überhaupt erst möglich pseudonyme Userprofile auszuwerten.</p>
            <p>Um mit Google Analytics unsere Website analysieren zu können, muss eine Property-ID in den Tracking-Code eingefügt werden. Die Daten werden dann in der entsprechenden Property gespeichert. Für jede neu angelegte Property ist die Google Analytics
                4-Property standardmäßig. Alternativ kann man aber auch noch die Universal Analytics Property erstellen. Je nach verwendeter Property werden Daten unterschiedlich lange gespeichert.</p>
            <p>Durch Kennzeichnungen wie Cookies und App-Instanz-IDs werden Ihre Interaktionen auf unserer Website gemessen. Interaktionen sind alle Arten von Handlungen, die Sie auf unserer Website ausführen. Wenn Sie auch andere Google-Systeme (wie z.B.
                ein Google-Konto) nützen, können über Google Analytics generierte Daten mit Drittanbieter-Cookies verknüpft werden. Google gibt keine Google Analytics-Daten weiter, außer wir als Websitebetreiber genehmigen das. Zu Ausnahmen kann es kommen,
                wenn es gesetzlich erforderlich ist.
            </p>
            <p>Folgende Cookies werden von Google Analytics verwendet:</p>
            <p>
                <strong class="adsimple-111729218">Name:</strong> _ga<br />
                <strong class="adsimple-111729218">Wert: </strong>2.1326744211.152111729218-5<br />
                <strong class="adsimple-111729218">Verwendungszweck:</strong> Standardmäßig verwendet analytics.js das Cookie _ga, um die User-ID zu speichern. Grundsätzlich dient es zur Unterscheidung der Webseitenbesucher.<br />
                <strong class="adsimple-111729218">Ablaufdatum:</strong> nach 2 Jahren</p>
            <p>
                <strong class="adsimple-111729218">Name:</strong> _gid<br />
                <strong class="adsimple-111729218">Wert: </strong>2.1687193234.152111729218-1<br />
                <strong class="adsimple-111729218">Verwendungszweck:</strong> Das Cookie dient auch zur Unterscheidung der Webseitenbesucher<br />
                <strong class="adsimple-111729218">Ablaufdatum:</strong> nach 24 Stunden</p>
            <p>
                <strong class="adsimple-111729218">Name:</strong> _gat_gtag_UA_
                <property-id><br />
                    <strong class="adsimple-111729218">Wert:</strong> 1<br />
                    <strong class="adsimple-111729218">Verwendungszweck:</strong> Wird zum Senken der Anforderungsrate verwendet. Wenn Google Analytics über den Google Tag Manager bereitgestellt wird, erhält dieser Cookie den Namen _dc_gtm_
                    <property-id>.<br />
                        <strong class="adsimple-111729218">Ablaufdatum: </strong>nach 1 Minute
                        <p>
                            <strong class="adsimple-111729218">Name:</strong> AMP_TOKEN<br />
                            <strong class="adsimple-111729218">Wert:</strong> keine Angaben<br />
                            <strong class="adsimple-111729218">Verwendungszweck:</strong> Das Cookie hat einen Token, mit dem eine User ID vom AMP-Client-ID-Dienst abgerufen werden kann. Andere mögliche Werte weisen auf eine Abmeldung, eine Anfrage oder
                            einen Fehler hin.<br />
                            <strong class="adsimple-111729218">Ablaufdatum:</strong> nach 30 Sekunden bis zu einem Jahr</p>
                        <p>
                            <strong class="adsimple-111729218">Name:</strong> __utma<br />
                            <strong class="adsimple-111729218">Wert: </strong>1564498958.1564498958.1564498958.1<br />
                            <strong class="adsimple-111729218">Verwendungszweck:</strong> Mit diesem Cookie kann man Ihr Verhalten auf der Website verfolgen und die Leistung messen. Das Cookie wird jedes Mal aktualisiert, wenn Informationen an Google
                            Analytics gesendet werden.<br />
                            <strong class="adsimple-111729218">Ablaufdatum:</strong> nach 2 Jahren</p>
                        <p>
                            <strong class="adsimple-111729218">Name:</strong> __utmt<br />
                            <strong class="adsimple-111729218">Wert:</strong> 1<br />
                            <strong class="adsimple-111729218">Verwendungszweck:</strong> Das Cookie wird wie _gat_gtag_UA_
                            <property-id> zum Drosseln der Anforderungsrate verwendet.<br />
                                <strong class="adsimple-111729218">Ablaufdatum:</strong> nach 10 Minuten
                                <p>
                                    <strong class="adsimple-111729218">Name:</strong> __utmb<br />
                                    <strong class="adsimple-111729218">Wert: </strong>3.10.1564498958<br />
                                    <strong class="adsimple-111729218">Verwendungszweck:</strong> Dieses Cookie wird verwendet, um neue Sitzungen zu bestimmen. Es wird jedes Mal aktualisiert, wenn neue Daten bzw. Infos an Google Analytics gesendet werden.<br
                                    />
                                    <strong class="adsimple-111729218">Ablaufdatum:</strong> nach 30 Minuten</p>
                                <p>
                                    <strong class="adsimple-111729218">Name:</strong> __utmc<br />
                                    <strong class="adsimple-111729218">Wert:</strong> 167421564<br />
                                    <strong class="adsimple-111729218">Verwendungszweck:</strong> Dieses Cookie wird verwendet, um neue Sitzungen für wiederkehrende Besucher festzulegen. Dabei handelt es sich um ein Session-Cookie und wird nur solange
                                    gespeichert, bis Sie den Browser wieder schließen.<br />
                                    <strong class="adsimple-111729218">Ablaufdatum:</strong> Nach Schließung des Browsers</p>
                                <p>
                                    <strong class="adsimple-111729218">Name:</strong> __utmz<br />
                                    <strong class="adsimple-111729218">Wert:</strong> m|utmccn=(referral)|utmcmd=referral|utmcct=/<br />
                                    <strong class="adsimple-111729218">Verwendungszweck:</strong> Das Cookie wird verwendet, um die Quelle des Besucheraufkommens auf unserer Website zu identifizieren. Das heißt, das Cookie speichert, von wo Sie auf unsere
                                    Website gekommen sind. Das kann eine andere Seite bzw. eine Werbeschaltung gewesen sein.<br />
                                    <strong class="adsimple-111729218">Ablaufdatum:</strong> nach 6 Monaten</p>
                                <p>
                                    <strong class="adsimple-111729218">Name:</strong> __utmv<br />
                                    <strong class="adsimple-111729218">Wert:</strong> keine Angabe<br />
                                    <strong class="adsimple-111729218">Verwendungszweck:</strong> Das Cookie wird verwendet, um benutzerdefinierte Userdaten zu speichern. Es wird immer aktualisiert, wenn Informationen an Google Analytics gesendet werden.<br
                                    />
                                    <strong class="adsimple-111729218">Ablaufdatum:</strong> nach 2 Jahren</p>
                                <p>
                                    <strong class="adsimple-111729218">Anmerkung:</strong> Diese Aufzählung kann keinen Anspruch auf Vollständigkeit erheben, da Google die Wahl ihrer Cookies immer wieder auch verändert.</p>
                                <p>Hier zeigen wir Ihnen einen Überblick über die wichtigsten Daten, die mit Google Analytics erhoben werden:</p>
                                <p>
                                    <strong class="adsimple-111729218">Heatmaps:</strong> Google legt sogenannte Heatmaps an. Über Heatmaps sieht man genau jene Bereiche, die Sie anklicken. So bekommen wir Informationen, wo Sie auf unserer Seite „unterwegs“
                                    sind.
                                </p>
                                <p>
                                    <strong class="adsimple-111729218">Sitzungsdauer:</strong> Als Sitzungsdauer bezeichnet Google die Zeit, die Sie auf unserer Seite verbringen, ohne die Seite zu verlassen. Wenn Sie 20 Minuten inaktiv waren, endet die
                                    Sitzung automatisch.
                                </p>
                                <p>
                                    <strong class="adsimple-111729218">Absprungrate</strong> (engl. Bouncerate): Von einem Absprung ist die Rede, wenn Sie auf unserer Website nur eine Seite ansehen und dann unsere Website wieder verlassen.</p>
                                <p>
                                    <strong class="adsimple-111729218">Kontoerstellung:</strong> Wenn Sie auf unserer Website ein Konto erstellen bzw. eine Bestellung machen, erhebt Google Analytics diese Daten.</p>
                                <p>
                                    <strong class="adsimple-111729218">IP-Adresse:</strong> Die IP-Adresse wird nur in gekürzter Form dargestellt, damit keine eindeutige Zuordnung möglich ist.</p>
                                <p>
                                    <strong class="adsimple-111729218">Standort:</strong> Über die IP-Adresse kann das Land und Ihr ungefährer Standort bestimmt werden. Diesen Vorgang bezeichnet man auch als IP- Standortbestimmung.</p>
                                <p>
                                    <strong class="adsimple-111729218">Technische Informationen:</strong> Zu den technischen Informationen zählen unter anderem Ihr Browsertyp, Ihr Internetanbieter oder Ihre Bildschirmauflösung.</p>
                                <p>
                                    <strong class="adsimple-111729218">Herkunftsquelle:</strong> Google Analytics beziehungsweise uns interessiert natürlich auch über welche Website oder welche Werbung Sie auf unsere Seite gekommen sind.</p>
                                <p>Weitere Daten sind Kontaktdaten, etwaige Bewertungen, das Abspielen von Medien (z.B., wenn Sie ein Video über unsere Seite abspielen), das Teilen von Inhalten über Social Media oder das Hinzufügen zu Ihren Favoriten. Die
                                    Aufzählung hat keinen Vollständigkeitsanspruch und dient nur zu einer allgemeinen Orientierung der Datenspeicherung durch Google Analytics.</p>
                                <h3 class="adsimple-111729218">Wie lange und wo werden die Daten gespeichert?</h3>
                                <p>Google hat Ihre Server auf der ganzen Welt verteilt. Die meisten Server befinden sich in Amerika und folglich werden Ihre Daten meist auf amerikanischen Servern gespeichert. Hier können Sie genau nachlesen wo sich die Google-Rechenzentren
                                    befinden:
                                    <a class="adsimple-111729218" href="https://www.google.com/about/datacenters/inside/locations/?hl=de" target="_blank" rel="noopener noreferrer">https://www.google.com/about/datacenters/inside/locations/?hl=de</a>
                                </p>
                                <p>Ihre Daten werden auf verschiedenen physischen Datenträgern verteilt. Das hat den Vorteil, dass die Daten schneller abrufbar sind und vor Manipulation besser geschützt sind. In jedem Google-Rechenzentrum gibt es entsprechende
                                    Notfallprogramme für Ihre Daten. Wenn beispielsweise die Hardware bei Google ausfällt oder Naturkatastrophen Server lahmlegen, bleibt das Risiko einer Dienstunterbrechung bei Google dennoch gering.</p>
                                <p>Die Aufbewahrungsdauer der Daten hängt von den verwendeten Properties ab. Bei der Verwendung der neueren Google Analytics 4-Properties ist die Aufbewahrungsdauer Ihrer Userdaten auf 14 Monate fix eingestellt. Für andere
                                    sogenannte Ereignisdaten haben wir die Möglichkeit eine Aufbewahrungsdauer von 2 Monaten oder 14 Monaten zu wählen.</p>
                                <p>Bei Universal Analytics-Properties ist bei Google Analytics eine Aufbewahrungsdauer Ihrer Userdaten von 26 Monaten standardisiert eingestellt. Dann werden Ihre Userdaten gelöscht. Allerdings haben wir die Möglichkeit, die
                                    Aufbewahrungsdauer von Nutzdaten selbst zu wählen. Dafür stehen uns fünf Varianten zur Verfügung:</p>
                                <ul class="adsimple-111729218">
                                    <li class="adsimple-111729218">Löschung nach 14 Monaten</li>
                                    <li class="adsimple-111729218">Löschung nach 26 Monaten</li>
                                    <li class="adsimple-111729218">Löschung nach 38 Monaten</li>
                                    <li class="adsimple-111729218">Löschung nach 50 Monaten</li>
                                    <li class="adsimple-111729218">Keine automatische Löschung</li>
                                </ul>
                                <p>Zusätzlich gibt es auch die Option, dass Daten erst dann gelöscht werden, wenn Sie innerhalb des von uns gewählten Zeitraums nicht mehr unsere Website besuchen. In diesem Fall wird die Aufbewahrungsdauer jedes Mal zurückgesetzt,
                                    wenn Sie unsere Website innerhalb des festgelegten Zeitraums wieder besuchen.</p>
                                <p>Wenn der festgelegte Zeitraum abgelaufen ist, werden einmal im Monat die Daten gelöscht. Diese Aufbewahrungsdauer gilt für Ihre Daten, die mit Cookies, Usererkennung und Werbe-IDs (z.B. Cookies der DoubleClick-Domain) verknüpft
                                    sind. Berichtergebnisse basieren auf aggregierten Daten und werden unabhängig von Nutzerdaten gespeichert. Aggregierte Daten sind eine Zusammenschmelzung von Einzeldaten zu einer größeren Einheit.</p>
                                <h3 class="adsimple-111729218">Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3>
                                <p>Nach dem Datenschutzrecht der Europäischen Union haben Sie das Recht, Auskunft über Ihre Daten zu erhalten, sie zu aktualisieren, zu löschen oder einzuschränken. Mithilfe des Browser-Add-ons zur Deaktivierung von Google
                                    Analytics-JavaScript (ga.js, analytics.js, dc.js) verhindern Sie, dass Google Analytics Ihre Daten verwendet. Das Browser-Add-on können Sie unter <a class="adsimple-111729218" href="https://tools.google.com/dlpage/gaoptout?hl=de"
                                        target="_blank" rel="noopener noreferrer">https://tools.google.com/dlpage/gaoptout?hl=de</a> runterladen und installieren. Beachten Sie bitte, dass durch dieses Add-on nur die Datenerhebung durch Google Analytics deaktiviert
                                    wird.
                                </p>
                                <p>Falls Sie grundsätzlich Cookies (unabhängig von Google Analytics) deaktivieren, löschen oder verwalten wollen, gibt es für jeden Browser eine eigene Anleitung:</p>
                                <p>
                                    <a class="adsimple-111729218" href="https://support.google.com/chrome/answer/95647?tid=111729218" target="_blank" rel="noopener noreferrer">Chrome: Cookies in Chrome löschen, aktivieren und verwalten</a>
                                </p>
                                <p>
                                    <a class="adsimple-111729218" href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=111729218" target="_blank" rel="noopener noreferrer">Safari: Verwalten von Cookies und Websitedaten mit Safari</a>
                                </p>
                                <p>
                                    <a class="adsimple-111729218" href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=111729218" target="_blank" rel="noopener noreferrer">Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf Ihrem Computer abgelegt haben</a>
                                </p>
                                <p>
                                    <a class="adsimple-111729218" href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=111729218" target="_blank" rel="noopener noreferrer">Internet Explorer: Löschen und Verwalten von Cookies</a>
                                </p>
                                <p>
                                    <a class="adsimple-111729218" href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=111729218" target="_blank" rel="noopener noreferrer">Microsoft Edge: Löschen und Verwalten von Cookies</a>
                                </p>
                                <p>Bitte beachten Sie, dass bei der Verwendung dieses Tools Daten von Ihnen auch außerhalb der EU gespeichert und verarbeitet werden können. Die meisten Drittstaaten (darunter auch die USA) gelten nach derzeitigem europäischen
                                    Datenschutzrecht als nicht sicher. Daten an unsichere Drittstaaten dürfen also nicht einfach übertragen, dort gespeichert und verarbeitet werden, sofern es keine passenden Garantien (wie etwa EU-Standardvertragsklauseln)
                                    zwischen uns und dem außereuropäischen Dienstleister gibt.
                                </p>
                                <p>Wir hoffen wir konnten Ihnen die wichtigsten Informationen rund um die Datenverarbeitung von Google Analytics näherbringen. Wenn Sie mehr über den Tracking-Dienst erfahren wollen, empfehlen wir diese beiden Links: <a class="adsimple-111729218"
                                        href="http://www.google.com/analytics/terms/de.html" target="_blank" rel="noopener noreferrer">http://www.google.com/analytics/terms/de.html</a> und <a class="adsimple-111729218" href="https://support.google.com/analytics/answer/6004245?hl=de"
                                        target="_blank" rel="noopener noreferrer">https://support.google.com/analytics/answer/6004245?hl=de</a>.</p>
                                <h2 id="google-analytics-ip-anonymisierung" class="adsimple-111729218">Google Analytics IP-Anonymisierung</h2>
                                <p>Wir haben auf dieser Webseite die IP-Adressen-Anonymisierung von Google Analytics implementiert. Diese Funktion wurde von Google entwickelt, damit diese Webseite die geltenden Datenschutzbestimmungen und Empfehlungen der
                                    lokalen Datenschutzbehörden einhalten kann, wenn diese eine Speicherung der vollständigen IP-Adresse untersagen. Die Anonymisierung bzw. Maskierung der IP findet statt, sobald die IP-Adressen im Google Analytics-Datenerfassungsnetzwerk
                                    eintreffen und bevor eine Speicherung oder Verarbeitung der Daten stattfindet.</p>
                                <p>Mehr Informationen zur IP-Anonymisierung finden Sie auf <a class="adsimple-111729218" href="https://support.google.com/analytics/answer/2763052?hl=de" target="_blank" rel="noopener">https://support.google.com/analytics/answer/2763052?hl=de</a>.</p>
                                <h2 id="google-analytics-berichte-zu-demografischen-merkmalen-und-interessen" class="adsimple-111729218">Google Analytics Berichte zu demografischen Merkmalen und Interessen</h2>
                                <p>Wir haben in Google Analytics die Funktionen für Werbeberichte eingeschaltet. Die Berichte zu demografischen Merkmalen und Interessen enthalten Angaben zu Alter, Geschlecht und Interessen. Damit können wir uns – ohne diese
                                    Daten einzelnen Personen zuordnen zu können – ein besseres Bild von unseren Nutzern machen. Mehr über die Werbefunktionen erfahren Sie <a class="adsimple-111729218" href="https://support.google.com/analytics/answer/3450482?hl=de_AT&utm_id=ad"
                                        target="_blank" rel="noopener">auf https://support.google.com/analytics/answer/3450482?hl=de_AT&utm_id=ad</a>.</p>
                                <p>Sie können die Nutzung der Aktivitäten und Informationen Ihres Google Kontos unter “Einstellungen für Werbung” auf <a class="adsimple-111729218" href="https://adssettings.google.com/authenticated">https://adssettings.google.com/authenticated</a>                                    per Checkbox beenden.</p>
                                <h2 id="google-analytics-deaktivierungslink" class="adsimple-111729218">Google Analytics Deaktivierungslink</h2>
                                <p>Wenn Sie auf folgenden <strong class="adsimple-111729218">Deaktivierungslink</strong> klicken, können Sie verhindern, dass Google weitere Besuche auf dieser Website erfasst. Achtung: Das Löschen von Cookies, die Nutzung
                                    des Inkognito/Privatmodus ihres Browsers, oder die Nutzung eines anderen Browsers führt dazu, dass wieder Daten erhoben werden.</p>
                                <p>
                                    <a class="adsimple-111729218">Google Analytics deaktivieren</a>
                                </p>
                                <h2 id="google-analytics-zusatz-zur-datenverarbeitung" class="adsimple-111729218">Google Analytics Zusatz zur Datenverarbeitung</h2>
                                <p>Wir haben mit Google einen Direktkundenvertrag zur Verwendung von Google Analytics abgeschlossen, indem wir den “Zusatz zur Datenverarbeitung” in Google Analytics akzeptiert haben.</p>
                                <p>Mehr über den Zusatz zur Datenverarbeitung für Google Analytics finden Sie hier: <a class="adsimple-111729218" href="https://support.google.com/analytics/answer/3379636?hl=de&utm_id=ad" target="_blank" rel="noopener">https://support.google.com/analytics/answer/3379636?hl=de&utm_id=ad</a>
                                </p>
                                <h2 id="google-analytics-google-signale-datenschutzerklaerung" class="adsimple-111729218">Google Analytics Google-Signale Datenschutzerklärung</h2>
                                <p>Wir haben in Google Analytics die Google-Signale aktiviert. So werden die bestehenden Google-Analytics-Funktionen (Werbeberichte, Remarketing, gerätübergreifende Berichte und Berichte zu Interessen und demografische Merkmale)
                                    aktualisiert, um zusammengefasste und anonymisierte Daten von Ihnen zu erhalten, sofern Sie personalisierte Anzeigen in Ihrem Google-Konto erlaubt haben.</p>
                                <p>Das besondere daran ist, dass es sich dabei um ein Cross-Device-Tracking handelt. Das heißt Ihre Daten können geräteübergreifend analysiert werden. Durch die Aktivierung von Google-Signale werden Daten erfasst und mit dem
                                    Google-Konto verknüpft. Google kann dadurch zum Beispiel erkennen, wenn Sie auf unsere Webseite über ein Smartphone ein Produkt ansehen und erst später über einen Laptop das Produkt kaufen. Dank der Aktivierung von
                                    Google-Signale können wir gerätübergreifende Remarketing-Kampagnen starten, die sonst in dieser Form nicht möglich wären. Remarketing bedeutet, dass wir Ihnen auch auf anderen Webseiten unser Angebot zeigen können.</p>
                                <p>In Google Analytics werden zudem durch die Google-Signale weitere Besucherdaten wie Standort, Suchverlauf, YouTube-Verlauf und Daten über Ihre Handlungen auf unserer Webseite, erfasst. Wir erhalten dadurch von Google bessere
                                    Werbeberichte und nützlichere Angaben zu Ihren Interessen und demografischen Merkmalen. Dazu gehören Ihr Alter, welche Sprache sie sprechen, wo Sie wohnen oder welchem Geschlecht Sie angehören. Weiters kommen auch noch
                                    soziale Kriterien wie Ihr Beruf, Ihr Familienstand oder Ihr Einkommen hinzu. All diese Merkmal helfen Google Analytics Personengruppen bzw. Zielgruppen zu definieren.</p>
                                <p>Die Berichte helfen uns auch Ihr Verhalten, Ihre Wünsche und Interessen besser einschätzen zu können. Dadurch können wir unsere Dienstleistungen und Produkte für Sie optimieren und anpassen. Diese Daten laufen standardmäßig
                                    nach 26 Monaten ab. Bitte beachten Sie, dass diese Datenerfassung nur erfolgt, wenn Sie personalisierte Werbung in Ihrem Google-Konto zugelassen haben. Es handelt sich dabei immer um zusammengefasste und anonyme Daten
                                    und nie um Daten einzelner Personen. In Ihrem Google-Konto können Sie diese Daten verwalten bzw. auch löschen.</p>
                                <h2 id="e-mail-marketing" class="adsimple-111729218">E-Mail-Marketing</h2>
                                <p>Wir wollen natürlich mit Ihnen in Kontakt bleiben und Ihnen stets die wichtigsten Neuigkeiten über unser Unternehmen präsentieren. Dafür nutzen wir unter anderem E-Mail-Marketing, ein wesentlicher Bestandteil unseres Online-Marketings.
                                    Sofern Sie sich damit einverstanden erklären oder es gesetzlich erlaubt ist, schicken wir Ihnen Newsletter, E-Mails oder andere Benachrichtigungen. Wenn wir im folgenden Text den Begriff „Newsletter“ verwenden, meinen
                                    wir damit hauptsächlich regelmäßig versendete E-Mails.
                                </p>
                                <h3 class="adsimple-111729218">Wie melden Sie sich für unser E-Mail-Marketing an?</h3>
                                <p>Wenn Sie an unserem E-Mail-Marketing (meist per Newsletter) teilnehmen wollen, müssen Sie sich im Normalfall einfach nur mit Ihrer E-Mail-Adresse anmelden. Dafür füllen Sie ein Online-Formular aus und senden es ab. Es kann
                                    aber auch vorkommen, dass wir Sie etwa um Ihre Anrede und Ihren Namen bitten, damit wir Sie auch persönlich anschreiben können.</p>
                                <p>Grundsätzlich funktioniert das Anmelden zu Newslettern mit Hilfe des sogenannten „Double-Opt-In-Verfahrens“. Nachdem Sie sich für unseren Newsletter auf unserer Website angemeldet haben, bekommen Sie eine E-Mail, über die
                                    Sie die Newsletter-Anmeldung bestätigen. So wird sichergestellt, dass Ihnen die E-Mail-Adresse gehört und sich niemand mit einer fremden E-Mail-Adresse angemeldet hat. Wir oder ein von uns verwendetes Benachrichtigungs-Tool
                                    protokolliert jede einzelne Anmeldung. Dies ist nötig, damit wir den rechtlich korrekten Anmeldevorgang auch nachweisen können. Dabei wird in der Regel der Zeitpunkt der Anmeldung, der Zeitpunkt der Anmeldebestätigung
                                    und Ihre IP-Adresse gespeichert. Zusätzlich wird auch protokolliert, wenn Sie Änderungen Ihrer gespeicherten Daten vornehmen.</p>
                                <h3 class="adsimple-111729218">Wie lange dürfen wir Ihre E-Mail-Adresse speichern?</h3>
                                <p>Wenn Sie Ihre E-Mail-Adresse aus unserem E-Mail/Newsletter-Verteiler austragen, dürfen wir Ihre Adresse bis zu drei Jahren auf Grundlage unserer berechtigten Interessen speichern, damit wir Ihre damalige Einwilligung noch
                                    nachweisen können. Verarbeiten dürfen wir diese Daten nur, wenn wir uns gegen etwaige Ansprüche wehren müssen.</p>
                                <p>Wenn Sie allerdings bestätigen, dass Sie uns die Einwilligung zur Newsletter-Anmeldung gegeben haben, können Sie jederzeit einen individuellen Löschantrag stellen. Widersprechen Sie der Einwilligung dauerhaft, behalten
                                    wir uns das Recht vor, Ihre E-Mail-Adresse in einer Sperrliste zu speichern. Solange Sie freiwillig unseren Newsletter abonniert haben, solange behalten wir selbstverständlich auch Ihre E-Mail-Adresse.</p>
                                <h3 class="adsimple-111729218">Auf welcher Rechtsgrundlage betreiben wir E-Mail-Marketing?</h3>
                                <p>Das Versenden unseres Newsletters erfolgt auf Grundlage Ihrer Einwilligung. Das heißt, wir dürfen Ihnen nur dann einen Newsletter schicken, wenn Sie sich zuvor aktiv dafür angemeldet haben. Wenn eine Einwilligung nicht
                                    nötig ist, dann erfolgt der Newsletter-Versand auf Grundlage des berechtigten Interesses am Direktmarketing, sofern dies rechtlich erlaubt ist. Auch wenn wir einen Dienstleister beauftragen, passiert dies auf der Grundlage
                                    unseres berechtigten Interesses. Ihren Registrierungsprozess zeichnen wir auf, damit wir stets nachweisen können, dass dieser unseren Gesetzen entspricht.</p>
                                <h3 class="adsimple-111729218">Was steht in unseren Newslettern?</h3>
                                <p>Natürlich wollen wir Sie mit unseren Newsletter in keiner Weise belästigen. Darum sind wir wirklich stets bemüht, nur relevante und interessante Inhalte zu bieten. So erfahren Sie etwa mehr über unser Unternehmen, unsere
                                    Leistungen oder Produkte. Da wir unsere Angebote auch immer verbessern, erfahren Sie über unseren Newsletter auch immer, wenn es Neuigkeiten gibt oder wir gerade spezielle, lukrative Aktionen anbieten.</p>
                                <p>Sofern wir einen Dienstleister, der ein professionelles Versand-Tool anbietet, für unser E-Mail-Marketing beauftragen, machen wir das, um Ihnen schnelle und sichere Newsletter bieten zu können.</p>
                                <h3 class="adsimple-111729218">Welche Daten werden gespeichert?</h3>
                                <p>Wenn Sie über unsere Website Abonnent unseres Newsletters werden, bestätigen Sie per E-Mail die Mitgliedschaft in einer E-Mail-Liste. Neben IP-Adresse und E-Mail-Adresse können auch Ihr Name, Ihre Adresse und Ihre Telefonnummer
                                    gespeichert werden. Allerdings nur, wenn Sie dieser Datenspeicherungen zustimmen. Zusätzlich können etwa auch Informationen zu Ihrem Gerät oder zu Ihren bevorzugten Inhalten auf unserer Website gespeichert werden. Mehr
                                    zur Speicherung von Daten, wenn Sie eine Website besuchen, finden Sie im Abschnitt “Automatische Datenspeicherung”.</p>
                                <p>Informationen zu speziellen E-Mail-Marketing Diensten, erfahren Sie – sofern vorhanden – in den folgenden Abschnitten.</p>
                                <h3 class="adsimple-111729218">Wie kann ich mein Abo kündigen?</h3>
                                <p>Sie haben jederzeit die Möglichkeit Ihre Newsletter-Anmeldung zu kündigen. Dafür müssen Sie lediglich Ihre Einwilligung zur Newsletter-Anmeldung widerrufen. Das dauert im Normalfall nur wenige Minuten bzw. ein paar Klicks.
                                    Meistens finden Sie direkt in unserem Newsletter einen Link, um das Abonnement zu kündigen. Wenn der Link im Newsletter wirklich nicht zu finden ist, kontaktieren Sie uns bitte per Mail und wir werden Ihr Newsletter-Abo
                                    kündigen.
                                </p>
                                <h2 id="zahlungsanbieter" class="adsimple-111729218">Zahlungsanbieter</h2>
                                <p>Wir verwenden um Spenden zu übermitteln Online Zahlungsanbieter.</p>
                                <p>Wir bieten also im Rahmen von vertraglichen bzw. rechtlichen Beziehungen, wegen gesetzlicher Pflichten und auf Grundlage des berechtigten Interesses neben Bank-/Kreditinstitutionen auch andere Zahlungsdienstleister an.
                                    In den Datenschutzerklärungen der einzelnen Zahlungsanbietern (wie zum Beispiel <span class="adsimple-111729218">Amazon Payments</span>,
                                    <span class="adsimple-111729218">Apple Pay</span> oder <span class="adsimple-111729218">Discover</span>) wird Ihnen ein genauer Überblick über die Datenverarbeitung und Datenspeicherung geboten. Zudem können Sie sich
                                    bei Fragen zu datenschutzrelevanten Themen stets an die Verantwortlichen richten.
                                </p>
                                <h3 class="adsimple-111729218">Was ist ein Zahlungsanbieter?</h3>
                                <p>Bei Zahlungsanbietern handelt es sich um Online-Zahlungssysteme, die es Ihnen ermöglichen eine Bestellung über Online-Banking durchzuführen. Dabei wird die Zahlungsabwicklung durch den von Ihnen gewählten Zahlungsanbieter
                                    durchgeführt. Wir erhalten anschließend eine Information über die getätigte Zahlung. Diese Methode kann jeder User nutzen, der ein aktives Online-Banking-Konto mit PIN und TAN hat. Es gibt kaum noch Banken, die solche
                                    Zahlungsmethoden nicht anbieten bzw. akzeptieren.</p>
                                <h3 class="adsimple-111729218">Warum verwenden wir Zahlungsanbieter auf unserer Website?</h3>
                                <p>Um Spenden einfach und sicher zu transferieren, haben wir diese Zahlungsanbieter implementiert.</p>
                                <h3 class="adsimple-111729218">Welche Daten werden gespeichert?</h3>
                                <p>Welche Daten genau verarbeitet werden, hängt natürlich von dem jeweiligen Zahlungsanbieter ab. Doch grundsätzlich werden Daten wie Name, Adresse, Bankdaten (Kontonummer, Kreditkartennummer, Passwörter, TANs usw.) gespeichert.
                                    Dabei handelt es sich um notwendige Daten, um überhaupt eine Transaktion durchführen zu können. Zudem können auch etwaige Vertragsdaten und Userdaten, wie zum Beispiel wann Sie unsere Website besuchen, für welche Inhalte
                                    Sie sich interessieren oder welche Unterseiten Sie anklicken, gespeichert werden. Auch Ihre IP-Adresse und Informationen zu Ihrem verwendeten Computer werden von den meisten Zahlungsanbietern gespeichert.</p>
                                <p>Die Daten werden in der Regel auf den Servern der Zahlungsanbietern gespeichert und verarbeitet. Wir als Websitebetreiber erhalten diese Daten nicht. Wir werden nur darüber informiert, ob die Zahlung funktioniert hat oder
                                    nicht. Für Identitäts- und Bonitätsprüfungen kann es vorkommen, dass Zahlungsanbieter Daten an die entsprechende Stelle weiterleiten. Für alle Zahlungsgeschäfte gelten immer die Geschäfts- und Datenschutzgrundlagen
                                    des jeweiligen Anbieters. Schauen Sie sich daher bitte immer auch die Allgemeinen Geschäftsbedingen und die Datenschutzerklärung des Zahlungsanbieters an. Sie haben auch jederzeit das Recht beispielsweise Daten löschen
                                    oder korrigieren zu lassen. Bitte setzen Sie sich bezüglich Ihrer Rechte (Widerrufsrecht, Auskunftsrecht und Betroffenheitsrecht) mit dem jeweiligen Dienstanbieter in Verbindung.</p>
                                <p>Informationen zu den speziellen Zahlungsanbietern erfahren Sie – sofern vorhanden – in den folgenden Abschnitten.</p>
                                <h2 id="visa-datenschutzerklaerung" class="adsimple-111729218">Visa Datenschutzerklärung</h2>
                                <p>Wir nutzen auf unserer Website Visa, einen weltweit agierenden Zahlungsanbieter. Dienstanbieter ist das amerikanische Unternehmen Visa Inc. Für den europäischen Raum ist das Unternehmen Visa Europe Services Inc. (1 Sheldon
                                    Square, London W2 6TT, Großbritannien) zuständig. Mehr über die Daten, die durch die Verwendung von Visa verarbeitet werden, erfahren Sie in der Privacy Policy auf <u>
<a class="adsimple-111729218" href="https://www.visa.de/nutzungsbedingungen/visa-privacy-center.html">https://www.visa.de/nutzungsbedingungen/visa-privacy-center.html</a>. </u>
                                </p>
                                <h2 id="paypal-datenschutzerklaerung" class="adsimple-111729218">PayPal Datenschutzerklärung</h2>
                                <p>Wir nutzen auf unserer Website den Online-Bezahldienst PayPal. Dienstanbieter ist das amerikanische Unternehmen PayPal Inc. Für den europäischen Raum ist das Unternehmen PayPal Europe (S.à r.l. et Cie, S.C.A., 22-24 Boulevard
                                    Royal, L-2449 Luxembourg) verantwortlich. Mehr über die Daten, die durch die Verwendung von PayPal verarbeitet werden, erfahren Sie in der Privacy Policy auf <u>
<a class="adsimple-111729218" href="https://www.paypal.com/de/webapps/mpp/ua/privacy-full">https://www.paypal.com/de/webapps/mpp/ua/privacy-full</a>.</u>
                                </p>
                                <h2 id="mastercard-datenschutzerklaerung" class="adsimple-111729218">Mastercard Datenschutzerklärung</h2>
                                <p>Wir nutzen auf unserer Website den Zahlungsdienstleister Mastercard. Dienstanbieter ist das amerikanische Unternehmen Mastercard Inc. Für den europäischen Raum ist das Unternehmen Mastercard Europe SA (Chaussée de Tervuren
                                    198A, B-1410 Waterloo, Belgien) verantwortlich. Mehr über die Daten, die durch die Verwendung von Mastercard verarbeitet werden, erfahren Sie in der Privacy Policy auf <u>
<a class="adsimple-111729218" href="https://www.mastercard.de/de-de/datenschutz.html" target="_blank" rel="noopener">https://www.mastercard.de/de-de/datenschutz.html</a>.</u>
                                </p>
                                <h2 id="eps-ueberweisung-datenschutzerklaerung" class="adsimple-111729218">eps-Überweisung Datenschutzerklärung</h2>
                                <p>Wir nutzen auf unserer Website eps-Überweisung, einen Dienst für Online-Bezahlverfahren. Dienstanbieter ist das österreichische Unternehmen Stuzza GmbH, Frankgasse 10/8, 1090 Wien, Österreich. Mehr über die Daten, die durch
                                    die Verwendung von eps-Überweisung verarbeitet werden, erfahren Sie in der Privacy Policy auf <u>
<a class="adsimple-111729218" href="https://eservice.stuzza.at/de/datenschutzerklaerung.html">https://eservice.stuzza.at/de/datenschutzerklaerung.html</a>.</u>
                                </p>
                                <h2 id="apple-pay-datenschutzerklaerung" class="adsimple-111729218">Apple Pay Datenschutzerklärung</h2>
                                <p>Wir nutzen auf unserer Website Apple Pay, einen Dienst für Online-Zahlungsverfahren. Dienstanbieter ist das amerikanische Unternehmen Apple Inc., Infinite Loop, Cupertino, CA 95014, USA. Mehr über die Daten, die durch die
                                    Verwendung von Apple Pay verarbeitet werden, erfahren Sie in der Privacy Policy auf <u>
<a class="adsimple-111729218" href="https://www.apple.com/legal/privacy/de-ww/">https://www.apple.com/legal/privacy/de-ww/</a>. </u>
                                </p>
                                <h2 id="sofortueberweisung-datenschutzerklaerung" class="adsimple-111729218">Sofortüberweisung Datenschutzerklärung</h2>
                                <p>Wir bieten auf unserer Website die Zahlungsmethode „Sofortüberweisung“ des Unternehmens Sofort GmbH zur bargeldlosen Bezahlung an. Die Sofort GmbH gehört seit 2014 zum schwedischen Unternehmen Klarna, hat aber ihren Firmensitz
                                    in Deutschland, Theresienhöhe 12, 80339 München.</p>
                                <p>Entscheiden Sie sich für diese Zahlungsmethode werden unter anderem auch personenbezogene Daten an die Sofort GmbH bzw. an Klarna übermittelt, gespeichert und dort verarbeitet. Mit diesem Datenschutztext geben wir Ihnen
                                    einen Überblick über die Datenverarbeitung durch die Sofort GmbH.</p>
                                <h3 class="adsimple-111729218">Was ist eine „Sofortüberweisung“?</h3>
                                <p>Bei der Sofortüberweisung handelt es sich um ein Online-Zahlungssystem, das es Ihnen ermöglicht eine Bestellung über Online-Banking durchzuführen. Dabei wird die Zahlungsabwicklung durch die Sofort GmbH durchgeführt und
                                    wir erhalten sofort eine Information über die getätigte Zahlung. Diese Methode kann jeder User nutzen, der ein aktives Online-Banking-Konto mit PIN und TAN hat. Nur noch wenige Banken unterstützen diese Zahlungsmethode
                                    noch nicht.
                                </p>
                                <h3 class="adsimple-111729218">Warum verwenden wir „Sofortüberweisung“ auf unserer Website?</h3>
                                <p>Um Spenden einfach und sicher zu transferieren, haben wir diese Zahlungsanbieter implementiert.</p>
                                <h3 class="adsimple-111729218">Welche Daten werden von „Sofortüberweisung“ gespeichert?</h3>
                                <p>Wenn Sie über den Sofort/Klarna-Dienst eine Sofortüberweisung durchführen, werden Daten wie Name, Kontonummer, Bankleitzahl, Betreff, Betrag und Datum auf den Servern des Unternehmens gespeichert. Diese Informationen erhalten
                                    auch wir über die Zahlungsbestätigung.</p>
                                <p>Im Rahmen der Kontodeckungsprüfung überprüft die Sofort GmbH, ob Ihr Kontostand und Überziehungskreditrahmen den Zahlungsbetrag abdeckt. In manchen Fällen wird auch überprüft, ob in den letzten 30 Tagen Sofortüberweisungen
                                    erfolgreich durchgeführt wurden. Weiters wird Ihre User-Identifikation (wie etwa Verfügernummer oder Vertragsnummer) in gekürzter („gehashter“) Form und Ihre IP-Adresse erhoben und gespeichert. Bei SEPA-Überweisungen
                                    wird auch BIC und IBAN gespeichert.</p>
                                <p>Laut dem Unternehmen werden sonst keine weiteren personenbezogenen Daten (wie Kontostände, Umsatzdaten, Verfügungsrahmen, Kontolisten, Mobiltelefonnummer, Authentifizierungszertifikate, Sicherheitscodses oder PIN/TAN) erhoben,
                                    gespeichert oder an Dritte weitergegeben.</p>
                                <p>Sofortüberweisung nutzt auch Cookies, um den eigenen Dienst benutzerfreundlicher zu gestalten. Wenn Sie ein Produkt bestellen, werden Sie auf die Sofort bzw. Klarna-Website umgeleitet. Nach der erfolgreichen Zahlung werden
                                    Sie auf unsere Dankesseite weitergeleitet. Hier werden folgende drei Cookies gesetzt:</p>
                                <p>
                                    <strong class="adsimple-111729218">Name</strong>: SOFUEB<br />
                                    <strong class="adsimple-111729218">Wert: </strong>e8cipp378mdscn9e17kajlfhv7111729218-5<br />
                                    <strong class="adsimple-111729218">Verwendungszweck:</strong> Dieses Cookie speichert Ihre Session-ID.<br />
                                    <strong class="adsimple-111729218">Ablaufdatum:</strong> nach Beenden der Browsersitzung</p>
                                <p>
                                    <strong class="adsimple-111729218">Name</strong>: User[user_cookie_rules]
                                    <strong class="adsimple-111729218">Wert: </strong>1<br />
                                    <strong class="adsimple-111729218">Verwendungszweck:</strong> Dieses Cookie speichert Ihre Zustimmung zur Verwendung von Cookies.<br />
                                    <strong class="adsimple-111729218">Ablaufdatum:</strong> nach 10 Jahren</p>
                                <p>
                                    <strong class="adsimple-111729218">Name: </strong>_ga<br />
                                    <strong class="adsimple-111729218">Wert: </strong>GA1.2.69759879.1589470706<br />
                                    <strong class="adsimple-111729218">Verwendungszweck:</strong> Standardmäßig verwendet analytics.js das Cookie _ga, um die User-ID zu speichern. Grundsätzlich dient es zur Unterscheidung der Webseitenbesucher. Hier handelt
                                    es sich um ein Cookie von Google Analytics.<br />
                                    <strong class="adsimple-111729218">Ablaufdatum:</strong> nach 2 Jahren</p>
                                <p>
                                    <strong class="adsimple-111729218">Anmerkung:</strong> Die hier angeführten Cookies erheben keinen Anspruch auch Vollständigkeit. Es kann immer sein, dass Sofortüberweisung auch andere Cookies verwendet.</p>
                                <h3 class="adsimple-111729218">Wie lange und wo werden die Daten gespeichert?</h3>
                                <p>Alle erhobenen Daten werden innerhalb der rechtlichen Aufbewahrungspflicht gespeichert. Diese Pflicht kann zwischen drei und zehn Jahren dauern.</p>
                                <p>Klarna/Sofort GmbH versucht Daten nur innerhalb der EU bzw. des Europäischen Wirtschaftsraums (EWR) zu speichern. Wenn Daten außerhalb des EU/EWR übertragen werden, muss der Datenschutz mit der DSGVO übereinstimmen und
                                    das Land in einer Angemessenheitsentscheidung der EU stehen.</p>
                                <h3 class="adsimple-111729218">Wie kann ich meine Daten löschen oder die Datenspeicherung verhindern?</h3>
                                <p>Sie können Ihre Einwilligung, dass Klarna personenbezogene Daten verarbeitet jederzeit widerrufen. Sie haben auch immer das Recht auf Auskunft, Berichtigung und Löschung Ihrer personenbezogenen Daten. Dafür können Sie einfach
                                    das Datenschutzteam des Unternehmens per E-Mail an datenschutz@sofort.com kontaktieren.</p>
                                <p>Mögliche Cookies, die Sofortüberweisung verwendet, können Sie in Ihrem Browser verwalten, löschen oder deaktivieren. Abhängig von Ihrem bevorzugten Browser funktioniert das auf unterschiedliche Weise. Die folgenden Anleitungen
                                    zeigen wie Sie Cookies in den gängigsten Browsern verwalten:</p>
                                <p>
                                    <a class="adsimple-111729218" href="https://support.google.com/chrome/answer/95647?tid=111729218" target="_blank" rel="noopener noreferrer">Chrome: Cookies in Chrome löschen, aktivieren und verwalten</a>
                                </p>
                                <p>
                                    <a class="adsimple-111729218" href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=111729218" target="_blank" rel="noopener noreferrer">Safari: Verwalten von Cookies und Websitedaten mit Safari</a>
                                </p>
                                <p>
                                    <a class="adsimple-111729218" href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=111729218" target="_blank" rel="noopener noreferrer">Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf Ihrem Computer abgelegt haben</a>
                                </p>
                                <p>
                                    <a class="adsimple-111729218" href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=111729218" target="_blank" rel="noopener noreferrer">Internet Explorer: Löschen und Verwalten von Cookies</a>
                                </p>
                                <p>
                                    <a class="adsimple-111729218" href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=111729218" target="_blank" rel="noopener noreferrer">Microsoft Edge: Löschen und Verwalten von Cookies</a>
                                </p>
                                <p>Wenn Sie mehr über die Datenverarbeitung durch die „Sofortüberweisung“ des Unternehmens Sofort GmbH erfahren wollen, empfehlen wir Ihnen die Datenschutzerklärung unter <a class="adsimple-111729218" href="https://www.sofort.de/datenschutz.html?tid=111729218">https://www.sofort.de/datenschutz.html</a>.</p>
                                <h2 id="jquery-cdn-datenschutzerklaerung" class="adsimple-111729218">jQuery CDN Datenschutzerklärung</h2>
                                <p>Um Ihnen unsere Website bzw. all unsere einzelnen Unterseiten (Webseiten) auf unterschiedlichen Geräten schnell und problemlos auszuliefern, nutzen wir Dienste von jQuery CDN des Unternehmens jQuery Foundation. jQuery wird
                                    über das Content Delivery Network (CDN) des amerikanischen Software-Unternehmens StackPath (LCC 2012 McKinney Ave. Suite 1100, Dallas, TX 75201, USA) verteilt. Durch diesen Dienst werden personenbezogene Daten von Ihnen
                                    gespeichert, verwaltet und verarbeitet.</p>
                                <p>Ein Content Delivery Network (CDN) ist ein Netzwerk regional verteilter Server, die über das Internet miteinander verbunden sind. Durch dieses Netzwerk können Inhalte, speziell sehr große Dateien, auch bei großen Lastspitzen
                                    schnell ausgeliefert werden.
                                </p>
                                <p>jQuery nutzt JavaScript-Bibliotheken, um unsere Website-Inhalte zügig ausliefern zu können. Dafür lädt ein CDN-Server die nötigen Dateien. Sobald eine Verbindung zum CDN-Server aufgebaut ist, wird Ihre IP-Adresse erfasst
                                    und gespeichert. Das geschieht nur, wenn diese Daten nicht schon durch einen vergangenen Websitebesuch in Ihrem Browser gespeichert sind.</p>
                                <p>In den Datenschutz-Richtlinien von StackPath wird ausdrücklich erwähnt, dass StackPath aggregierte und anonymisierte Daten von diversen Diensten (wie eben auch jQuery) für die Erweiterung der Sicherheit und für eigene Dienste
                                    benutzen. Durch diese Daten können Sie als Person allerdings nicht identifiziert werden.</p>
                                <p>Wenn Sie nicht wollen, dass es zu dieser Datenübertragung kommt, haben Sie immer auch die Möglichkeit Java-Scriptblocker wie beispielsweise <a class="adsimple-111729218" href="https://www.ghostery.com/de/" target="_blank"
                                        rel="follow noopener noreferrer">ghostery.com</a> oder <a class="adsimple-111729218" href="https://noscript.net/" target="_blank" rel="follow noopener noreferrer">noscript.net</a> zu installieren. Sie können aber auch
                                    einfach in Ihrem Browser die Ausführung von JavaScript-Codes deaktivieren. Wenn Sie sich für die Deaktivierung von JavaScript-Codes entscheiden, verändern sich auch die gewohnten Funktionen. So wird beispielsweise eine
                                    Website nicht mehr so schnell geladen.</p>
                                <p>Bitte beachten Sie, dass bei der Verwendung dieses Tools Daten von Ihnen auch außerhalb der EU gespeichert und verarbeitet werden können. Die meisten Drittstaaten (darunter auch die USA) gelten nach derzeitigem europäischen
                                    Datenschutzrecht als nicht sicher. Daten an unsichere Drittstaaten dürfen also nicht einfach übertragen, dort gespeichert und verarbeitet werden, sofern es keine passenden Garantien (wie etwa EU-Standardvertragsklauseln)
                                    zwischen uns und dem außereuropäischen Dienstleister gibt.
                                </p>
                                <p>Mehr Informationen zum Datenschutz bei StackPath finden Sie unter <a class="adsimple-111729218" href="https://www.stackpath.com/legal/privacy-statement" target="_blank" rel="noopener noreferrer">https://www.stackpath.com/legal/privacy-statement/</a>                                    und zu jQuery unter <a class="adsimple-111729218" href="https://openjsf.org/wp-content/uploads/sites/84/2019/11/OpenJS-Foundation-Privacy-Policy-2019-11-15.pdf" target="_blank" rel="follow noopener noreferrer">https://openjsf.org/wp-content/uploads/sites/84/2019/11/OpenJS-Foundation-Privacy-Policy-2019-11-15.pdf</a>.</p>
                                <h2 id="bootstrapcdn-datenschutzerklaerung" class="adsimple-111729218">BootstrapCDN Datenschutzerklärung</h2>
                                <p>Um Ihnen all unsere einzelnen Webseiten (Unterseiten unserer Website) auf allen Geräten schnell und sicher ausliefern zu können, nutzen wir das Content Delivery Network (CDN) BootstrapCDN des amerikanischen Software-Unternehmens
                                    StackPath, LLC 2012 McKinney Ave. Suite 1100, Dallas, TX 75201, USA.</p>
                                <p>Ein Content Delivery Network (CDN) ist ein Netzwerk regional verteilter Server, die über das Internet miteinander verbunden sind. Durch dieses Netzwerk können Inhalte, speziell sehr große Dateien, auch bei großen Lastspitzen
                                    schnell ausgeliefert werden.
                                </p>
                                <p>BootstrapCDN funktioniert so, dass sogenannte JavaScript-Bibliotheken an Ihren Browser ausgeliefert werden. Lädt nun Ihr Browser eine Datei vom BootstrapCDN herunter, wird Ihre IP-Adresse während der Verbindung zum Bootstrap-CDN-Server
                                    an das Unternehmen StockPath übermittelt.</p>
                                <p>StackPath erwähnt auch in der hauseigenen Datenschutzerklärung, dass das Unternehmen aggregierte und anonymisierte Daten von diversen Diensten (wie BootstrapCDN) für die Erweiterung der Sicherung und für andere StackPath-Dienste
                                    und Clients verwenden. All diese Daten können aber keine Person identifizieren.</p>
                                <p>Wenn Sie diese Datenübertragung unterbinden wollen, können Sie einen JavaScript-Blocker (siehe beispielsweise <a class="adsimple-111729218" href="https://noscript.net/" target="_blank" rel="noopener noreferrer">https://noscript.net/</a>)
                                    installieren oder in Ihrem Browser die Ausführung von JavaScript-Codes deaktivieren. Bitte beachten Sie aber, dass dadurch die Website nicht mehr das gewohnte Service (wie etwa schnelle Ladegeschwindigkeit) bieten kann.</p>
                                <p> </p>
                                <p>Bitte beachten Sie, dass bei der Verwendung dieses Tools Daten von Ihnen auch außerhalb der EU gespeichert und verarbeitet werden können. Die meisten Drittstaaten (darunter auch die USA) gelten nach derzeitigem europäischen
                                    Datenschutzrecht als nicht sicher. Daten an unsichere Drittstaaten dürfen also nicht einfach übertragen, dort gespeichert und verarbeitet werden, sofern es keine passenden Garantien (wie etwa EU-Standardvertragsklauseln)
                                    zwischen uns und dem außereuropäischen Dienstleister gibt.
                                </p>
                                <p>Mehr Informationen zum Datenschutz bei StackPath bzw. BootstrapCDN finden Sie auf <a class="adsimple-111729218" href="https://www.bootstrapcdn.com/privacy-policy/?tid=111729218" target="_blank" rel="noopener noreferrer">https://www.bootstrapcdn.com/privacy-policy/</a>.</p>
                                Alle Texte sind urheberrechtlich geschützt.
                                <p style="margin-top:15px">Quelle: Erstellt mit dem <a href="https://www.adsimple.at/datenschutz-generator/" title="Datenschutz Generator von AdSimple">Datenschutz Generator</a> von AdSimple</p>