<!-- <div class="row">
    <div class="col-md-6 offset-md-3 mt-4">
        <h3>Register New Employer</h3>
        <form #f="ngForm" (ngSubmit)="onSubmit(f)">
            <div class="form-group">
                <label for="registerUsername">Name des Lokales</label>
                <input type="text" name="name" ngModel required #username="ngModel" class="form-control" id="registerUsername" placeholder="Name">
            </div>
            <div class="form-group">
                <label for="registerEmail">Email</label>
                <input type="email" name="email" ngModel required #email="ngModel" class="form-control" id="registerEmail" placeholder="E-Mail">
            </div>
            <div class="form-group">
                <label for="registerTelefon">Telefon</label>
                <input type="number" name="telefon" ngModel required #telefon="ngModel" class="form-control" id="registerTelefon" placeholder="Gib deine Telefonnumer ein">
            </div>
            <div class="form-group">
                <label for="registerPassword">Passwort</label>
                <input type="password" name="password" ngModel required #password="ngModel" class="form-control" id="registerPassword" placeholder="Passwort">
            </div>
            <div class="form-group">
                <label for="registerStandort">Kategorie</label>
                <select name="kategorie" id="registerKategorie" ngModel #kategorie="ngModel" class="browser-default custom-select form-control">
                    <option *ngFor="let kategorie of kategories"  [ngValue]="kategorie.value">{{kategorie.viewValue}}</option>
                </select>
            </div>
            <div class="form-group">
                <label for="registerStandort">Standort</label>


                <input type="text" name="standort" ngModel required #standort="ngModel" class="form-control" id="registerStandort" [options]='options' placeholder="Standort" ngx-google-places-autocomplete (onAddressChange)="AddressChange($event)"> {{
                formattedaddress }}
            </div>
            <div class="form-group">
                <label for="registerPreis">Preis</label>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="preis" id="inlineRadio1" value="€" (click)="preis('€')">
                    <label class="form-check-label" for="inlineRadio1">€</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="preis" id="inlineRadio2" value="€€" (click)="preis('€€')">
                    <label class="form-check-label" for="inlineRadio2">€€</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="preis" id="inlineRadio3" value="€€€" (click)="preis('€€€')">
                    <label class="form-check-label" for="inlineRadio3">€€€</label>
                </div>
            </div>
            <div class="form-group">
                <label for="registerAGB">AGB</label>
                <input type="checkbox" name="agb" ngModel required #agb="ngModel" class="form-control" id="registerAGB" placeholder="AGB">
            </div>

            <button type="submit" [disabled]="f.invalid" class="btn btn-primary">Register <i class="fas fa-sign-in-alt"></i></button>
        </form>
    </div>
</div> -->

<!-- AIzaSyCJCHsseZdcfn7VG57fiPhUzmiA16hVk_8-->


<div class="container col-md-6" id="register" style="margin-bottom: 5rem;">
    <div class="d-flex d-md-flex flex-column justify-content-center align-items-center align-content-center align-items-md-center">
        <h3>Gleich geht’s los!</h3>
        <h4 id="restaurant">Restaurant registrieren</h4>
    </div>
    <form #f="ngForm" (ngSubmit)="onSubmit(f)" class="row register" style="padding-bottom: 30px;">
        <div class="form-group col-md-6 book"><input class="form-control book is-valid" type="text" name="name" ngModel required #username="ngModel" class="form-control" id="registerUsername" placeholder="Name des Lokals"></div>
        <div class="form-group col-md-6 book"><input class="form-control book is-valid" type="email" name="email" ngModel required #email="ngModel" class="form-control" id="registerEmail" placeholder="E-Mail Adresse"></div>
        <div class="form-group col-md-6 book"><input class="form-control book is-valid" type="text" name="standort" ngModel required #standort="ngModel" class="form-control" id="registerStandort" [options]='options' placeholder="Standort" ngx-google-places-autocomplete (onAddressChange)="AddressChange($event)">            {{ formattedaddress }}</div>
        <div class="form-group col-md-6 book"><input class="form-control book is-valid" type="number" name="telefon" ngModel required #telefon="ngModel" class="form-control" id="registerTelefon" placeholder="Telefonnummer"></div>
        <div class="form-group col-md-6 book"><input class="form-control is-valid book" type="password" name="password" ngModel required #password="ngModel" class="form-control" id="registerPassword" placeholder="Passwort"></div>
        <div class="form-group col-md-6 book"><small>Das Passwort muss min. 8 Zeichen lang sein</small></div>
        <div>
            <p class="Hinweis">Alle weiteren Informationen (z.B. Öffnungszeiten) kannst du im nächsten Schritt in deinem Profil eingeben!</p>
        </div><button class="btn btn-primary text-center reservation col-md-12" [disabled]="f.invalid" type="submit">Registrieren</button>
    </form>
</div>