<div style="margin-top: 8rem;" class="container">
    <div class="container reserv">
        <h3>Deine Reservierung</h3>
        <p class="text-center">Gib einfach deine Reservierungs ID und Telefonnummer ein – schon siehst du deine Reservierung!</p>
    </div>
    <div class="container col-md-6" style="margin-top: 8rem;" id="bookingform">
        <form class="row" #f="ngForm" (ngSubmit)="onSubmit(f)">
            <div class="form-row">
                <div class="col-lg-4 col-md-6 form-group">
                    <input type="number" name="resid" class="form-control" required ngModel id="resid" placeholder="Reservierungs ID">
                </div>
                <div class="col-autoform-group">
                    <div class="btn-group" dropdown>
                        <button id="button-basic" dropdownToggle type="button" class="btn btn-primary dropdown-toggle" aria-controls="dropdown-basic">
                              {{ selectedOption }} <span class="caret"></span>
                    </button>

                        <ul id="dropdown-basic" class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                            <li role="menuitem" *ngFor="let option of options" (click)="changeSelectedOption(option)">
                                <span class="dropdown-item"> 
                            {{ option }} 
                        </span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-5 col-md-5 form-group">
                    <input type="number" class="form-control" required ngModel name="telefon" id="telefon" placeholder="Telefonnummer">
                </div>

                <div class="col-lg-11 col-md-11 form-group">
                    <button type="submit" [disabled]="f.invalid" class="btn btn-primary float-right mt-3" style="margin-bottom: 10rem;">Suchen</button>
                </div>
            </div>


        </form>
    </div>
</div>



<div class="container reservationcard" *ngIf="this.authService.foundres" style="margin-bottom: 5rem;">
    <div class="row">
        <div class="col-md-12 resID"><span class="reservationid">Reservierung ID:&nbsp; {{this.resid}}</span></div>
    </div>
    <div class="row reserv">
        <div class="col">
            <h6>Name des Gastes</h6>
            <p class="dates">{{this.authService.valuesres ? this.authService.valuesres.message.name : "" }}</p>
        </div>
        <div class="col-md-4">
            <h6>Personen</h6>
            <p class="dates">{{this.authService.valuesres ? this.authService.valuesres.message.personen : "" }}</p>
        </div>
        <div class="col-md-4">
            <h6>Datum/Uhrzeit</h6>
            <p class="dates">{{this.authService.valuesres ? this.authService.valuesres.message.datum : ""}} {{this.authService.valuesres ? this.authService.valuesres.message.zeit : "" }}</p>
        </div>
    </div>
    <div class="row reserv">
        <div class="col">
            <h6>Name des Wirts</h6>
            <p class="dates">{{this.authService.valuesres ? this.authService.valuesres.message.namewirt : "" }}</p>
        </div>
        <div class="col col-md-4">
            <h6>Telefonnummer</h6>
            <p class="dates">{{this.authService.valuesres ? this.authService.valuesres.message.telefonwirt : "" }}</p>
        </div>
        <div class="col col-md-4">
            <h6>Standort</h6>
            <p class="dates">{{this.authService.valuesres ? this.authService.valuesres.message.standort : "" }}</p>
        </div>
    </div>
    <div class="row reserv">
        <div class="col">
            <h6>Anmerkungen</h6>
            <p class="dates">{{this.authService.valuesres ? this.authService.valuesres.message.bemerkung : "" }}</p>
        </div>
        <div class="col-md-4">
            <h6>Status</h6>
            <button class="btn btn-primary col-md-12 ablehnen" type="button" *ngIf="this.authService.foundres" class="btn btn-primary">{{status[this.authService.valuesres ? this.authService.valuesres.message.status : '0']}}</button>
            <button class="btn btn-primary col-md-12" type="button" (click)="stornieren()" *ngIf="this.authService.buttonstorno" class="btn btn-primary">Stornieren</button>
        </div>

    </div>
</div>