import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AnleitungComponent } from './pages/anleitung/anleitung.component';
import { DatenschutzComponent } from './pages/datenschutz/datenschutz.component';
import { HomeComponent } from './pages/home/home.component';
import { ImpressumComponent } from './pages/impressum/impressum.component';
import { OverviewComponent } from './pages/overview/overview.component';
import { ReservierungKComponent } from './pages/reservierung-k/reservierung-k.component';
import { ReservierungbearbeitenComponent } from './pages/reservierungbearbeiten/reservierungbearbeiten.component';

const routes: Routes = [
  { path: '', component: HomeComponent},
  { path: 'ergebnis/:params', component: OverviewComponent},
  { path: 'reserkunde/:id', component: ReservierungKComponent},
  { path: 'resbearbeiten', component: ReservierungbearbeitenComponent},
  { path: 'impressum', component: ImpressumComponent},
  { path: 'datenschutz', component: DatenschutzComponent},
  { path: 'anleitung', component: AnleitungComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
