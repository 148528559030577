import { Component, OnInit, NgModule, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ProgressBarService } from 'src/app/shared/services/progress-bar.service';
import { NgForm } from '@angular/forms';
import { Options } from 'ngx-google-places-autocomplete/objects/options/options';
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';

import Swal from 'sweetalert2';
import { style } from '@angular/animations';






@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  checked: boolean | undefined;
  showSuccess: boolean | undefined;
  constructor(public authService: AuthService, private router: Router, public progressBar: ProgressBarService, public route: ActivatedRoute, ) { }

  public payPalConfig ? : IPayPalConfig;
  //Local Variable defined 
  formattedaddress = "";
  addresslat = "";
  addresslng = "";

  aktiv:number = 0;



  

  datasave!: SendData;

  kategories: Kategorie[] = [
    { value: '0', viewValue: '' },
    { value: '1', viewValue: 'Österreichische Küche' },
    { value: '2', viewValue: 'Italienisch' },
    { value: '3', viewValue: 'Bar' },
    { value: '4', viewValue: 'Pizza' },
    { value: '5', viewValue: 'Fast Food' },
    { value: '6', viewValue: 'Pasta' },
    { value: '7', viewValue: 'Asiatisch' },
    { value: '8', viewValue: 'Cafe' },
    { value: '9', viewValue: 'Burger' },
    { value: '10', viewValue: 'Mexikanisch' },
  ];


  preise = [
    { value: "€" },
    { value: "€€" },
    { value: "€€€" }
  ];
  zahlungs: Zahlung[] = [
    { value: '0', viewValue: 'Bar' },
    { value: '1', viewValue: 'Bar u. Karte' },
    { value: '2', viewValue: 'Bar u. Karte u. Bitcoin' }
  ];

  zeitOnChange : string[] = [];






  public AddressChange(address: any) {
    //setting address from API to local variable 
    this.formattedaddress = address.formatted_address
    this.addresslat = address.geometry.location.lat();
    this.addresslng = address.geometry.location.lng();
   //console.log(address.geometry.location.lat());
   //console.log(address.geometry.location.lng());
  }





public onChangeEventBetrag(){
 console.log(this.authService.betrag);
  //this.initConfig();
}















  tage: tag[] = [
    { value: 'Montag', valuevon: '10:00', valuebis: '20:00' },
    { value: 'Dienstag', valuevon: '10:00', valuebis: '20:00' },
    { value: 'Mittwoch', valuevon: '10:00', valuebis: '20:00' },
    { value: 'Donnerstag', valuevon: '10:00', valuebis: '20:00' },
    { value: 'Freitag', valuevon: '10:00', valuebis: '20:00' },
    { value: 'Samstag', valuevon: '10:00', valuebis: '20:00' },
    { value: 'Sonntag', valuevon: '10:00', valuebis: '20:00' },
  ];
  options: Options = {
    types: [],
    componentRestrictions: { country: 'AT' },
    bounds: null as any,
    fields: null as any,
    strictBounds: false,
    origin: null as any
  };


  

  ngOnInit(): void {
    if (!this.authService.loggedIn()) {
      this.router.navigate(['']);
    }
    this.initConfig();
    
    this.progressBar.startLoading();
    /* let valueObserver = {
      next: () => {
        console.log('Service Ok');
        this.progressBar.setSuccess();
        this.progressBar.completeLoading();
        this.router.navigate(['/profile']);
    },
      error: (err: any) => {
        console.log(err);
        this.progressBar.completeLoading();
      }
    }; */
    let valueObserver = this.authService.getValues();



    if (valueObserver) {
      this.progressBar.setSuccess();
      this.progressBar.completeLoading();
      this.showSuccess = true;
      
    } else {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Leider ist ein Fehler aufgetreten',
        showConfirmButton: false,
        timer: 1500
      })
    }
    
  }

  onSubmit(f: NgForm) {
    this.progressBar.startLoading();
    const dataObserver = {
      next: () => {
        this.progressBar.setSuccess();
        this.progressBar.completeLoading();
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Speichern war erfolgreich!',
          showConfirmButton: false,
          timer: 1500
        })
        //this.ngOnInit();
        window.location.reload();
      },
      error: (err: any) => {
        //console.log(err);
        this.progressBar.completeLoading();
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Leider ist ein Fehler aufgetreten',
          showConfirmButton: false,
          timer: 1500
        })
      }
    };


    this.datasave = f.value;
    this.datasave.standort = this.formattedaddress;
    this.datasave.lat = this.addresslat;
    this.datasave.lng = this.addresslng;
    if (!this.datasave.kategorie) {
      this.datasave.kategorie = this.authService.values?.message.kategorie;
    }
    if (!this.datasave.kategorie1) {
      this.datasave.kategorie1 = this.authService.values?.message.kategorie1;
    }
    if (!this.datasave.name) {
      this.datasave.name = this.authService.values?.message.name;
    }
    if (!this.datasave.preis) {
      this.datasave.preis = this.authService.values?.message.preis as string | undefined;
    }
    if (!this.datasave.zahlung) {
      this.datasave.zahlung = this.authService.values?.message.zahlung;
    }
    if (!this.datasave.standort) {
      this.datasave.standort = this.authService.values?.message.standort;
      this.datasave.lat = this.authService.values?.message.lat;
      this.datasave.lng = this.authService.values?.message.lng;
      
    }
    if (!this.datasave.telefon) {
      this.datasave.telefon = this.authService.values?.message.telefon;
    }

    if (!this.datasave.beschreib) {
      this.datasave.beschreib = this.authService.values?.message.beschreib;
    }
    if (this.datasave.kategorie || this.datasave.name || this.datasave.preis || this.datasave.standort || this.datasave.telefon) {
      this.authService.saveData(this.datasave).subscribe(dataObserver);
    }


  }

  onSubmitzeit(z: NgForm) {
    this.progressBar.startLoading();
    const timeObserver = {
      next: () => {
        this.progressBar.setSuccess();
        this.progressBar.completeLoading();
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Speichern war erfolgreich!',
          showConfirmButton: false,
          timer: 1500
        })
        //this.ngOnInit();
        window.location.reload();
      },
      error: (err: any) => {
       //console.log(err);
        this.progressBar.completeLoading();
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Leider ist ein Fehler aufgetreten',
          showConfirmButton: false,
          timer: 1500
        })
      }
    };
    this.authService.saveTime(this.timetostring(z.value)).subscribe(timeObserver);

  }



  timetostring(zeiten: any) {

    let timestring = zeiten['montagvon'] + ';' + zeiten['montagbis'] + ';' + zeiten['montagvon2'] + ';' + zeiten['montagbis2'] + ';'
      + zeiten['dienstagvon'] + ';' + zeiten['dienstagbis'] + ';' + zeiten['dienstagvon2'] + ';' + zeiten['dienstagbis2'] + ';'
      + zeiten['mittwochvon'] + ';' + zeiten['mittwochbis'] + ';' + zeiten['mittwochvon2'] + ';' + zeiten['mittwochbis2'] + ';'
      + zeiten['donnerstagvon'] + ';' + zeiten['donnerstagbis'] + ';' + zeiten['donnerstagvon2'] + ';' + zeiten['donnerstagbis2'] + ';'
      + zeiten['freitagvon'] + ';' + zeiten['freitagbis'] + ';' + zeiten['freitagvon2'] + ';' + zeiten['freitagbis2'] + ';'
      + zeiten['samstagvon'] + ';' + zeiten['samstagbis'] + ';' + zeiten['samstagvon2'] + ';' + zeiten['samstagbis2'] + ';'
      + zeiten['sonntagvon'] + ';' + zeiten['sonntagbis'] + ';' + zeiten['sonntagvon2'] + ';' + zeiten['sonntagbis2'] + ';';
    let timearray: { [key: string]: string } = { zeiten: timestring };

    const timestringarray: string[] = timearray.zeiten.split(";"); 
    let i = 0;
    timestringarray.forEach(element =>{
      if(!this.zeitOnChange.includes(i.toString())){
        timestringarray[i] = this.authService.splitted[i];
      }
      i = i + 1;
    });
    let stringarray = timestringarray.toString();
    let returnarray :  { [key: string]: string} = { zeiten: stringarray};

    return returnarray;
  }
  filedata: any;
  /* File onchange event */
  fileEvent(e: any) {
    this.filedata = e.target.files[0];
  }
  /* Upload button functioanlity */
  onSubmitform(upload: NgForm) {
    this.authService.imageupload(upload, this.filedata);
  }
  /* Upload button functioanlity */
  onSubmitformspeise(upload: NgForm) {
    this.authService.speiseupload(upload, this.filedata);
  }
  zeitchange(zeit: string) {
    this.zeitOnChange.push(zeit);
    
  }

  changestatus(){
    this.progressBar.startLoading();
    if(this.authService.values?.message.confirmemail === "1"){
      this.aktiv = 2;
    }else if(this.authService.values?.message.confirmemail === "2"){
      this.aktiv = 1;
    }else {
      this.aktiv = 0;
    }
    const statusObserver = {
      next: () => {
        this.progressBar.setSuccess();
        this.progressBar.completeLoading();
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Speichern war erfolgreich!',
          showConfirmButton: false,
          timer: 1500
        })
        //this.ngOnInit();
        window.location.reload();
      },
      error: (err: any) => {
       //console.log(err);
        this.progressBar.completeLoading();
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Leider ist ein Fehler aufgetreten',
          showConfirmButton: false,
          timer: 1500
        })
      }
    };
    this.authService.saveStatus({'aktiv': this.aktiv}).subscribe(statusObserver);;
  }

  sharespeise(){

    this.router.navigate(['/speisekarte', this.authService.values?.message.id]);
  }





  private initConfig(): void {
    this.payPalConfig = {
    currency: 'EUR',
    //clientId: 'AYRXQ8P4h8XR-SE9kUp0IP-tNx7l8WDnNI8eIJKE2bY_ISoyIZj1Fq05RhsaIoTMcxE8DkveL3N7Ud4-',
    clientId: 'ATnod2ZADRwTxvG89MczbD2XhP_XB_UubOJVDsENXcqsIRL-6VT36YhwHlNEzcSCR9GDEOnE5L528hH_',
    createOrderOnClient: (data) => <ICreateOrderRequest>{
      intent: 'CAPTURE',
      purchase_units: [
        {
          amount: {
            currency_code: 'EUR',
            value: String(this.authService.betrag),
            breakdown: {
              item_total: {
                  currency_code: 'EUR',
                  value: String(this.authService.betrag)
              }
            }
          },
          items: [{
            name: 'Reservierungs Vermittlung',
            quantity: '1',
            category: 'DIGITAL_GOODS',
            unit_amount: {
                currency_code: 'EUR',
                value: String(this.authService.betrag),
            },
        }]
        }
      ]
    },
    advanced: {
      commit: 'true'
    },
    style: {
      label: 'paypal',
      layout: 'vertical'
      
    },
    onApprove: (data, actions) => {
     //console.log('onApprove - transaction was approved, but not authorized', data, actions);
      actions.order.get().then((details: any) => {
       //console.log('onApprove - you can get full order details inside onApprove: ', details);
      });
    },
    onClientAuthorization: (data) => {
     //console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
      const donationObserver = {
        next: () => {
          this.progressBar.setSuccess();
          this.progressBar.completeLoading();
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Spende war erfolgreich!',
            showConfirmButton: false,
            timer: 1500
          })
          //this.ngOnInit();
          window.location.reload();
        },
        error: (err: any) => {
         //console.log(err);
          this.progressBar.completeLoading();
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Leider ist ein Fehler aufgetreten',
            showConfirmButton: false,
            timer: 1500
          })
        }
      };
      this.authService.saveDonation({'betrag': this.authService.betrag}).subscribe(donationObserver);;
      this.showSuccess = true;
    },
    onCancel: (data, actions) => {
     //console.log('OnCancel', data, actions);
    },
    onError: err => {
     //console.log('OnError', err);
    },
    onClick: (data, actions) => {
     //console.log('onClick', data, actions);
    },
  };
  }
 
}





export interface tag {
  value: string;
  valuevon: string;
  valuebis: string;
}


interface Kategorie {
  value: string;
  viewValue: string;
}

interface Zahlung {
  value: string;
  viewValue: string;
}


export interface SendData {

  name: string | undefined;
  telefon: string | undefined;
  standort: string | undefined;
  lat: string | undefined;
  lng: string | undefined;
  preis: string | undefined;
  kategorie: string | undefined;
  kategorie1: string | undefined;
  beschreib: string | undefined;
  zahlung: string | undefined;
}

