<!-- <div class="container">
    <div class="main-body">



        <div class="row" style="margin-top: 10rem;">
            <div class="col">
                <div class="card mb-3">
                    <div class="card-body">
                        <form #f="ngForm" (ngSubmit)="onSubmit(f)">
                            <div class="form-group">
                                <label for="profileUsername">Name des Lokales</label>
                                <input type="text" name="name" ngModel #username="ngModel" class="form-control" id="profileUsername" value=" {{this.authService.values ? this.authService.values.message.name : '' }} ">
                            </div>
                            <div class="form-group">
                                <label for="profileTelefon">Telefon</label>
                                <input type="text" name="telefon" ngModel #telefon="ngModel" class="form-control" id="profileTelefon" value=" {{this.authService.values ? this.authService.values.message.telefon : '' }} ">
                            </div>
                            <div class="form-row">
                                <div class="form-group">
                                    <label for="profileKategorie">Kategorie</label>
                                    <select name="kategorie" id="profileKategorie" ngModel #kategorie="ngModel" class="browser-default custom-select form-control">
                                    <option value="" selected>{{ kategories[this.authService.values ? this.authService.values.message.kategorie : 1].viewValue }}</option>
                                    <option *ngFor="let kategorie of kategories"  [ngValue]="kategorie.value">{{kategorie.viewValue}}</option>
                                </select>

                                </div>
                                <div class="form-group">
                                    <label for="profileKategorie1">Kategorie1</label>
                                    <select name="kategorie1" id="profileKategorie1" ngModel #kategorie1="ngModel" class="browser-default custom-select form-control">
                                    <option value="" selected>{{ kategories[this.authService.values ? this.authService.values.message.kategorie1 : 1].viewValue }}</option>
                                    <option *ngFor="let kategorie of kategories"  [ngValue]="kategorie.value">{{kategorie.viewValue}}</option>
                                </select>

                                </div>
                            </div>
                            <div class="form-group">
                                <label for="profileStandort">Standort</label>

                               
                                <input type="text" name="standort" ngModel #standort="ngModel" class="form-control" id="profileStandort" [options]='options' value=" {{this.authService.values ? this.authService.values.message.standort : '' }} " ngx-google-places-autocomplete (onAddressChange)="AddressChange($event)">                                {{ formattedaddress }}
                            </div>
                            <div class="form-group">
                                <label for="profilePreis">Preis</label>
                                <select name="preis" id="profilePreis" ngModel #preis="ngModel" class="browser-default custom-select form-control">
                                    <option value="" selected>{{this.authService.values ? this.authService.values.message.preis : '' }}</option>
                                    <option *ngFor="let preis of preise"  [ngValue]="preis.value">{{preis.value}}</option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label for="profileZahlung">Zahlungsarten</label>
                                <select name="zahlung" id="profileZahlung" ngModel #zahlung="ngModel" class="browser-default custom-select form-control">
                                    <option value="" selected>{{zahlungs[this.authService.values ? this.authService.values.message.zahlung : 1].viewValue }}</option>
                                    <option *ngFor="let zahlung of zahlungs"  [ngValue]="zahlung.value">{{zahlung.viewValue}}</option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label for="profileBeschreib">Beschreibung für Übersicht</label>
                                <input type="text" name="beschreib" ngModel #telefon="ngModel" class="form-control" id="profileBeschreib" value=" {{this.authService.values ? this.authService.values.message.beschreib : '' }} ">
                            </div>
                            <button type="submit" class="btn btn-primary">Speichern <i class="fas fa-sign-in-alt"></i></button>
                        </form>
                        <br>

                        <br>
                        <div class="row">
                            <div class="col-sm-5">
                                <button type="submit" class="btn btn-primary" [routerLink]="['/userchange-password']" routerLinkActive="router-link-active">Passwort Ändern</button>
                            </div>
                            <div class="col-sm-2">
                                <h6 class="mb-0">Dabei seit</h6>
                            </div>
                            <div class="col-sm-5 text-secondary">
                                {{this.authService.values ? this.authService.values.message.since : ""}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>





</div> -->
<div class="container full" style="margin-top: 5rem;">
    <div class="row full">
        <div class="col-md-12 headprofile"><img src="assets/images/milk-tea-PeJWBQ5O6g8-unsplash.jpg"></div>
    </div>
</div>
<div class="container">
    <div class="row one">
        <div class="col col-md-8 data">
            <h3>Willkommen {{this.authService.values ? this.authService.values.message.name : ""}}</h3>
            <h4>Wichtige Funktionen</h4>
            <p>Bei Fragen oder Anregungen bitte eine E-Mail an office@locatetable.at oder unter der Telefonnummer 0664 252 60 92</p>
            <label for="shareLink">Dieser Link führt zu der Reservierung in Ihrem Lokal. <strong>Perfekt zum Teilen.</strong></label><input class="form-control book" type="text" name="shareLink" ngModel #shareLink="ngModel" id="shareLink" value="https://www.locatetable.at/reserkunde/{{this.authService.values ? this.authService.values.message.id : '' }}">

            <button class="btn btn-primary data col-4 " (click)="changestatus()" type="button">{{ this.authService.values ? this.authService.values.message.status : "" }}</button>
            <button class="btn btn-primary data col-4 offset-4" type="button" [routerLink]="['/userchange-password']" routerLinkActive="router-link-active">Passwort Ändern</button>

        </div>
    </div>
    <div class="row one">
        <div class="col col-md-8 data">
            <h4>Daten deines Restaurants - ID: {{this.authService.values ? this.authService.values.message.id : ""}}</h4>
            <form #f="ngForm" (ngSubmit)="onSubmit(f)" class="row">
                <div class="form-group col-md-12 book"><label>Name des Restaurants</label><input class="form-control book" type="text" name="name" ngModel #username="ngModel" id="profileUsername" value=" {{this.authService.values ? this.authService.values.message.name : '' }} "></div>
                <!-- <div class="form-group col-md-6 book"><label>E-Mail</label><input class="form-control book" type="email"></div> -->
                <div class="form-group col-md-6 book"><label>Standort</label><input class="form-control book" type="text" name="standort" ngModel #standort="ngModel" id="profileStandort" [options]='options' value=" {{this.authService.values ? this.authService.values.message.standort : '' }} "
                        ngx-google-places-autocomplete (onAddressChange)="AddressChange($event)"></div>
                <div class="form-group col-md-6 book"><label>Telefonnummer</label><input class="form-control book" type="tel" name="telefon" ngModel #telefon="ngModel" id="profileTelefon" value=" {{this.authService.values ? this.authService.values.message.telefon : '' }} "></div>

                <div class="form-group col-md-6 book"><label>Kategorie 1</label>
                    <select name="kategorie" id="profileKategorie" ngModel #kategorie="ngModel" class="form-control book">
                            <option value="" selected>{{ kategories[this.authService.values ? this.authService.values.message.kategorie : 0].viewValue }}</option>
                            <option *ngFor="let kategorie of kategories"  [ngValue]="kategorie.value">{{kategorie.viewValue}}</option>
                    </select></div>
                <div class="form-group col-md-6 book"><label>Kategorie 2</label>
                    <select name="kategorie1" id="profileKategorie1" ngModel #kategorie1="ngModel" class="form-control book">
                        <option value="" selected>{{ kategories[this.authService.values ? this.authService.values.message.kategorie1 : 0].viewValue }}</option>
                        <option *ngFor="let kategorie of kategories"  [ngValue]="kategorie.value">{{kategorie.viewValue}}</option>
                </select></div>
                <div class="form-group col-md-6 book"><label>Preiskategorie</label>
                    <select name="preis" id="profilePreis" ngModel #preis="ngModel" class="form-control book">
                        <option value="" selected>{{this.authService.values ? this.authService.values.message.preis : '' }}</option>
                        <option *ngFor="let preis of preise"  [ngValue]="preis.value">{{preis.value}}</option>
                    </select></div>
                <div class="form-group col-md-6 book"><label>Zahlungsarten</label>
                    <select name="zahlung" id="profileZahlung" ngModel #zahlung="ngModel" class="form-control book">
                            <option value="" selected>{{zahlungs[this.authService.values ? this.authService.values.message.zahlung : 1].viewValue }}</option>
                            <option *ngFor="let zahlung of zahlungs"  [ngValue]="zahlung.value">{{zahlung.viewValue}}</option>
                        </select></div>



                <div class="form-group col-md-12 book"><label>Beschreibung</label><textarea class="form-control book" type="text" name="beschreib" ngModel #telefon="ngModel" class="form-control" id="profileBeschreib" value=" {{this.authService.values ? this.authService.values.message.beschreib : '' }} "></textarea></div>
                <button class="btn btn-primary data offset-1" type="submit" style="margin-top: 2rem;">Daten speichern</button>
            </form>
        </div>
        <div class="col"></div>
    </div>
    <div class="row one">
        <div class="col col-md-8 data">
            <h4>Über Unterstützung - freuen wir uns</h4>

            <div class="row mt-1">
                <p>
                    Pro Resverierung entstehen Kosten von 40Cent, da vor allem SMS Nachrichten kostenpflichtig sind. Zudem kommen Server Kosten etc. dazu. Mit einer Spende von 40Cent pro Reservierung würde es beim fortbestehen von <strong>LocateTable</strong>                    helfen.
                    <br>
                    <strong>Es ist natürlich auch jeder andere Betrag möglich. Jedoch min. 1€</strong></p>
            </div>
            <div class="row" *ngIf="this.authService.values">
                <div class="col-3">
                    <input type="number" class="form-control offset-1" ngModel (change)="onChangeEventBetrag()" [(ngModel)]="this.authService.betrag" name="betrag" id="betrag" placeholder="this.authService.betrag">
                </div>
                <div class="col-1">
                    <p>€</p>
                </div>
                <div class="col-8">
                    Reservierungen {{this.authService.values ? this.authService.values.message.rescount : ""}} seit: {{this.authService.values ? this.authService.values.message.letztespende : ""}}
                </div>
            </div>

            <div class="col" style="margin-top: 2rem;" *ngIf="this.showSuccess">
                <!-- <p style="color: red;">Achtung!!! Test System.</p> -->
                <ngx-paypal [config]="payPalConfig"></ngx-paypal>
            </div>
        </div>
    </div>
    <div class="row one">
        <div class="col col-md-8 data">
            <h4>Deine Dateien</h4><img class="pic col-md-12" src='{{ this.authService.values ? this.authService.values.message.vorschaubild : ""}}'>
            <form #upload="ngForm" (ngSubmit)="onSubmitform(upload)">
                <input type="file" name="myFile" class="rosa col-md-6" accept="image/*" (change)="fileEvent($event)" />
                <button class="btn btn-primary col-md-5 offset-1 " type="submit">Bild Hochladen</button>
            </form>
            <h6 class="mt-3">Speisekarte</h6><button class="btn btn-primary rosa col-md-6" type="button" [routerLink]="['/speisekarte', this.authService.values ? this.authService.values.message.id : '' ]" routerLinkActive="router-link-active">Speisekarte anzeigen</button>
            <p class="col-md-6 datei">{{ this.authService.values ? this.authService.values.message.speisekarte : '' }}</p>
            <h6>Neue Speisekarte hochladen</h6>
            <form #uploadspeise="ngForm" (ngSubmit)="onSubmitformspeise(upload)">
                <input type="file" name="myFile" accept="application/pdf" class=" rosa col-md-6" (change)="fileEvent($event)" />
                <button type="submit" class="btn btn-primary col-md-5 offset-1">Datei Hochladen</button>
            </form>
        </div>
    </div>
    <div class="row one" *ngIf="this.authService.splitted">
        <div class="col col-md-8 data end">
            <h4>Öffnungszeiten</h4>
            <form #z="ngForm" (ngSubmit)="onSubmitzeit(z)">
                <div class="form-group">
                    <label for="profileZeit">Montag von: </label>
                    <input type="time" name="montagvon" ngModel #username="ngModel" (change)="zeitchange('0')" class="" id="profileZeitMontagvon" value={{this.authService.splitted[0]}}>
                    <label for="profileZeit">bis: </label>
                    <input type="time" name="montagbis" ngModel #username="ngModel" (change)="zeitchange('1')" class="" id="profileMontagbis" value={{this.authService.splitted[1]}}>
                    <label for="profileZeit">von: </label>
                    <input type="time" name="montagvon2" ngModel #username="ngModel" (change)="zeitchange('2')" class="" id="profileMontagvon2" value={{this.authService.splitted[2]}}>
                    <label for="profileZeit">bis: </label>
                    <input type="time" name="montagbis2" ngModel #username="ngModel" (change)="zeitchange('3')" class="" id="profileMontagbis2" value={{this.authService.splitted[3]}}>
                </div>
                <div class="form-group">
                    <label for="profileZeit">Dienstag von:</label>
                    <input type="time" name="dienstagvon" ngModel #username="ngModel" (change)="zeitchange('4')" class="" id="profileZeitDienstagvon" value={{this.authService.splitted[4]}}>
                    <label for="profileZeit">bis: </label>
                    <input type="time" name="dienstagbis" ngModel #username="ngModel" (change)="zeitchange('5')" class="" id="profileDienstagbis" value={{this.authService.splitted[5]}}>
                    <label for="profileZeit">von: </label>
                    <input type="time" name="dienstagvon2" ngModel #username="ngModel" class="" (change)="zeitchange('6')" id="profileDienstagvon2" value={{this.authService.splitted[6]}}>
                    <label for="profileZeit">bis: </label>
                    <input type="time" name="dienstagbis2" ngModel #username="ngModel" class="" (change)="zeitchange('7')" id="profileDienstagbis2" value={{this.authService.splitted[7]}}>
                </div>
                <div class="form-group">
                    <label for="profileZeit">Mittwoch von:</label>
                    <input type="time" name="mittwochvon" ngModel #username="ngModel" class="" (change)="zeitchange('8')" id="profileZeitMittwochvon" value={{this.authService.splitted[8]}}>
                    <label for="profileZeit">bis: </label>
                    <input type="time" name="mittwochbis" ngModel #username="ngModel" class="" (change)="zeitchange('9')" id="profileMittwochbis" value={{this.authService.splitted[9]}}>
                    <label for="profileZeit">von: </label>
                    <input type="time" name="mittwochvon2" ngModel #username="ngModel" class="" (change)="zeitchange('10')" id="profileMittwochvon2" value={{this.authService.splitted[10]}}>
                    <label for="profileZeit">bis: </label>
                    <input type="time" name="mittwochbis2" ngModel #username="ngModel" class="" (change)="zeitchange('11')" id="profileMittwochbis2" value={{this.authService.splitted[11]}}>
                </div>
                <div class="form-group">
                    <label for="profileZeit">Donnerstag von:</label>
                    <input type="time" name="donnerstagvon" ngModel #username="ngModel" class="" (change)="zeitchange('12')" id="profileZeitDonnerstagvon" value={{this.authService.splitted[12]}}>
                    <label for="profileZeit">bis: </label>
                    <input type="time" name="donnerstagbis" ngModel #username="ngModel" class="" (change)="zeitchange('13')" id="profileDonnerstagbis" value={{this.authService.splitted[13]}}>
                    <label for="profileZeit">von: </label>
                    <input type="time" name="donnerstagvon2" ngModel #username="ngModel" class="" (change)="zeitchange('14')" id="profileDonnerstagvon2" value={{this.authService.splitted[14]}}>
                    <label for="profileZeit">bis: </label>
                    <input type="time" name="donnerstagbis2" ngModel #username="ngModel" class="" (change)="zeitchange('15')" id="profileDonnerstagbis2" value={{this.authService.splitted[15]}}>
                </div>
                <div class="form-group">
                    <label for="profileZeit">Freitag von:</label>
                    <input type="time" name="freitagvon" ngModel #username="ngModel" class="" (change)="zeitchange('16')" id="profileZeitFreitagvon" value={{this.authService.splitted[16]}}>
                    <label for="profileZeit">bis: </label>
                    <input type="time" name="freitagbis" ngModel #username="ngModel" class="" (change)="zeitchange('17')" id="profileFreitagbis" value={{this.authService.splitted[17]}}>
                    <label for="profileZeit">von: </label>
                    <input type="time" name="freitagvon2" ngModel #username="ngModel" class="" (change)="zeitchange('18')" id="profileFreitagvon2" value={{this.authService.splitted[18]}}>
                    <label for="profileZeit">bis: </label>
                    <input type="time" name="freitagbis2" ngModel #username="ngModel" class="" (change)="zeitchange('19')" id="profileFreitagbis2" value={{this.authService.splitted[19]}}>
                </div>
                <div class="form-group">
                    <label for="profileZeit">Samstag von:</label>
                    <input type="time" name="samstagvon" ngModel #username="ngModel" class="" (change)="zeitchange('20')" id="profileZeitSamstagvon" value={{this.authService.splitted[20]}}>
                    <label for="profileZeit">bis: </label>
                    <input type="time" name="samstagbis" ngModel #username="ngModel" class="" (change)="zeitchange('21')" id="profileSamstagbis" value={{this.authService.splitted[21]}}>
                    <label for="profileZeit">von: </label>
                    <input type="time" name="samstagvon2" ngModel #username="ngModel" class="" (change)="zeitchange('22')" id="profileSamstagvon2" value={{this.authService.splitted[22]}}>
                    <label for="profileZeit">bis: </label>
                    <input type="time" name="samstagbis2" ngModel #username="ngModel" class="" (change)="zeitchange('23')" id="profileSamstagbis2" value={{this.authService.splitted[23]}}>
                </div>
                <div class="form-group">
                    <label for="profileZeit">Sonntag von:</label>
                    <input type="time" name="sonntagvon" ngModel #username="ngModel" class="" (change)="zeitchange('24')" id="profileZeitSonntagvon" value={{this.authService.splitted[24]}}>
                    <label for="profileZeit">bis: </label>
                    <input type="time" name="sonntagbis" ngModel #username="ngModel" class="" (change)="zeitchange('25')" id="profileSonntagbis" value={{this.authService.splitted[25]}}>
                    <label for="profileZeit">von: </label>
                    <input type="time" name="sonntagvon2" ngModel #username="ngModel" class="" (change)="zeitchange('26')" id="profileSonntagvon2" value={{this.authService.splitted[26]}}>
                    <label for="profileZeit">bis: </label>
                    <input type="time" name="sonntagbis2" ngModel #username="ngModel" class="" (change)="zeitchange('27')" id="profileSonntagbis2" value={{this.authService.splitted[27]}}>
                </div>




                <button type="submitzeit" class="btn btn-primary">Speichern <i class="fas fa-sign-in-alt"></i></button>
            </form>
        </div>
    </div>
</div>