<ng-progress id="progressBar" [thick]="true"></ng-progress>
<!-- 
<nav ngxNavbarDynamicExpand class="navbar navbar-expand-lg navbar-light bg-light fixed-top">
    <a class="navbar-brand" href="">LocateTable</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav ml-auto">
            <li class="nav-item">
                <a class="nav-link" *ngIf="authService.loggedIn();">Du bist eingeloggt! Willkommen {{this.authService.decodeToken ? this.authService.decodeToken.data.user_name : ""}}</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [routerLink]="['profile']" routerLinkActive="active" *ngIf="authService.loggedIn();"><span class="material-icons" style="font-size: 36px;">
                    account_circle
                    </span></a>
            </li>

            
<li class="nav-item dropdown" dropdown>
    <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>
        <span class="material-icons" style="font-size: 36px;">
                        account_circle
                        </span></a>
    <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown dropdown-primary" role="menu">
        <a class="nav-link" [routerLink]="['login']" routerLinkActive="active" *ngIf="!authService.loggedIn();">Login</a>
        <a class="nav-link" (click)="logout();" *ngIf="authService.loggedIn();">Logout</a>
        <a class="nav-link" [routerLink]="['register']" routerLinkActive="active" *ngIf="!authService.loggedIn();">Register</a>
        <a class="nav-link" [routerLink]="['reset-password']" routerLinkActive="active" *ngIf="!authService.loggedIn();">Reset Password</a>
    </div>


    </ul>
    </div>
    </nav>
    -->
<!--Navbar-->
<header class="bg-primary text-black text-center">


    <mdb-navbar *ngIf="this.router.url !== '/'" SideClass=" navbar fixed-top navbar-expand-lg  scrolling-navbar intro-fixed-nav py-2" [containerInside]="false">


        <mdb-navbar-brand>
            <a class="navbar-brand" href="">
                <img *ngIf="!this.OnTop || this.router.url !== '/'" width="147" height="60" alt="" loading="lazy" src="assets/images/Locatetable_LOGO_RGB.svg" alt=""></a>
        </mdb-navbar-brand>



        <ul class="navbar-nav ml-auto">


            <li class="nav-item dropdown" dropdown>
                <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>
                    <mdb-icon class="fa-lg" fas icon="user"></mdb-icon><span class="caret"></span></a>
                <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown dropdown-primary" role="menu">
                    <a class="dropdown-item waves-light" [routerLink]="['profile']" routerLinkActive="active" *ngIf="authService.loggedIn();">Profil</a>
                    <a class="dropdown-item waves-light" [routerLink]="['login']" routerLinkActive="active" *ngIf="!authService.loggedIn();">Login</a>
                    <a class="dropdown-item waves-light" (click)="logout();" *ngIf="authService.loggedIn();">Logout</a>
                    <!-- <a class="dropdown-item waves-light" [routerLink]="['register']" routerLinkActive="active" *ngIf="!authService.loggedIn();">Registrieren</a> -->
                    <a class="dropdown-item waves-light" [routerLink]="['reset-password']" routerLinkActive="active" *ngIf="!authService.loggedIn();">Reset Passwort</a>
                </div>

            </li>


        </ul>


    </mdb-navbar>
    <mdb-navbar *ngIf="this.router.url === '/'" SideClass=" navbar navbarmain fixed-top navbar-expand-lg  scrolling-navbar intro-fixed-nav py-2" [containerInside]="false">


        <mdb-navbar-brand>
            <a class="navbar-brand" href=""><img *ngIf="this.OnTop && this.router.url === '/'" width="147" height="90" alt="" loading="lazy" src="assets/images/Locatetable_LOGO_white_RGB.svg" alt="">
                <img *ngIf="!this.OnTop || this.router.url !== '/'" width="147" height="70" alt="" loading="lazy" src="assets/images/Locatetable_LOGO_RGB.svg" alt=""></a>
        </mdb-navbar-brand>



        <ul class="navbar-nav ml-auto">


            <li class="nav-item dropdown" dropdown>
                <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>
                    <mdb-icon class="fa-lg" fas icon="user"></mdb-icon><span class="caret"></span></a>
                <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown dropdown-primary" role="menu">
                    <a class="dropdown-item waves-light" [routerLink]="['profile']" routerLinkActive="active" *ngIf="authService.loggedIn();">Profil</a>
                    <a class="dropdown-item waves-light" [routerLink]="['login']" routerLinkActive="active" *ngIf="!authService.loggedIn();">Login</a>
                    <a class="dropdown-item waves-light" (click)="logout();" *ngIf="authService.loggedIn();">Logout</a>
                    <!-- <a class="dropdown-item waves-light" [routerLink]="['register']" routerLinkActive="active" *ngIf="!authService.loggedIn();">Registrieren</a> -->
                    <a class="dropdown-item waves-light" [routerLink]="['reset-password']" routerLinkActive="active" *ngIf="!authService.loggedIn();">Reset Passwort</a>
                </div>

            </li>


        </ul>


    </mdb-navbar>
</header>
<!--/.Navbar-->


<!-- <section id="hero">
    <nav class="navbar navbar-dark navbar-expand-md fixed-top active">
        <div class="container-fluid">
            <a class="navbar-brand" href="#" style="background: url(&quot;assets/img/Locatetable_LOGO_white_RGB.svg&quot;) left / 100% no-repeat;width: 147px;height: 90px;padding-top: 5px;"></a>
            <div class="dropdown" style="border-bottom-style: none;"><a class="dropdown-toggle" aria-expanded="false" data-toggle="dropdown" href="#" style="color: rgb(255,255,255);"><i class="fa fa-user" style="color: rgb(255,255,255);font-size: 26px;padding-right: 5px;"></i></a>
                <div class="dropdown-menu dropdown-menu-right" style="color: #2f2f2f;font-size: 15px;border-style: none;border-radius: 0px;background: #ecf4e1;width: 100px;height: auto;margin: 1px 0px 0px;padding: 8px;padding-top: 8px;text-align: center;transform: perspective(0px);"><a class="dropdown-item" href="#" style="border-bottom: 1px solid rgb(33, 37, 41);font-family: Montserrat, sans-serif;background: #ecf4e1;color: #2f2f2f;padding-bottom: 7px;">Login</a><a class="dropdown-item" href="#" style="background: #ecf4e1;color: rgb(47,47,47);padding-top: 7px;">Registrieren</a></div>
            </div>
        </div>
    </nav>
</section> -->