import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GooglePlaceModule } from "ngx-google-places-autocomplete"; 

import { SharedRoutingModule } from './shared-routing.module';
import { RouterModule } from '@angular/router';
import { ColumnOneComponent } from './layouts/column-one/column-one.component';
import { HeaderComponent } from './components/header/header.component';
import { NgProgressModule } from '@ngx-progressbar/core';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgxNavbarModule } from 'ngx-bootstrap-navbar';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { FooterComponent } from './components/footer/footer.component';
import { BrowserModule } from '@angular/platform-browser';
import { MatButtonModule } from "@angular/material/button";

import { LastrowComponent } from './lastrow/lastrow.component';

















@NgModule({
  declarations: [ColumnOneComponent, HeaderComponent, FooterComponent, LastrowComponent],
  imports: [
    CommonModule,
    SharedRoutingModule,
    RouterModule,
    NgProgressModule,
    BrowserAnimationsModule,
    BsDropdownModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    GooglePlaceModule,
    MDBBootstrapModule.forRoot(),
    BrowserModule,
    MatButtonModule
 
   
    
  

  
  ],
  exports:[
    ColumnOneComponent,
    FooterComponent,
    LastrowComponent
  ]
})
export class SharedModule { }



