<section style="margin-top: 8rem;">

    <div class=" container " style=" margin-bottom: 2rem;">
        <div class="col-md-12 ">
            <h3>Und so geht’s als Wirt</h3>
        </div>
    </div>
    <div class="container">
        <div class="row ">
            <div class="col-md-3 "><img src="assets/images/erkl/Locatetable_Erklärung_Register.png " class="img-thumbnail " alt=" ">
                <h5>Registrieren</h5>
                <p>Registrieren Sie sich unter <a [routerLink]="[ 'register'] ">Registrieren</a> &nbsp;</p>
            </div>
            <div class="col-md-3 "><img src="assets/images/erkl/Locatetable_Erklärung_Profil.png " class="img-thumbnail " alt=" ">
                <h5>Profil</h5>
                <p>Vervollständigen Sie ihr Profil und schalten es frei.</p>
            </div>
            <div class="col-md-3 "><img src="assets/images/erkl/Locatetable_Erklärung_Reservierungen.png " class="img-thumbnail " alt=" ">
                <h5>Reservierungen</h5>
                <p>Bestätigen Sie ihre Reservierungen unter <a [routerLink]="[ 'resbearbeiten'] ">Reservierungen</a> &nbsp;&nbsp;</p>
            </div>
            <div class="col-md-3 "><img src="assets/images/erkl/Locatetable_Erklärung_Donate.png " class="img-thumbnail " alt=" ">
                <h5>Spenden</h5>
                <h6>Kostenlos aber nicht Umsonst</h6>
                <p>Kostenloser Service, der sich auszahlt. Sie sind zufrieden mit LocateTable? Dann würden wir uns über eine Spende freuen. Pro Reservierung entstehen für uns Kosten von 40 Cent. LocateTable ist und bleibt kostenlos. Mit einer freiwilligen
                    Spende helfen Sie uns, diesen Service weiterhin anbieten zu können.
                </p>
            </div>
        </div>
    </div>

</section>